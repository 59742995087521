<template>
<div id="rightPane" class="whitebg" style="overflow: hidden; inset: 0px 0px 0px 85.79%;">
    <div id="mypricedtltitle" style="height:28px;font-size:15px;padding:0 5px;line-height:28px;">
        <!-- <div class="fleft" style="margin-top:6px;"><a href="#" class="mr-1" id="bright"><img id="brightimg" src="images/rightarrow_w.png" style="width:16px;height:16px;"></a></div>-->
        <div class="fleft"><span data-aa="n3" style="color: #ccc">報價明細</span><span class="badge badge-warning" id="pricedtlname" style="color: #ccc">加權指數</span></div>
        <div class="fleft" style="margin-top:7px;"><img id="pricesearch22" src="../assets/images/zoomBox.png"></div>
        <div class="form-check fright ">
            <input type="checkbox" class="form-check-input" checked="checked" id="autocheck" style="margin-top:8px;">
            <label class="form-check-label" for="autocheck" data-aa="n75" style="color: #ccc">自動滾動</label>
        </div>
    </div>
    <div class=" myborderbottom" id="mypricedtl" style="overflow: hidden; width: 100%; height: 225px;">
        <div class="outerbox noSelect">
            <div class="innerbox ft_table" id="tablePrice" style="width: 100%; display: block;">
                <div class=" ft_title pricetable" id="tablePriceheader" style="width:100%;">
                    <table>
                        <thead>
                            <tr>
                                <th class="markettitlemx"><div style="text-align:center;color: #ccc" data-aa="b82">時間</div></th>
                                <th class="markettitlemx"><div style="text-align:right;color: #ccc" data-aa="n76">成交價</div></th>
                                <th class="markettitlemx"><div style="text-align:center;color: #ccc" data-aa="n77">單量</div></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class=" ft_div " id="tablePriceOut" style="width: 100%; margin-top: 27px; overflow: hidden; outline: none; height: 198px;" tabindex="3">
                    <!--class="ft_div scrollbar1"-->
                    <!-- <div class="scrollbar"><div class="track"><div class="thumb"></div></div></div>-->
                    <div class="viewport" id="tablePriceView">
                        <div class="  pricetable overview" id="tablePricemain" style="width: 100%;"><table id="tablePriceMy"><tbody></tbody></table></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" mybordertop myborderbottom" id="myvalue" style="height:50px;margin-top:5px;position:relative;">
        <div id="myvalue1">
            <div class="fleft" style="padding-left:10px;">
                <div class="myvaluename myid"><span style="color: #ccc">ta4</span> <span class="text-danger" style="font-size:12px;font-weight:bold;color: #ccc">測試<span></span></span></div>
                <div>
                    <span class="mytesbx" data-aa="a14" style="color: #ccc">可用餘額</span>
                </div>
            </div>
            <div class="fleft">
                <span class="myvvale" id="myaCash0" style="margin-left: 10px; color: rgb(220, 53, 69);color: #ccc">157,000</span>
            </div>

        </div>
        <div id="myvalue2" style="display:none;">
            <div style="padding-left:10px;">
                <div class="myvaluename myid"><span>ta4</span> <span class="text-danger" style="font-size:12px;font-weight:bold;color: #ccc">測試<span></span></span></div>
            </div>
            <div>
                <ul class="myvaluecashPop">
                    <li><div class="myaaadlab" data-aa="a12" style="color: #ccc">昨日權益數</div><div id="yCash" class="mycash " style="color: rgb(220, 53, 69);">157,000</div></li>
                    <li><div class="myaaadlab" data-aa="a13" style="color: #ccc">今日損益</div><div id="tCash" class="mycash " style="color: rgb(204, 204, 204);">0</div></li>
                    <li><div class="myaaadlab" data-aa="a14" style="color: #ccc">可用餘額</div><div id="aCash" class="mycash " style="color: rgb(220, 53, 69);">157,000</div></li>
                    <li><div class="myaaadlab" data-aa="a15" style="color: #ccc">強平額度</div><div id="pCash" class="mycash" style="color: rgb(40, 167, 69);">-600,000</div></li>
                    <li><div class="myaaadlab" data-aa="a16" style="color: #ccc">留倉保證金</div><div id="eCash" class="mycash" style="color: rgb(204, 204, 204);">0</div></li>
                    <li><div class="myaaadlab" data-aa="a17" style="color: #ccc">總權益數</div><div id="aCash0" class="mycash" style="color: rgb(220, 53, 69);">157,000</div></li>
                </ul>
            </div>
        </div>
        <div id="myvalue0" class="myvaluearrow">
            <a href="http://Future.ctinfo.com.tw" id="myvaluedownbtn">
                <img id="myvaluedownimg" src="../assets/images/downarrow.png" style="width:20px;height:20px;">
            </a>
            <a href="http://Future.ctinfo.com.tw" id="myvalueupbtn">
                <img id="myvalueupimg" src="../assets/images/uparrow.png" style="width:20px;height:20px;">
            </a>
        </div>
    </div>
    <div class="singlebox " id="mysinglebox" style="height: 280px;">

        <!--single box A-->
        <table class="talbetaeb singletable">

            <tbody><tr>
                <td colspan="2">
                    <div>
                        <div class="form-check fleft ">
                            <input type="checkbox" class="form-check-input" id="lockcheck">
                            <label class="form-check-label blackWord" for="lockcheck" data-aa="n78" style="font-size:14px;">鎖定商品</label>
                        </div>
                        <div class="form-check fleft m10" id="fastcheckBox">
                            <input type="checkbox" class="form-check-input " id="fastcheck">
                            <label class="form-check-label blackWord" for="fastcheck" data-aa="n72" style="font-size:14px;">下單不確認</label>
                        </div>
                    </div>
                    <div class="fright">

                        <button type="button" id="singleboxa" class="button white small btnsmsm" style="display: none;">A</button>
                        <button type="button" id="singleboxb" class="button white small btnsmsm" style="">B</button>
                        <button type="button" id="singleboxc" class="button white small btnsmsm" style="">C</button>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="text-secondary"><div data-aa="a57" style="text-overflow: ellipsis; width: 38px ;">商品</div></td>
                <td>
                    <select id="single_symbol" class="form-control form-control-sm selectpicker w90p"><option value="">請選擇</option><option value="WTX">台指期</option><option value="TX2">台指全</option><option value="WTE">電子期</option><option value="WTF">金融期</option><option value="MDJ">小道瓊</option><option value="ENC">小那指</option><option value="MSP">小SP</option><option value="NSK">恆生期</option><option value="JNI">日經</option><option value="SFC">A50指</option><option value="SHS">滬深期</option><option value="FDX">德國DAX</option><option value="EUC">歐元期</option><option value="GLN">黃金</option><option value="OSI">白銀</option><option value="CON">輕原油</option><option value="CCT">棉花</option><option value="B1S">黃豆</option><option value="B1W">小麥</option><option value="B1C">玉米</option><option value="BTC">BTCUSD</option><option value="ETH">ETHUSD</option></select>
                </td>
            </tr>
            <tr id="boxa1" style="">
                <td class="text-secondary" data-aa="n261">買賣</td>
                <td>
                    <div class="form-check fleft ">
                        <input type="radio" name="boxa1type" class="form-check-input" id="single_type1" checked="checked">
                        <label class="form-check-label" for="single_type1" data-aa="a62" id="single_typew1" style="color:#dc3545">多單</label>
                    </div>
                    <div class="form-check fleft ml15">
                        <input type="radio" name="boxa1type" class="form-check-input" id="single_type2">
                        <label class="form-check-label" for="single_type2" data-aa="a63" id="single_typew2" style="color:#28a745">空單</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="ml30">
                        <div class="form-check fleft ">
                            <input type="checkbox" class="form-check-input single_nowPrice" id="single_nowPrice" checked="checked">
                            <label class="form-check-label blackWord" for="single_nowPrice" data-aa="a58">市價</label>
                        </div>
                        <div class="form-check fleft ml15">
                            <input type="checkbox" class="form-check-input single_closePrice" id="single_closePrice">
                            <label class="form-check-label blackWord" for="single_closePrice" data-aa="a59">收盤價</label>
                        </div>

                    </div>
                </td>
            </tr>

            <tr>
                <td class="text-secondary" data-aa="a60">限價</td>
                <td>

                    <div id="SingleBestBidBox" class="input-group input-group-sm fleft ui-disabled SingleBestBidBox" style="width: 155px;">
                        <button type="button" id="singlePriceAdd1" class="button white small">
                            <img src="../assets/images/edd.png">
                        </button>

                        <input type="number" min="0" name="single_price" id="single_price" class="form-control singleinput" value="1">

                        <button type="button" id="singlePriceAdd2" class="button white small">
                            <img src="../assets/images/add.png">
                        </button>

                    </div>

                    <button type="button" id="single_nowpricebtn" class="button orange small shlie fleft ml-2 single_nowpricebtn" data-aa="n67">現價</button>

                </td>
            </tr>
            <tr id="boxb1" style="display: none;">
                <td colspan="2">
                    <div class="ml30">
                        <button type="button" class="button white small btnsmsm singleset_put" id="single_put1">1</button>
                        <button type="button" class="button white small btnsmsm singleset_put" id="single_put2">2</button>
                        <button type="button" class="button white small btnsmsm singleset_put" id="single_put3">3</button>
                        <button type="button" class="button white small btnsmsm singleset_put" id="single_put4">4</button>
                        <button type="button" class="button white small btnsmsm singleset_put" id="single_put5">5</button>
                        <button type="button" id="single_putset" class="button white small ml-2 btnsmsm single_putset" data-aa="a18">設</button>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="text-secondary" data-aa="a61">口數</td>
                <td>
                    <div class="input-group input-group-sm fleft" id="SingleLotBox" style="width: 155px;">

                        <button type="button" id="singlePutAdd1" class="button white small">
                            <img src="../assets/images/edd.png">
                        </button>

                        <input type="number" min="0" name="single_put" id="single_put" class="form-control singleinput" value="1">

                        <button type="button" id="singlePutAdd2" class="button white small">
                            <img src="../assets/images/add.png">
                        </button>


                    </div>

                    <button type="button" id="single_allping lockbtn" class="button orange small shlie fleft ml-2 ui-disabled single_allping" data-aa="n71">全平</button>

                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <button type="button" class="button red lockbtn" style="width: 100%;" id="single_btn_buy" data-aa="a62">多單</button>

                    <button type="button" class="button green lockbtn" style="width: 100%; display: none;" id="single_btn_sell" data-aa="a63">空單</button>
                </td>
            </tr>
        </tbody></table>


    </div>
</div>       
                
</template>
<script src="../assets/js/base64.js"></script>
<script src="../assets/js/basic.js"></script>
<script src="../assets/js/bootstrap.bundle.min.js"></script>
<script src="../assets/js/bootstrap-datetimepicker.min.js"></script>
<script src="../assets/js/color-picker.min.js"></script>
<script src="../assets/js/gunzip.min.js"></script>
<script src="../assets/js/jQuery.fastClick.js"></script>
<script src="../assets/js/jquery.nicescroll.js"></script>
<script src="../assets/js/jquery.translate.js"></script>
<script src="../assets/js/jquery-3.0.0.min.js"></script>
<script src="../assets/js/jsbn.js"></script>
<script src="../assets/js/kChart.js"></script>
<script src="../assets/js/kkk.js"></script>
<script src="../assets/js/loadBar.js"></script>
<script src="../assets/js/main.js"></script>
<script src="../assets/js/modernizr-custom.js"></script>
<script src="../assets/js/mTW.js"></script>
<script src="../assets/js/prng4.js"></script>
<script src="../assets/js/rng.js"></script>
<script src="../assets/js/rsa.js"></script>
<script src="../assets/js/splitter.js"></script>
<script src="../assets/js/tinyscrollbar.js"></script>
<script src="../assets/js/top.js"></script>
<script src="../assets/js/tread.js"></script>
<script src="../assets/js/value.js"></script>
<style src="../assets/css/bootstrap.min.css"></style>
<style src="../assets/css/bootstrap-datetimepicker.min.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<style src="../assets/css/style.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<script>
export default {
  name: 'mainRight',  // 將原為name: 'mainTop'改為name: 'mainRight' |Henry|20231030
  data: function () {
    return {
    }
  },
  components: {

  },
  methods: {
  }
};
</script>
<style scoped>
  .splitpanes__pane {
    box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset;
    justify-content: center;
    align-items: center;
    position: relative;
    height:100vh;
  }
  .splitpanes__pane > span {
    font-family: Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 5em;
    opacity: 0.7;
  }
  .vue-modal-resizer {
    display: block;
    overflow: hidden;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: transparent;
    cursor: se-resize;
  }
  .vue-modal-resizer::after {
    display: block;
    position: absolute;
    content: '';
    background: transparent;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid #ddd;
    border-left: 10px solid transparent;
  }
  .vue-modal-resizer.clicked::after {
    border-bottom: 10px solid #369be9;
  }
  .v--modal-block-scroll {
    overflow: hidden;
    width: 100vw;
  }
  .v--modal-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    opacity: 1;
  }
  .v--modal-overlay.scrollable {
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .v--modal-overlay .v--modal-background-click {
    width: 100%;
    min-height: 100%;
    height: auto;
  }
  .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }
  .v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 2px;
  }
  .v--modal {
    background-color: white;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    padding: 0;
  }
  .v--modal.v--modal-fullscreen {
    width: 100vw;
    height: 100vh;
    margin: 0;
    left: 0;
    top: 0;
  }
  .v--modal-top-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .overlay-fade-enter-active,
  .overlay-fade-leave-active {
    transition: all 0.2s;
  }
  .overlay-fade-enter,
  .overlay-fade-leave-active {
    opacity: 0;
  }
  .nice-modal-fade-enter-active,
  .nice-modal-fade-leave-active {
    transition: all 0.4s;
  }
  .nice-modal-fade-enter,
  .nice-modal-fade-leave-active {
    opacity: 0;
    transform: translateY(-20px);
  }
  .vue-dialog div {
    box-sizing: border-box;
  }
  .vue-dialog .dialog-flex {
    width: 100%;
    height: 100%;
  }
  .vue-dialog .dialog-content {
    flex: 1 0 auto;
    width: 100%;
    padding: 15px;
    font-size: 14px;
  }
  .vue-dialog .dialog-c-title {
    font-weight: 600;
    padding-bottom: 15px;
  }
  .vue-dialog .vue-dialog-buttons {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    border-top: 1px solid #eee;
  }
  .vue-dialog .vue-dialog-buttons-none {
    width: 100%;
    padding-bottom: 15px;
  }
  .vue-dialog-button {
    font-size: 12px !important;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 40px;
    height: 40px;
    color: inherit;
    font: inherit;
    outline: none;
  }
  .vue-dialog-button:hover {
    background: rgba(0, 0, 0, 0.01);
  }
  .vue-dialog-button:active {
    background: rgba(0, 0, 0, 0.025);
  }
  .vue-dialog-button:not(:first-of-type) {
    border-left: 1px solid #eee;
  }
</style>
