<template>
  <div>
    <div class="root" >
      <split-pane  v-on:resize="resize" :min-percent='20' :default-percent='80' split="vertical">
        <template slot="paneL">
          <split-pane v-on:resize="resize" :min-percent='20' :default-percent='30' split="horizontal">
            <template slot="paneL">
                <menueBox></menueBox>
                <mainTop class="dv-b"></mainTop>
            </template>
            <template slot="paneR">
              <split-pane v-on:resize="resize" split="vertical">
                  <template  slot="paneL">
                    <div id="dv-lbl" class="dv-lbl">
                      <HighChartPopupL ref="leftLK"></HighChartPopupL>
                    </div>
                  </template >
                  <template  slot="paneR">
                    <div id="dv-lbr" class="dv-lbr">
                      <HighChartPopupR ref="leftRK"></HighChartPopupR>
                    </div>                    
                  </template>                  
              </split-pane>
            </template>
          </split-pane>
        </template>
        <template slot="paneR">
         <div class="dv-z">
           再次將右側面板進行拆分
        </div>
        </template>
      </split-pane>
    </div>
    <div class="footer"><bottombar style="z-index: 100000000;"></bottombar></div>
  </div>
</template>

<script>

import $ from 'jquery'
import HighChartPopupL from '@/components/HighChartPopupL';
import HighChartPopupR from '@/components/HighChartPopupR';
import bottombar from '@/components/bottombar';
import mainTop from '@/components/mainTop';
import menueBox from '@/components/menueBox';
import { Splitpanes, Pane } from '@/components/splitpanes/index';
    export default {
        name: "VueSplitpane",
        components: {         
          HighChartPopupL,
          HighChartPopupR,
          menueBox,
          bottombar, 
          mainTop,
        },
        methods: {
          resize(){         
            this.$refs.leftLK.resizeLK(document.getElementById("dv-lbl").offsetWidth,document.getElementById("dv-lbl").offsetHeight-10);
            this.$refs.leftRK.resizeRK(document.getElementById("dv-lbr").offsetWidth,document.getElementById("dv-lbr").offsetHeight-10);
          },  
        }, 
        mounted() {
          this.resize();
        },                   
    }
</script>

<style scoped>
.splitter-pane-resizer.vertical{
    width: 20px;
    height: 100%;
    margin-left: -5px;
    border-left: 5px solid hsla(0,0%,100%,0);
    border-right: 5px solid hsla(0,0%,100%,0);
    cursor: col-resize;
}
.splitter-pane-resizer{
    box-sizing: border-box;
    background: rgb(248, 0, 0) !important;
    position: absolute;
    opacity: 1;
    z-index: 1;
    background-clip: padding-box;
}
.splitter-pane-resizer[data-v-212fa2a4]{box-sizing:border-box;
background: red !important;
position:absolute;opacity:.2;z-index:1;background-clip:padding-box}.splitter-pane-resizer.horizontal[data-v-212fa2a4]{height:11px;margin:-5px 0;border-top:5px solid hsla(0,0%,100%,0);border-bottom:5px solid hsla(0,0%,100%,0);cursor:row-resize;width:100%}.splitter-pane-resizer.vertical[data-v-212fa2a4]{width:11px;height:100%;margin-left:-5px;border-left:5px solid hsla(0,0%,100%,0);border-right:5px solid hsla(0,0%,100%,0);cursor:col-resize}
  .root {
    width: 100%;
    height: 100vh;
  }

  .dv-a {
    width: 100%;
    height: 100%;
    background-color: dodgerblue;
  }

  .dv-b {
    width: 100%;
    height: calc(100% - 43px);
    background-color: yellow;
  }

  .dv-c {
    width: 100%;
    height: 100%;
    background-color: #ce272d;
  }
  .dv-z {
    width: 100%;
    height: 100%;
    background-color: #a0b345;
  }
  .dv-lbl {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }
  .dv-lbr {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }  
  .footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #42b983;
  color: #fff;
}
</style>