<template>
    <div>
        <div id="rightPane" class="whitebg" style="overflow: hidden; inset: 0px 0px 0px 85.79%;">
            <div id="mypricedtltitle" style="height:28px;font-size:15px;padding:0 5px;line-height:28px;">
                <!-- <div class="fleft" style="margin-top:6px;"><a href="#" class="mr-1" id="bright"><img id="brightimg" src="images/rightarrow_w.png" style="width:16px;height:16px;"></a></div>-->
                <div class="fleft"><span data-aa="n3" style="color: #ccc">報價明細</span><span class="badge badge-warning"
                        id="pricedtlname" ref="pricedtlname" style="color: #000">{{defaultproductName}}</span></div>
                <div class="fleft" style="margin-top:7px;"><img id="pricesearch22" src="../assets/images/zoomBox.png">
                </div>
                <div class="form-check fright">
                    <!-- 20231104 | HENRY | 這邊新增變數autoScroll再利用v-model進行綁定-For tick測試Grid排版用 -->
                    <input type="checkbox" class="form-check-input" id="autocheck" style="margin-top:8px;"
                        v-model="autoScroll">
                    <label class="form-check-label" for="autocheck" data-aa="n75" style="color: #ccc">自動滾動</label>
                </div>
            </div>
        </div>
        <!-- 以下For tick測試用 -->
        <!-- <button @click="backToTop" class="back-to-top-btn">返回頂部</button> -->
        <div style="max-height: 400px;">
            <div id="divRT">
                <Grid ref="grid" id="grid" 
                :style="{ height: '100%' }" 
                :data-items="items" 
                :columns="columns"
                @scroll="scrollHandler">
                    <template v-slot:myCellTemplate="{props}">
                        <td :style="cellBackground(props.dataItem)">
                            {{props.dataItem[props.field]}}
                            <span v-if="props.dataItem.漲跌 < 0">
                                <span class="k-icon k-i-sort-desc-sm" />
                            </span>
                            <span v-else>
                                <span class="k-icon k-i-sort-asc-sm" />
                            </span>
                        </td>
                    </template>
                </Grid>
            </div>
        </div>
        <!-- 以下為原程式碼 -->
        <!-- <div id="divRT" style="overflow: auto; height: 96%;">
        <Grid ref="grid" id="grid"
            :data-items="items"
            :columns="columns"
            >
                <template v-slot:myCellTemplate="{props}">
                        <td :style="cellBackground(props.dataItem)">
                                {{props.dataItem[props.field]}} 
                                <span v-if="props.dataItem.漲跌 < 0">
                                    <span className="k-icon k-i-sort-desc-sm" />
                                </span>
                                <span v-else>
                                    <span className="k-icon k-i-sort-asc-sm" />
                                </span>
                        </td>
                </template>          
        </Grid>
    </div> -->
    </div>
</template>
<script>
    import { Grid } from '@progress/kendo-vue-grid';
    import Store from '../store/index.js'
    import axios from "axios";
    // import '../assets/css/telerik/default-main-dark.css'

    export default {
        components: {
            'Grid': Grid,
        },
        data: function () {
            return {
                columns: [
                    { field: 'time', title: '時間', editable: false, width: '120px' },
                    { field: 'intDeal', title: '成交價', editable: false, width: '130px', cell: "myCellTemplate" },
                    { field: 'dwVol', title: '單量', width: '130px' },
                ],
                products: [],
                fakeDataInterval: null,   // 用於存儲假資料生成定時器
                autoScroll: true,
                autoScrollInterval: null, // 用於存儲資料定時回頂點定時器
                changeTickData: false,
                defaultproductName: Store.state.nowSelectProduct.productName,
            };
        },
        computed: {
            items() {
                return this.products.map((item) => ({
                    ...item,
                }));
            },
            allTick() {
                return Store.state.allTick;
            },
            nowSelectProduct() {
                return Store.state.nowSelectProduct;
            },
            nowSelectProductName() {
                return Store.state.nowSelectProduct.productName;
            },
            historyTick() {
                return Store.state.historyTick
            },
        },
        watch: {
            items(){
                // console.log(this.items)
                while(this.items.length > 20) {
                    this.products.pop(); // 移除数组最后一个元素
                }
            },
            allTick() {
                // this.products = Store.state.real_time_product;
                // console.log("========Store.state.allTick========")
                // console.log(Store.state.allTick)
                // console.log("========Store.state.allTick========")
                if (Store.state.allTick.productCode === this.nowSelectProduct.productCode) {
                    Store.state.allTick.time = Store.state.allTick.dealHour + ":" + Store.state.allTick.dealMin + ":" + Store.state.allTick.dealSec + ":" + Store.state.allTick.dealMinsec
                    // Store.state.allTick.time = Store.state.allTick.timeStamp;
                    if (Store.state.allTick.dwVol > 2147483648) {
                        Store.state.allTick.dwVol = Store.state.allTick.dwVol - 2147483648
                    }
                    this.products.unshift(Store.state.allTick);
                }
                // console.log("========this.products========")
                // console.log(this.products)
                // console.log("========this.products========")
            },
            historyTick() {
                let vm = this;
                // console.log("++++++++++++++++++++++++++++++++++++++++++++");
                // console.log(this.historyTick);
                // console.log(vm.nowSelectProduct.productCode);
                // console.log(vm.historyTick[0].productCode);
                // console.log(vm.changeTickData);
                // console.log("++++++++++++++++++++++++++++++++++++++++++++");

                if (vm.historyTick.length > 0) {
                    if (vm.historyTick[0].productCode == vm.nowSelectProduct.productCode && vm.changeTickData) {
                        // console.log("++++++++++++++++++++++++++++++++++++++++++++");
                        // console.log("<<<<========whilewhile change product success========")
                        // console.log("++++++++++++++++++++++++++++++++++++++++++++");
                        vm.doAddTickHistory();
                    }
                }
                else{
                    console.log("0tick");
                }
                // console.log("++++++++++++++++++++++++++++++++++++++++++++");
            },
            nowSelectProduct() {
                // console.log("this.nowSelectProduct")
                // console.log(this.nowSelectProduct.productCode)
                // console.log("this.nowSelectProduct")
                this.products = [];
                // console.log("change product========")
                // var historyProductTick = [];
                this.changeTickData = true;
                this.getTickHistory();
                // console.log("change product========>>>>", Store.state.historyTick[0].productCode)
                // console.log("change product========>>>>", this.nowSelectProduct.productCode)
            },
            // 以下For tick自動滾動功能用 | HENRY | 20231108
            autoScroll(newValue) {
                if (newValue) {
                    this.startAutoScroll();
                } else {
                    this.stopAutoScroll();
                }
            },
            // 20231112 | HENRY | 新增報價明細旁的產品，也要跟著選擇到的產品做名稱更改
            nowSelectProductName(newVal) {
                this.defaultproductName = newVal;
            },
        },
        methods: {
            scrollHandler: function(event){
                const e = event;
                if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
                    console.log("TTTTTTTTTTTTTTTTTTTTTTTTT")
                }
            },
            doAddTickHistory() {
                let vm = this;
                vm.changeTickData = false;
                let historyProductTick = [];
                vm.historyTick.forEach(element => {
                    //element.time = element.dealHour + ":" + element.dealMin + ":" + element.dealSec + ":" + element.dealMinsec;
                    let date = new Date(element.timeStamp);
                    let hours = date.getHours().toString().padStart(2, '0');
                    let minutes = date.getMinutes().toString().padStart(2, '0');
                    let seconds = date.getSeconds().toString().padStart(2, '0');
                    let milliseconds = date.getMilliseconds().toString().padStart(3, '0');
                    element.time = `${hours}:${minutes}:${seconds}:${milliseconds}`
                    if (element.dwVol > 2147483648) {
                        element.dwVol = element.dwVol - 2147483648
                    }
                    historyProductTick.unshift(element);
                });
                vm.products.push(...historyProductTick);
                historyProductTick = null;
                let date = null;
                let hours = null;
                let minutes = null;
                let seconds = null;
                let milliseconds = null;
            },
            getTickHistory() {
                let vm = this;
                axios.get(this.$httpPath + '/AllStockPrice/getTickHistory/' + vm.nowSelectProduct.productCode + '/0/')
                    .then((response) => {
                        if (response.status === 200) {
                            Store.state.historyTick = response.data;
                            vm.doAddTickHistory()
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            createRandomData() {
                this.products = [{
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }, {
                    "time": "11:24:32",
                    "intDeal": "1652.00",
                    "dwVol": "1",
                }
                ]
            },
            cellBackground: function (dataItem) {
                const examplePrice = dataItem.漲跌 < 0;
                return {
                    "background-color": examplePrice
                        ? "rgb(243, 23, 0, 0.32) !important"
                        : "rgb(55, 180, 0,0.32)  !important"
                };
            },
            reslzeRT(width, height) {
                document.getElementById('divRT').style.height = height - 30 + 'px';
            },
            // 以下For tick測試用-生成假資料
            generateFakeData() {
                return setInterval(() => {
                    const currentTime = new Date();
                    const hours = currentTime.getHours().toString().padStart(2, '0');
                    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
                    const seconds = currentTime.getSeconds().toString().padStart(2, '0');

                    const fakeData = {
                        "time": `${hours}:${minutes}:${seconds}`,
                        "intDeal": (Math.random() * 1000).toFixed(2),
                        "dwVol": Math.floor(Math.random() * 1000),
                        "漲跌": Math.random() > 0.5 ? 1 : -1,
                    };

                    this.products.unshift(fakeData);
                }, 2000);
            },
            // 以下For tick自動滾動功能用 | HENRY | 20231108
            backToTop() {
                const gridElement = this.$refs.grid; // 取得 Grid 
                const gridContent = gridElement.$el.querySelector(".k-grid-content"); // 取得 Grid 的內容區域
                gridContent.scrollTop = 0;
            },
            startAutoScroll() {
                this.autoScrollInterval = setInterval(this.backToTop, 500); // 每0.5秒滾動至頂部
            },
            stopAutoScroll() {
                if (this.autoScrollInterval) {
                    clearInterval(this.autoScrollInterval);
                }
            },
            // 以上For tick自動滾動功能用 | HENRY | 20231108
        },
        mounted() {
            //this.createRandomData();
            // 以下For tick測試用
            //this.fakeDataInterval = this.generateFakeData();
            // 如果autoScroll預設為true，則啟動自動滾動
            if (this.autoScroll) {
                this.startAutoScroll();
            }
        },
        beforeDestroy() {
            // 以下For tick測試用
            //clearInterval(this.fakeDataInterval);
            this.stopAutoScroll();
        },
    };
</script>
<style>
</style>