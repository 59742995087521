<template>
<div style="height:24px;background-color:#000000; overflow:hidden;" id="k_newpricebox">
    <span class="badge badge-danger" style="font-size:13px;display:none;" id="kinverd1"><span data-aa="n260">倒圖</span></span>
    <span class="badge badge-warning" id="ksymbol1">金融期(5分)</span>
    <span class="linesp"><span data-aa="n60">昨收</span><span id="kclose1" style="color: rgb(255, 255, 255);">1608.6</span></span>
    <span class="linesp"><span data-aa="n61">開</span><span id="kopen1" style="color: rgb(39, 214, 39);">1605.2</span></span>
    <span class="linesp"><span data-aa="n62">高</span><span id="khigh1" style="color: rgb(255, 0, 0);">1617</span></span>
    <span class="linesp"><span data-aa="n63">低</span><span id="klow1" style="color: rgb(39, 214, 39);">1603.8</span></span>
    <span class="linesp"><span data-aa="n64">成交</span><span id="kprice1" style="color: rgb(255, 0, 0);">1617</span></span>
    <span class="linesp"><span data-aa="n65">漲跌</span><span id="kchange1" style="color: rgb(255, 0, 0);">▲8.4</span></span>
</div>
</template>
<script>
export default {
  name: "k_newpricebox",
  data: function () {
    return {
    }
  },
  components: {

  },
  methods: {
  },
};
</script>
<style>
</style>