<template>
  <div id="mymenubox" menu-class="dropdown-menu-top" class="navbar navbar-expand-md  myborderbottom"
    style="min-width:400px;background-color:#000000;color:white;padding:0px;margin:0px">
    <ul class="nav navbar-nav ">
      <li class="nav-item dropdown">
        <a @click="toggleDialog()" class="nav-link " id="loginOutBtn" data-aa="a11" style="color:white">登出</a>
      </li>
      <!-- <li class="nav-item dropdown">
              <b-dropdown no-auto-triggers id="menuview" text="檢視" variant="dark">
                  <b-dropdown-item  class="dropdown-item" id="menubulletin" href="#">公告總覽</b-dropdown-item>
                  <b-dropdown-item class="dropdown-item" id="menupricedtl" data-aa="n3" href="#">報價明細</b-dropdown-item>
                  <b-dropdown-item class="dropdown-item" id="menuaccountinfo" data-aa="a70" href="#">會員資訊</b-dropdown-item>
                  <b-dropdown-item class="dropdown-item" id="menuhistory" data-aa="a73" href="#">帳戶歷史</b-dropdown-item>
                  <b-dropdown-item class="dropdown-item" id="menucashhistory" data-aa="a74" href="#">儲值記錄</b-dropdown-item>
              </b-dropdown>
          </li> -->
      <li class="nav-item dropdown" @click="isShown_menuview = !isShown_menuview" no-auto-triggers aria-expanded="true">
        <a class="nav-link dropdown-toggle setmenubox" data-toggle="dropdown" id="menuview">檢視</a>
        <ul class="dropdown-menu show " v-if="isShown_menuview">
          <li><a class="dropdown-item" id="menubulletin" @click="inDevelopment">公告總覽</a></li>
          <li><a class="dropdown-item" id="menupricedtl" data-aa="n3" @click="inDevelopment">報價明細</a></li>
          <li><a class="dropdown-item" id="menuaccountinfo" data-aa="a70" @click="inDevelopment">會員資訊</a></li>
          <li><a class="dropdown-item" id="menuhistory" data-aa="a73" @click="accountHistoy_Button">帳戶歷史</a></li>
          <!-- <li><a class="dropdown-item" id="menucashhistory" data-aa="a74" @click="inDevelopment">儲值記錄</a></li> -->
          <li><a class="dropdown-item" id="menucashhistory" data-aa="a74" @click="LastBalance_Button">儲值記錄</a></li>
        </ul>
      </li>
      <li class="nav-item dropdown " @click="isShown_menuset = !isShown_menuset" aria-expanded="true">
        <a class="nav-link dropdown-toggle setmenubox" data-toggle="dropdown" id="menuset" data-aa="a47">設定</a>
        <ul class="dropdown-menu show " v-if="isShown_menuset">
          <li><a class="dropdown-item" id="menuapassword" data-aa="a71" @click="inDevelopment">修改密碼</a></li>
          <li><a class="dropdown-item" id="menusetsymbol" data-aa="n4" @click="inDevelopment">自訂商品</a></li>
          <li><a class="dropdown-item" id="menusetsymbolcol" data-aa="n5" @click="inDevelopment">自訂欄位</a></li>
          <li><a class="dropdown-item" id="menusetopencontrol" data-aa="n6" @click="inDevelopment">開盤風控設定</a></li>
          <li class="dropdown-divider"></li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289">風格切換</a>
            <div class="dropdown-menu">
              <a class="dropdown-item dropdown-item-checked" id="bcolor_b1" data-aa="n290" @click="inDevelopment">深色</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">淺色</a>
            </div>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289">選擇版面</a>
            <div class="dropdown-menu">
              <a class="dropdown-item dropdown-item-checked" id="bcolor_b1" data-aa="n290">版面1</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">版面2</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">版面3</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">版面4</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">版面5</a>
            </div>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289">字型大小</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">特大</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">大</a>
              <a class="dropdown-item dropdown-item-checked" id="bcolor_b1" data-aa="n290" @click="inDevelopment">中</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">小</a>
            </div>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289"
              @click="inDevelopment">損益設定</a>
            <div class="dropdown-menu">
              <a class="dropdown-item dropdown-item-checked" id="bcolor_b1" data-aa="n290"
                @click="inDevelopment">點數輸入</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">行情輸入</a>
            </div>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289"
              @click="inDevelopment">成交提示</a>
            <div class="dropdown-menu">
              <a class="dropdown-item dropdown-item-checked" id="bcolor_b1" data-aa="n290" @click="inDevelopment">開啟</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">關閉</a>
            </div>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289"
              @click="inDevelopment">音效</a>
            <div class="dropdown-menu">
              <a class="dropdown-item dropdown-item-checked" id="bcolor_b1" data-aa="n290" @click="inDevelopment">開啟</a>
              <a class="dropdown-item" id="bcolor_w1" data-aa="n291" @click="inDevelopment">關閉</a>
            </div>
          </li>
          <li class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle " data-toggle="dropdown" data-aa="n289"
              @click="inDevelopment">貨幣</a>
            <div class="dropdown-menu">
              <a class="dropdown-item " id="bcolor_b1" data-aa="n290" @click="inDevelopment">USD</a>
              <a class="dropdown-item " id="bcolor_b1" data-aa="n290" @click="inDevelopment">EUR</a>
              <a class="dropdown-item " id="bcolor_b1" data-aa="n290" @click="inDevelopment">JPY</a>
              <a class="dropdown-item " id="bcolor_b1" data-aa="n290" @click="inDevelopment">HKD</a>
            </div>
          </li>
        </ul>
      </li>

      <li class="nav-item dropdown " @click="isShown_menuexplain = !isShown_menuexplain" aria-expanded="true">
        <a class="nav-link dropdown-toggle setmenubox" data-toggle="dropdown" id="menuexplain" data-aa="a47">說明</a>
        <ul class="dropdown-menu show " v-if="isShown_menuexplain">
          <li><a class="dropdown-item" id="menuapassword" @click="WintoggleDialog">規則說明</a></li>
          <li><a class="dropdown-item" id="menuaccountinfo" data-aa="a70" @click="NameWintoggleDialog">名詞說明</a></li>
          <li><a class="dropdown-item" id="menuhistory" data-aa="a73" @click="GoodsWintoggleDialog">商品交易時間</a></li>
        </ul>
        <window v-if="Winvisible" :title="'規則說明'" :left="left" :top="top" :width="width" :height="height"
          :minimizeButton="F" :maximizeButton="F" @move="handleMove" @resize="handleResize" @close="WintoggleDialog">
          <div>
            <ruleillustrate />
          </div>
        </window>
        <!-- 20231105 | HENRY | 新增名詞說明 -->
        <window v-if="NameWinvisible" :title="'名詞說明'" :left="left" :top="top" :width="width" :height="height"
          :minimizeButton="F" :maximizeButton="F" @move="handleMove" @resize="handleResize"
          @close="NameWintoggleDialog">
          <div>
            <nameillustrate />
          </div>
          <div
            style="text-align:right; padding-top: 5px; padding-right: 3px; padding-bottom: 3px; background-color: #fff;">
            <kbutton type="button" @click="NameWintoggleDialog">關閉</kbutton>
          </div>
        </window>
        <!-- 20231105 | HENRY | 新增名詞說明 -->
        <!-- 20231119 | HENRY | 新增商品交易時間 -->
        <window v-if="GoodsWinvisible" :title="'商品交易時間(GMT +08:00)'" :left="left" :top="top" :width="width"
          :height="height" :minimizeButton="F" :maximizeButton="F" @move="handleMove" @resize="handleResize"
          @close="GoodsWintoggleDialog">
          <div>
            <goodsTradeTime />
          </div>
          <div
            style="text-align:right; padding-top: 5px; padding-right: 3px; padding-bottom: 3px; background-color: #fff;">
            <kbutton type="button" @click="GoodsWintoggleDialog">關閉</kbutton>
          </div>
        </window>
        <!-- 20231119 | HENRY | 新增商品交易時間 -->
      </li>

      <!-- <li class="nav-item dropdown">
                    <b-dropdown id="menuview" text="說明" variant="dark"  >
                      <b-dropdown-item class="dropdown-item" id="menupricedtl" data-aa="n3" href="#">
                        <div>
                          <k-dropdownlist :data="options" v-model="selectedOption" @change="handleChange">
                            <kbutton @click="WintoggleDialog">規則說明</kbutton>
                                      <window v-if="Winvisible" :title="'規則說明'" :left="left" :top="top" :width="width" :height="height" :minimizeButton="F" 
                                        :maximizeButton="F" @move="handleMove" @resize="handleResize" @close="WintoggleDialog"> 
                                        <div ><div>
                                          <ruleillustrate />
                                        </div></div>
                                      </window>
                          </k-dropdownlist>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item class="dropdown-item" id="menuaccountinfo" data-aa="a70" href="#">名詞說明</b-dropdown-item>
                      <b-dropdown-item class="dropdown-item" id="menuhistory" data-aa="a73" href="#">商品交易時間</b-dropdown-item>
                    </b-dropdown>
                   
          </li> -->
      <!-- <li class="nav-item dropdown">
            <div>
              <k-dropdownlist :data="options" v-model="selectedOption" @change="handleChange">
                <kbutton @click="WintoggleDialog">規則說明</kbutton>
                          <window v-if="Winvisible" :title="'規則說明'" :left="left" :top="top" :width="width" :height="height" :minimizeButton="F" 
                            :maximizeButton="F" @move="handleMove" @resize="handleResize" @close="WintoggleDialog"> 
                            <div>
                              <ruleillustrate/>
                            </div>
                          </window>
              </k-dropdownlist>
            </div>
          </li>       -->
      <li class="nav-item dropdown" id="a_getcash" style="">
        <a class="nav-link" id="a_getcashbtn" data-aa="n21" @click="inDevelopment">領取試玩點數</a>
      </li>
      <li class="nav-item dropdown" id="a_setBouns" style="display:none;">
        <a class="nav-link" id="bounsbtn" data-aa="a79">紅利中心</a>
      </li>
      <li class="nav-item dropdown ui-disabled" id="a_setWithdrawal" style="display:none;">
        <a class="nav-link" id="withdrawalbtn" data-aa="bk17"></a>
      </li>



    </ul>
    <Dialog v-if="lastBalance_visible" :width="'800px'" :height="'500px'" :title="'儲值紀錄'" @close="LastBalance_Button()">
      <LastBalance />
    </Dialog>
    <Dialog v-if="accountHistor_visible" :width="'800px'" :height="'500px'" :title="'帳戶紀錄'" @close="accountHistoy_Button()">
      <AccountHistory />
    </Dialog>
  </div>
</template>




<script src="../assets/js/base64.js"></script>
<script src="../assets/js/basic.js"></script>
<script src="../assets/js/bootstrap.bundle.min.js"></script>
<script src="../assets/js/bootstrap-datetimepicker.min.js"></script>
<script src="../assets/js/color-picker.min.js"></script>
<script src="../assets/js/gunzip.min.js"></script>
<script src="../assets/js/jQuery.fastClick.js"></script>
<script src="../assets/js/jquery.nicescroll.js"></script>
<script src="../assets/js/jquery.translate.js"></script>
<script src="../assets/js/jquery-3.0.0.min.js"></script>
<script src="../assets/js/jsbn.js"></script>
<script src="../assets/js/kChart.js"></script>
<script src="../assets/js/kkk.js"></script>
<script src="../assets/js/loadBar.js"></script>
<script src="../assets/js/main.js"></script>
<script src="../assets/js/modernizr-custom.js"></script>
<script src="../assets/js/mTW.js"></script>
<script src="../assets/js/prng4.js"></script>
<script src="../assets/js/rng.js"></script>
<script src="../assets/js/rsa.js"></script>
<script src="../assets/js/splitter.js"></script>
<script src="../assets/js/tinyscrollbar.js"></script>
<script src="../assets/js/top.js"></script>
<script src="../assets/js/tread.js"></script>
<script src="../assets/js/value.js"></script>
<style src="../assets/css/bootstrap.min.css"></style>
<style src="../assets/css/bootstrap-datetimepicker.min.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<style src="../assets/css/style.css"></style>
<style src="../assets/css/color-picker.min.css"></style>


<script>
  import BootstrapVue from 'bootstrap-vue'
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'
  import { Grid } from '@progress/kendo-vue-grid';
  import Stomp from 'stompjs'
  import axios from 'axios';
  import Vue from 'vue';
  import VueToast from 'vue-toast-notification';
  import Store from '../store/index.js'
  import Vuex from 'vuex';
  import { DropDownButton } from "@progress/kendo-vue-buttons";
  import { AutoComplete, ComboBox, DropDownList, MultiSelect } from '@progress/kendo-vue-dropdowns';
  // import { Window } from '@progress/kendo-vue-dialogs';
  import { Window, Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'; // 20231126 | HENRY | 新增會員儲值紀錄
  import { Button } from '@progress/kendo-vue-buttons';
  import { Input } from '@progress/kendo-vue-inputs';
  import ruleillustrate from "./ruleillustrate.vue";
  import nameillustrate from "./nameillustrate.vue"; // 20231105 | HENRY | 新增名詞說明
  import goodsTradeTime from "./goodsTradeTime.vue"; // 20231119 | HENRY | 新增商品交易時間
  import LastBalance_Dialog from "./Account_Manage_Group/Dialog/LastBalance_Dialog.vue"; // 20231126 | HENRY | 新增會員儲值紀錄
  import accountHistory_Dialog from "./Account_Manage_Group/Dialog/accountHistory_Dialog.vue"; // 20240422 | RICKY | 新增帳戶紀錄
  export default {
    components: {
      buttonColor() {
        return this.selectedOption === 'Option 1' ? 'danger' : 'primary'
      },
      DropDownButton,
      'window': Window,
      'kbutton': Button,

    },


    name: 'menueBox',
    data: function () {
      return {
        visibleDialog: false,
        selectedOption: '',
        isShown_menuview: false,
        isShown_menuset: false,
        isShown_menuexplain: false,
        left: 75,
        top: 50,
        width: 500,
        height: 500,
        F: false,
        Winvisible: false,
        NameWinvisible: false,
        GoodsWinvisible: false,
        lastBalance_visible: false,
        accountHistor_visible: false,
        select_row_data: [],
        visible: true,
        showPopup: false,
        options: ['Option 1', 'Option 2', 'Option 3'],
        selectedOption: '',
      }
    },
    components: {
      ruleillustrate,
      nameillustrate,
      goodsTradeTime,
      'window': Window,
      'kbutton': Button,
      Dialog,
      DialogActionsBar,

      'LastBalance': LastBalance_Dialog,
      'AccountHistory': accountHistory_Dialog
    },

    methods: {
      toggleDialog() {
        this.visibleDialog = !this.visibleDialog;
        localStorage.setItem("access_token", "");
        localStorage.setItem("loglevel:webpack-dev-server", "");
        localStorage.setItem("isAuthenticated", "");
        location.reload();
      },
      toggleDropdown() {
        this.isDropdownShow = !this.isDropdownShow;
      },
      handleMove(event) {
        this.left = event.left;
        this.top = event.top;
      },
      handleResize(event) {
        this.left = event.left;
        this.top = event.top;
        this.width = event.width;
        this.height = event.height;
      },
      WintoggleDialog() {
        this.Winvisible = !this.Winvisible;
      },
      // 20231105 | HENRY | 新增名詞說明
      NameWintoggleDialog() {
        this.NameWinvisible = !this.NameWinvisible;
      },
      // 20231119 | HENRY | 新增商品交易時間
      GoodsWintoggleDialog() {
        this.GoodsWinvisible = !this.GoodsWinvisible;
      },
      LastBalance_Button() {
        //this.select_row_data = data;
        this.lastBalance_visible = !this.lastBalance_visible;
      },
      accountHistoy_Button() {
        //this.select_row_data = data;
        this.accountHistor_visible = !this.accountHistor_visible;
      },
      showComponent() {
        this.showPopup = true;
      },
      handleChange() {
        // Handle change event
      },
      inDevelopment() {
        alert("功能開發中");
      },

    },
    mounted() {
      document.addEventListener('click', this.hideMenu);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.hideMenu);
    },

  };
</script>
<style scoped></style>