<template>
    <body id='AccountHistory_Dialog'>
        <Grid ref="grid" :style="{ height: '100%', width: 'cale(100%-0px)', border: '0px' }" :data-items="result" 
            :skip="skip" :take="take" :total="total" :pageable="true" @pagechange="pageChangeHandler($event)"
            :column-virtualization="false" :columns="columns" :enableRtl="true" :resizable="false" :selectable="true">
            <GridToolbar class="gridtoolbar">
                <div class="bar">
                    <div class="center">
                        <div>
                            <Input :style="{ margin: '0px 5px', overflow: 'hidden', flex: 'nowrap', display: 'none' }" :size="'small'"
                                v-model="wallet.creditAmount"></Input>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div class="center">
                        <span style="margin:0px 5px; font-size:16px; vertical-align: middle;" v-text="'日期'"></span>
                        <div>
                            <DatePicker class="DatePicker" :size="'small'" :rounded="'large'" :default-value="new Date()"
                                :max="new Date()" :value="datepicker_start" @change="OnChange_Date($event, 'start')"
                                :format="'yyyy/MM/dd'">
                            </DatePicker>
                        </div>
                        <span style="vertical-align: middle;" v-text="'~'"></span>
                        <div>
                            <DatePicker class="DatePicker" :size="'small'" :rounded="'large'" :default-value="new Date()"
                                :max="new Date()" :value="datepicker_end" @change="OnChange_Date($event, 'end')"
                                :format="'yyyy/MM/dd'">
                            </DatePicker>
                        </div>
                        <div style="padding-right:20px">
                            <Button :rounded="'medium'" size="medium" v-text="'搜尋'" @click="Date_Search('search')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'今日'" @click="Date_Search('today')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'昨日'"
                                @click="Date_Search('yesterday')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'本周'"
                                @click="Date_Search('current_week')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'本月'"
                                @click="Date_Search('current_month')"></Button>
                        </div>
                        <div>
                            <Button :rounded="'medium'" size="medium" v-text="'上月'"
                                @click="Date_Search('one_months_ago')"></Button>
                        </div>
                    </div>
                </div>
            </GridToolbar>
        </Grid>
        <Dialog v-if="message_visible" :width="'300px'" :height="'125px'" :title="'訊息'" @close="Message_Button(null)" :close-on-outside-click="true">
            <div style="height:100%; width:100%; display: inline-flex; align-items: center; justify-content: center;">
                <div style="font-size:16px; font-weight: bold;" v-text="message" />
            </div>
        </Dialog>
    </body>
</template>
<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
import { Button, ButtonGroup } from '@progress/kendo-vue-buttons';
import dateFormat, { masks } from "dateformat";
import { isEqualDate, Day, prevDayOfWeek, nextDayOfWeek, firstDayOfMonth, lastDayOfMonth, addMonths, addWeeks, addDays } from '@progress/kendo-date-math';
import { Window, Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import axios from 'axios';
export default {
    name: 'LastBalance_Dialog',
    components: {
        Grid,
        GridToolbar,
        Button,
        DatePicker,
        Input,
        Dialog,
    },
    props: {
        select_row_data: {},
    },
    data: function () {
        return {
            skip: 0,
            take: 10,
            currentPage:1,
            message: '',
            message_visible: false,
            getWallet: {
                guid: '',
                userGuid: '',
                futureWallet: 0,
                futureLock: 0,
                creditAmount: 0,
                creditLock: 0,
                currency: '',
                ver: 0,
            },
            wallet: {
                creditAmount: 0,
                futureAmount: 0,
            },
            addAmount: {
                amount: 0,
                userGuid: '',
                wallet: '',
                currency: '',
                walletVer: 1,
            },
            search_date_text: "",
            datepicker_start: new Date(),
            datepicker_end: new Date(),
            date_start: dateFormat(new Date(), "yyyy/mm/dd"),
            date_end: dateFormat(new Date(), "yyyy/mm/dd"),
            current_month: dateFormat(new Date(), "m"),
            one_months_ago: dateFormat(addMonths(new Date(), -1), "m"),
            two_months_ago: dateFormat(addMonths(new Date(), -2), "m"),

            credit_limit: 0,//信用額度
            account_balance: 0,//帳戶餘額
            remaining: 0,//上層剩餘[額度]
            available: 0,//用戶可用[額度]
            data: [],/*資料連接*/
            columns: [
                { field: 'date', title: '操作日期', width: '180x' },
                // { field: 'type', title: '類型', width: '280px' },
                { field: 'addition', title: '操作內容', width: '400px' },
                { field: 'amount', title: '金額', width: '150px' },
                // { field: 'agent', title: '操作人員', width: '125px' },
                // { field: 'account', title: '帳號', width: '425px' },
            ],
        }
    },
    computed: {
        result: {
            get: function() {
                //console.log(this.data);
                return this.data;
            }
        },
        total () {
            return this.data.totalRows;
        },  
    },
    mounted() {
        this.refreshAll();
    },
    methods: {
        refreshAll(){
            //this.GetWallet();
            this.GetWalletLog();
        },
        pageChangeHandler: function(event) {
            this.skip = event.page.skip;
            this.take = event.page.take;
            this.currentPage = (event.page.skip/this.take)+1,
            this.GetWalletLog();
        },
        // 20231126 | HENRY | 新增會員儲值紀錄
        GetWalletLog() {
            axios.get(this.$httpPath + '/ManagementInfo/getMyWalletInfo' + '/' + this.currentPage + '/' + this.take)
                .then((response) => {
                    if (response.status == 200) {
                        // 將取到的數值先進行時間轉換, 使其僅顯示日期即可, 日後再根據需求進行修改
                        response.data.data = response.data.data.map(item => {
                            const date = new Date(item.date);
                            const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                            return {
                                ...item,
                                date: formattedDate
                            };
                        });
                        this.data = response.data
                        //console.log(this.data)
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                    console.log(error)
                }).finally(() => {
                });
        },
        Message_Button() {
            this.message_visible = !this.message_visible;
            this.message = '';
        },
        GetWallet() {
            axios.get(this.$httpPath + '/AgentManager/addAmount/' + this.select_row_data.guid + '/20')
                .then((response) => {
                    if (response.status == 200) {
                        this.getWallet = response.data
                        console.log(this.getWallet)
                    }
                })
                .catch((error) => {
                    this.message = "發生錯誤"
                    console.log(error)
                }).finally(() => {
                });
        },
        ModifyCredit() {
            var info = this.wallet.creditAmount < 0 ? "減少" : "增加"
            var con = confirm("確定要" + info + this.wallet.creditAmount + "點到" + this.select_row_data.userName + "的信用額度嗎？")
            if (con) {
                this.addAmount.amount = Number(this.wallet.creditAmount)
                this.addAmount.userGuid = this.select_row_data.guid;
                this.addAmount.wallet = "CREDIT_AMOUNT"
                this.addAmount.currency = this.getWallet.currency;
                this.addAmount.walletVer = Number(this.getWallet.ver);
                axios.post(this.$httpPath + '/AgentManager/addAmount', this.addAmount)
                    .then((response) => {
                        if (response.status == 200) {
                            this.message_visible = true;
                            this.message = response.data;
                        }
                    })
                    .catch((error) => {
                        this.message = "發生錯誤"
                        this.message_visible = true;
                        console.log(error)
                    }).finally(() => {
                        this.refreshAll();
                    });
            }
        },
        ModifyFuture() {
            var info = this.wallet.futureAmount < 0 ? "減少" : "增加"
            var con = confirm("確定要" + info + this.wallet.futureAmount + "元到" + this.select_row_data.userName + "的帳戶餘額嗎？")
            if (con) {
                this.addAmount.amount = Number(this.wallet.futureAmount)
                this.addAmount.userGuid = this.select_row_data.guid;
                this.addAmount.wallet = "FUTURE_WALLET"
                this.addAmount.currency = this.getWallet.currency;
                this.addAmount.walletVer = Number(this.getWallet.ver);
                axios.post(this.$httpPath + '/AgentManager/addAmount', this.addAmount)
                    .then((response) => {
                        if (response.status == 200) {
                            this.message_visible = true;
                            this.message = response.data;
                        }
                    })
                    .catch((error) => {
                        this.message = "發生錯誤"
                        this.message_visible = true;
                        console.log(error)
                    }).finally(() => {
                        this.refreshAll();
                    });
            }
        },
        Filter_Change() {

        },
        OnChange_Date(event, key) {
            console.log(event.value)
            //補一段開始時間大於結束時間       修正為當天日期
            //補一段結束時間小於開始時間       修正為當天日期
            //補一段開始,結束時間大於當天日期  修正為當天日期
            switch (key) {
                case 'start': {
                    console.log("start")
                    this.date_start = dateFormat(event.value, "yyyy/mm/dd");
                    this.datepicker_start = event.value;
                    break;
                }
                case 'end': {
                    console.log("end")
                    this.date_end = dateFormat(event.value, "yyyy/mm/dd")
                    this.datepicker_end = event.value;
                    break;
                }
                default: {
                    break;
                }
            }
            console.log(this.date_start)
            console.log(this.date_end)
        },
        Date_Search(event) {
            switch (event) {
                case 'search': {
                    alert("功能開發中！")
                    break;
                }
                case 'today': {
                    this.datepicker_start = new Date(),
                        this.datepicker_end = new Date(),
                        this.date_start = dateFormat(new Date(), "yyyy/mm/dd")
                    this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    break;
                }
                case 'yesterday': {
                    this.datepicker_start = addDays(new Date(), -1),
                        this.datepicker_end = addDays(new Date(), -1),
                        this.date_start = dateFormat(addDays(new Date(), -1), "yyyy/mm/dd")
                    this.date_end = dateFormat(addDays(new Date(), -1), "yyyy/mm/dd")
                    break;
                }
                case 'current_week': {
                    this.datepicker_start = nextDayOfWeek(new Date(), Day.Monday),
                        this.date_start = dateFormat(nextDayOfWeek(new Date(), Day.Monday), "yyyy/mm/dd")

                    if (isEqualDate(new Date(), nextDayOfWeek(new Date(), Day.Sunday))) {
                        this.datepicker_end = nextDayOfWeek(new Date(), Day.Sunday),
                            this.date_end = dateFormat(nextDayOfWeek(new Date(), Day.Sunday), "yyyy/mm/dd")
                    }
                    else {
                        this.datepicker_end = new Date(),
                            this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    };
                    break;
                }
                case 'current_month': {
                    this.datepicker_start = firstDayOfMonth(new Date()),
                        this.date_start = dateFormat(firstDayOfMonth(new Date()), "yyyy/mm/dd")
                    if (isEqualDate(new Date(), lastDayOfMonth(new Date()))) {
                        this.datepicker_end = lastDayOfMonth(new Date()),
                            this.date_end = dateFormat(lastDayOfMonth(new Date()), "yyyy/mm/dd")
                    }
                    else {
                        this.datepicker_end = new Date(),
                            this.date_end = dateFormat(new Date(), "yyyy/mm/dd")
                    };
                    break;
                }
                case 'one_months_ago': {
                    this.datepicker_start = firstDayOfMonth(addMonths(new Date(), -1)),
                        this.datepicker_end = lastDayOfMonth(addMonths(new Date(), -1)),
                        this.date_start = dateFormat(firstDayOfMonth(addMonths(new Date(), -1)), "yyyy/mm/dd")
                    this.date_end = dateFormat(lastDayOfMonth(addMonths(new Date(), -1)), "yyyy/mm/dd")
                    break;
                }
                default: {
                    break;
                }
            }
            this.search_date_text = "日期:" + this.date_start + "~" + this.date_end;
        },
    },
};
</script>
<style scoped>
body {
    height: 100%;
    margin: -16px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.gridtoolbar {
    height: 80px;
    width: 100%;
    padding: 0px 0px;
    border: 0px;
    background-color: #0c4d70;
    color: #fff;
    display: inline-flex;

}

.bar {
    flex: 1;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
}

.center {
    flex: 1;
    width: 100%;
    padding: 0px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.DatePicker {
    margin: 3px;
    font-size: 12px;
    width: 120px;
}

.DatePicker>span {
    margin: 0px;

}

Button {
    height: 100%;
    width: auto;
    color: #fff;
    background-color: #17a2b8;
    padding: 2px 5px;
    margin: 3px;
}
</style>
