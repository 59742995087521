import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import router from '@/router'

Vue.use(Vuex);

// 定義一個新的 Vue Store
const store = new Vuex.Store({
    state: {
        refresh_token: "",
        access_token: "",
        loggedInUser: {},
        isAuthenticated: false,
        allProducts: ["NoData"],
        allKLineDay: ["NoData"],
        allKLineMin: ["NoData"],
        allTick: ["NoData"],
        nowSelectProduct:{
            bstrStockNo:'DHF00',
            bstrStock:'',
            nBid:'',
            nAsk:'',
            nClose:'',
            nTQty:'',
            nYQty:'',
            nRef:'',
            nOpen:'',
            nHigh:'',
            nLow:'',
            sDecimal:'',
            nDown:'',
            nUp:'',
            nFutureOI:'',
            nTAc:'',
            nTBc:'',
            nAc:'',
            nBc:'',
            nTradingDay:'',
            Amplitude:'',
            nAmplitude:'',
            nTickQty:'',
            bstrMarketNo:'',
            sTypeNo:'',
        },
    },
    action:{
      setRefreshToken: function(state, refreshToken) {
        state.refresh_token = refreshToken;
      },
      setAccessToken: function(state, accessToken) {
        state.access_token = accessToken;
        localStorage.setItem("isAuthenticated",true);
      },
      logIn: async ({ commit, dispatch }, {email,password}) => {
        let self = this
        console.log(email)
        console.log(password)
        const loginUrl = Vue.prototype.$httpPath+'/AuthManagement/login';
        try {
          await axios.post(loginUrl, {email,password}).then(response => {
            if (response.status === 200) {
              localStorage.setItem("access_token",response.data.token);
              commit("setRefreshToken", response.data.refreshToken);
              commit("setAccessToken", response.data.token);
              //router.push("/");
              router.go(0);
            }
          });
        } catch (e) {
          console.log(e);
        }
      },
      TestreloadProductsData(context,status){
        console.log(123)
        context.commit('reloadProductsData', status)
      },
      ReloadProductsData(context,status){
        console.log(123)
        context.commit('reloadProductsData', status)
      },
      ReloadKLineDayData(context,status){
        console.log(status)
        context.commit('reloadKLineDayData', status)
      },
      ReloadKLineMinData(context,status){
        //console.log(status)
        context.commit('reloadKLineMinData', status)
      },
      ReloadTickData(context,status){
        //console.log(status)
        context.commit('reloadTickData', status)
      },
      LoadNowSelectProduct(context,status){
        //console.log(status)
        context.commit('loadNowSelectProduct', status)
      },
    },
    mutations: {
      // 將state設定為參數
      reloadProductsData(state, payload) {
        console.log(payload)
        state.allProducts = payload;
      },
      reloadKLineDayData(state, payload) {
        state.allKLineDay = payload;
        //console.log(state.allKLineDay)
      },
      reloadKLineMinData(state, payload) {
        state.allKLineMin = payload;
        //console.log(state.allKLineMin)
      },
      reloadTickData(state, payload) {
        state.allTick = payload;
        //console.log(state.allTick)
      },
      loadNowSelectProduct(state, payload) {
        state.nowSelectProduct = payload;
      },
      setRefreshToken: function(state, refreshToken) {
        state.refresh_token = refreshToken;
      },
      setAccessToken: function(state, accessToken) {
        state.access_token = accessToken;
        localStorage.setItem("isAuthenticated",true);
      },
      setLoggedInUser: function(state, user) {
        state.loggedInUser = user;
        state.isAuthenticated = true;
      },
      clearUserData: function(state) {
        state.refresh_token = "";
        state.access_token = "";
        state.loggedInUser = {};
        state.isAuthenticated = false;
        this.$router.go(0);
        localStorage.setItem("access_token","");
      }, 
    }

})
export default store;