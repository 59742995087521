<template>
  <div id="app" style="height:100%">
    <!-- <mainPage></mainPage> -->
    <router-view></router-view>
    <!-- <HighChartPopup></HighChartPopup> -->
  </div>
  
</template>

<script>
// import stock from '@/components/stock.vue'
// 其他组件
//import mainPage from '@/components/mainPage.vue'
export default {
  name: 'app',
  components: {
    //mainPage
  }
}
</script>
<style>
</style>
