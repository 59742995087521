<template>
    <div class="modal-content boxshowdd">
                <div class="modal-body">
                    <div class="outerbox">
                        <div class="innerbox ft_table" style="width:100%;">
                            <div class=" ft_title" style="width:100%;">
                                <table class="infotablesym" style="width:100%;">
                                    <tbody><tr>
                                        <td class="markettitlemxwex" style="width:97px;height:27px;text-align:left;padding-left:5px;" data-aa="n96">分類</td>
                                        <td class="markettitlemxwex" style="width:503px;height:27px;" data-aa="a108">規則說明</td>
                                    </tr>
                                </tbody></table>
                            </div>
                            <div class=" ft_div" style="height:450px;
                            overflow-x: hidden ;overflow-y:auto; outline: none; margin-top: 27px;border-bottom:1px solid #dee2e6;">
                                <div class="ft_divfixed ruletable" id="ruletable">
                                    <table>
                                        <tbody><tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="b1">開盤</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b2">開盤前不接受任何預約單。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">2)</td>
                                            <td class="myrule3" data-aa="b3">開盤風控點位預設值，留倉單于每日開盤時若每手(口)單位漲跌幅正負值超過「商品資訊」內「開盤風控點數」，系統將自動平倉，午盤休息視為收盤。</td>
                                        </tr>
                                        <tr class="markettdinfo ">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee">3)</td>
                                            <td class="myrule3 myspaceee" data-aa="b4">開盤風控是以可交易時間的第一筆報價和最後一筆報價去計算。</td>
                                        </tr>

                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="b5">盤中</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b6">市價單：下單後以下一筆報價為成交點位。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">2)</td>
                                            <td class="myrule3" data-aa="b7">可設限價單以及持倉後可設[停損][停利]單，掛價單只限於當日有效，收盤後自動取消需重新設置。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">3)</td>
                                            <td class="myrule3" data-aa="b8">未成交之掛單，若于成交時帳戶餘額不足手續費時，系統自動刪除掛單，視同刪除單，成交不算。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">4)</td>
                                            <td class="myrule3" data-aa="b9">當日行情漲跌幅到達該商品禁新價時，該商品系統將不收新單。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee">5)</td>
                                            <td class="myrule3 myspaceee" data-aa="b10">5) 當日行情漲跌幅到達該商品強平價時，所有在倉單之多單、空單，系統將所有在倉單強制以下一筆報價平倉。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="b11">收盤</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b12">商品收盤後(含上午盤)，系統將取消該商品未成交掛價單及停損停利。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">2)</td>
                                            <td class="myrule3" data-aa="b13">商品收盤，系統檢查該留倉單是否超過[留倉上限]及[帳戶餘額]是否足夠[留倉保證金]進行留倉指令，若有不足則強制平倉。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee">3)</td>
                                            <td class="myrule3 myspaceee" data-aa="b14">留倉規則采商品先收盤先留倉原則。</td>
                                        </tr>

                                        <tr class="markettdinfo">
                                            <td class="myrule1" rowspan="2" data-aa="b15">最後交易日及換倉</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b16">商品最後交易日當天將全部強制平倉不可留倉。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule2 myspaceee">2)</td>
                                            <td class="myrule3 myspaceee" data-aa="b17">換倉日：以系統時間為准。</td>
                                        </tr>

                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee" data-aa="b18">持倉規定</td>
                                            <td class="myrule2 myspaceee">1)</td>
                                            <td class="myrule3 myspaceee" data-aa="b19">帳戶餘額：當 「 ≦ 強制平倉額度 」時，所有持倉部份自動以市價強制平倉。</td>
                                        </tr>

                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="b20">計算方式</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b21">下單交易方式分為買（多）或買（空）,如下單買升點數向上漲表示獲利往下跌則為虧損如下單買跌點數向下跌表示獲利點數向上漲表示虧損。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2" style="color:#CC0000;" data-aa="n97">例</td>
                                            <td class="myrule3" data-aa="b22">買多一手黃金1688.8在1698.8時賣出則損益則為&gt;&gt;1698.8-1688.8＝10大點*500（每一整數點價值）- 手續費300所以損益=4700 </td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee" style="color:#CC0000;" data-aa="n97">例</td>
                                            <td class="myrule3 myspaceee" data-aa="b23">買多一手黃金1698.8在1688.8時賣出則損益則為&gt;&gt;1688.8-1698.8＝-10大點*500（每一整數點價值）- 手續費300所以損益= -5300 </td>
                                        </tr>


                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="b24">統計結算方式</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b25">結算時間，將所有在倉單商品以結算時間的前一筆點位結算，積分轉入昨日積分餘額，以統計損益。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">2)</td>
                                            <td class="myrule3" data-aa="b26">結算時間暫時停止新下單，將所有在倉單與留倉單以收盤最後1筆（有量）之成交點位轉入新單。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">3)</td>
                                            <td class="myrule3" data-aa="b27">每日結算完後，系統將會把今日損益提列到帳戶餘額做相加減，查賬請至統計或帳戶歷史觀看。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee">4)</td>
                                            <td class="myrule3 myspaceee" data-aa="b28">會員結算時間請至會員資訊查詢。</td>
                                        </tr>

                                        <tr class="markettdinfo isqtcshow" style="display:none;">
                                            <td class="myrule1 myspace" data-aa="b29">斷線處理</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b30">全商品暫停交易待系統恢復後方可繼續交易。</td>
                                        </tr>
                                        <tr class="markettdinfo isqtcshow" style="display:none;">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">2)</td>
                                            <td class="myrule3" data-aa="b31">會員如欲平倉，可以通訊軟體、專線或短信通知線上客服人員，注明帳號、名稱、商品及手（口）數，將以收到通知信息後下一分鐘第一筆成交價平倉。若無主動通知管理人員平倉，則視為繼續持倉。</td>
                                        </tr>
                                        <tr class="markettdinfo isqtcshow" style="display:none;">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee">3)</td>
                                            <td class="myrule3 myspaceee" data-aa="b32">本規則如有未盡事宜，可視狀況隨時調整。以即時消息公告。 </td>
                                        </tr>


                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee" data-aa="b33">低量時段</td>
                                            <td class="myrule2 myspaceee">1)</td>
                                            <td class="myrule3 myspaceee" data-aa="b34">美盤商品每日有部份時段成交量極低，稱為低量時段(請參考交易時間表)，此時段本系統不接受新單（禁新）但可若有在倉單可平（關倉）不受此限。 </td>
                                        </tr>

                                        <tr class="markettdinfo isqtcshow" style="display:none;">
                                            <td class="myrule1 myspaceee" data-aa="b35">高频交易（剥头皮交易）</td>
                                            <td class="myrule2 myspaceee">1)</td>
                                            <td class="myrule3 myspaceee"><span data-aa="b36">高频交易单（本公司认定为180秒内完成开仓平仓的交易单）, 对於高频交易单本公司将会於平仓时加收手续费</span> <span id="isqtcfee">400</span></td>
                                        </tr>


                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="b37">穿價回原價</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="b38">限價單(含倒限單)所以成交方式一定是穿價回原價。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">2)</td>
                                            <td class="myrule3" data-aa="b39">限價單(含倒限單)生效後, 有觸及或穿越該掛價, 則以該掛價成交。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace"></td>
                                            <td class="myrule2">3)</td>
                                            <td class="myrule3" data-aa="b40">當限價單(含倒限單)成交時, 才有穿價回原價。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee">4)</td>
                                            <td class="myrule3 myspaceee" data-aa="b41">當報價觸及或穿越某筆單的損失點(獲利點)時,系統發出自動平倉, 以損失點(獲利點)作為自動平倉單的掛價和成交價。</td>
                                        </tr>

                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspace" data-aa="bk35">分時K線時間</td>
                                            <td class="myrule2">1)</td>
                                            <td class="myrule3" data-aa="bk36">本系統K線在分時週期的當根K棒顯示的數據實際是上一分鐘的資料。</td>
                                        </tr>
                                        <tr class="markettdinfo">
                                            <td class="myrule1 myspaceee"></td>
                                            <td class="myrule2 myspaceee"></td>
                                            <td class="myrule3 myspaceee">【<span data-aa="bk37">以一分線為例，收線的價格決定時，是在下一分開始才能決定上一分的收的價格，因而如此顯示</span>】</td>
                                        </tr>

                                        <tr class="markettdinfo">
                                            <td class="myrule1  myspace" data-aa="b42">聲明</td>
                                            <td class="myrule2 ">1)</td>
                                            <td class="myrule3  " data-aa="b43">因每家報價資料皆有些許誤差，如遇會員本身斷線、當機、塞車、電腦老舊...等硬體或傳輸問題,則所有成交價格均以「本系統網路資料（分價揭示）」為主，若系統發生延遲慢盤將集體於盤中通知損益不予計算。</td>
                                        </tr>

                                        <tr class="markettdinfo isqtcshow" style="display:none;">
                                            <td class="myrule1 myspaceee myspace"></td>
                                            <td class="myrule2 myspaceee">2)</td>
                                            <td class="myrule3 myspaceee" data-aa="b433">一個交易帳號僅限同一個人，同一IP，同一電腦，若交易超過一個以上的帳號，經本公司認定，將不受理出款，立即封號並剔除其營利。</td>
                                        </tr>
                                    </tbody></table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                
            </div>
  </template>
  
  <script>
  export default {};
  </script>