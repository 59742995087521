import axios from "axios";
// import store from "../store";
import Store from '../store/index.js'
import router from "../router";
import Vue from "vue";
export default function axiosSetUp() {
  let wait_refresh = false;
  let usedWithin5min = false;
  let refreshSeconds = 30 * 60;
  function count_refresh_time(){
    const intervalId = setInterval(() => {
      // 轉換成分鐘和秒數
      // const minutes = Math.floor(refreshSeconds / 60);
      // const secs = refreshSeconds % 60;

      // 更新顯示的時間
      // console.log(`${minutes}:${secs < 10 ? '0' : ''}${secs}`);

      // 減少秒數
      refreshSeconds--;

      // 如果時間到，停止計時器並顯示訊息
      if (refreshSeconds < 0) {
          clearInterval(intervalId);
          let re = confirm("是否要繼續使用系統？");
          if(re){
            Store.dispatch("UserClick_RefreshToken"); //UserClick_RefreshToken
          }
          console.log("30分鐘已到！");
      }
      if (refreshSeconds == 300) {
        console.log("5分鐘已到！");
        if(usedWithin5min){
          console.log("5分鐘已到！__AUTO_REFRESH");
          Store.dispatch("refreshToken");
        }
    }
    }, 1000); // 每1000毫秒(即1秒)更新一次
  }
  count_refresh_time();

  // point to your API endpoint
  axios.defaults.baseURL = Vue.prototype.$httpPath;
  // Add a request interceptor
  axios.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      const token = localStorage.getItem("access_token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const originalRequest = response.config;
      // console.log(originalRequest)
      if (response.status === 200) 
      {
        usedWithin5min = true;
      }
      if (response.status === 200 && originalRequest.url.includes("api/AuthManagement/RefreshToken")) 
      {
        wait_refresh = false;
        console.log(response.data)
        if(response.data.success){
          if(refreshSeconds == -999){
            refreshSeconds = 30 * 60;
            count_refresh_time();
          }
          else{
            refreshSeconds = 30 * 60;
            usedWithin5min = false;
          }
        }
        else{
          //location.reload();
        }
      }
      return response;
    },
    async function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const originalRequest = error.config;
      
      if (error.response.status === 401 && originalRequest.url.includes("api/AuthManagement/RefreshToken")) 
      {
        // Store.commit("clearUserData");
        //router.push("/");
        //router.go(0);
        location.reload();
        return Promise.reject(error);
      } 
      else if (error.response.status === 401 && !originalRequest._retry) 
      {
        originalRequest._retry = true;
        if(!wait_refresh){
          wait_refresh = true;
          await Store.dispatch("refreshToken");
        }
        else{
          // while(wait_refresh){
          //   console.log("wait for refresh")
          // }
          console.log("wait for refresh")
        }
        
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}