<template>
<div> 
<div id="containerKR"></div>
</div>
</template>
<script src="https://code.highcharts.com/stock/highstock.js"></script>
<script src="https://code.highcharts.com/stock/modules/data.js"></script>

<script src="https://code.highcharts.com/stock/modules/drag-panes.js"></script>

<script src="https://code.highcharts.com/stock/indicators/indicators.js"></script>
<script src="https://code.highcharts.com/stock/indicators/bollinger-bands.js"></script>
<script src="https://code.highcharts.com/stock/indicators/ema.js"></script>

<script src="https://code.highcharts.com/modules/annotations-advanced.js"></script>

<script src="../assets/highcharts/code/es-modules/extensions/fullscreen.js"></script>
<script src="https://code.highcharts.com/stock/modules/price-indicator.js"></script>
<script src="https://code.highcharts.com/stock/modules/stock-tools.js"></script>
<script>

import Vue from "vue";
import $ from 'jquery'
import axios from "axios";
import Highcharts from 'highcharts';
export default {
  name: "HighChartPopupR",
  data: function () {
    return {
      chartR:null,
      ChartOptions: 
      {
            chart: 
            {
                backgroundColor:'#000000',
                reflow:true,
                events: {
                    load: function () {
                        addPopupEvents(this);
                    }
                },
            },
            plotOptions: 
            {
                candlestick: {           
                    upLineColor: 'red',
                    upColor: 'red',
                    LineColor: '#00FFFF',
                    color: '#00FFFF',
                    border:'#00FFFF',
                }
            },                    
            xAxis: 
            {
                gridLineWidth: 1,
                gridLineColor:'#501111',
            },   
            yAxis: 
            [{
                labels: {
                    align: 'left'
                },
                height: '100%',
                resize: {
                    enabled: true
                },
                gridLineWidth: 1,
                gridLineColor:'#501111',
                }, 
                {
                labels: {
                    align: 'left'
                },
                top: '80%',
                height: '20%',
                offset: 10
            }],
            scrollbar: 
            {
                enabled: true
            },                    
            navigationBindings: 
            {
                events: 
                {
                    selectButton: function (event) {
                        var newClassName = event.button.className + ' highcharts-active',
                            topButton = event.button.parentNode.parentNode;

                        if (topButton.classList.contains('right')) {
                            newClassName += ' right';
                        }

                        // If this is a button with sub buttons,
                        // change main icon to the current one:
                        if (!topButton.classList.contains('highcharts-menu-wrapper')) {
                            topButton.className = newClassName;
                        }

                        // Store info about active button:
                        vm.chart.activeButton = event.button;
                    },
                    deselectButton: function (event) {
                        event.button.parentNode.parentNode.classList.remove('highcharts-active');

                        // Remove info about active button:
                        this.chart.activeButton = null;
                    },
                    showPopup: function (event) {

                        if (!this.indicatorsPopupContainer) {
                            this.indicatorsPopupContainer = document
                                .getElementsByClassName('highcharts-popup-indicators')[0];
                        }

                        if (!this.annotationsPopupContainer) {
                            this.annotationsPopupContainer = document
                                .getElementsByClassName('highcharts-popup-annotations')[0];
                        }

                        if (event.formType === 'indicators') {
                            this.indicatorsPopupContainer.style.display = 'block';
                        } else if (event.formType === 'annotation-toolbar') {
                            // If user is still adding an annotation, don't show popup:
                            if (!this.chart.activeButton) {
                                this.chart.currentAnnotation = event.annotation;
                                this.annotationsPopupContainer.style.display = 'block';
                            }
                        }

                    },
                    closePopup: function () {
                        this.indicatorsPopupContainer.style.display = 'none';
                        this.annotationsPopupContainer.style.display = 'none';
                    }
                }
            },
            tooltip: 
            {
                shape: 'square',
                headerShape: 'callout',
                borderWidth: 0,
                shadow: false,
                positioner: function (width, height, point) {
                    var chart = this.chart,
                        position;

                    if (point.isHeader) {
                        position = {
                            x: Math.max(
                                // Left side limit
                                chart.plotLeft,
                                Math.min(
                                    point.plotX + chart.plotLeft - width / 2,
                                    // Right side limit
                                    chart.chartWidth - width - chart.marginRight
                                )
                            ),
                            y: point.plotY
                        };
                    } else {
                        position = {
                            x: point.series.chart.plotLeft,
                            y: point.series.yAxis.top - chart.plotTop
                        };
                    }

                    return position;
                }
            },     
            series: 
                [{
                    type: 'candlestick',
                    id: 'aapl-ohlc',
                    name: 'AAPL Stock Price',
                    data: []
                }, {
                    type: 'column',
                    id: 'aapl-volume',
                    name: 'AAPL Volume',
                    data: [],
                    yAxis: 1
                }],
            responsive: 
            {
                rules: 
                    [{
                        condition: {
                        },
                        chartOptions: {
                            rangeSelector: {
                                inputEnabled: false
                            }
                        }
                    }]
            }
        },
    }
  },
  components: {
  },
  computed:{
  },
  methods: {
     resizeRK(width,height) {
        let vm= this;
        document.getElementById('containerKR').style.width =  width + 'px';
        document.getElementById('containerKR').style.height =  height - 40+ 'px';
        if(vm.chartR!=null)
            vm.chartR.reflow();      
     },
     maxSize()
     {
         this.chartR.$parent.$parent.$parent.$parent.$parent.$parent.$parent.maxSize();
     },
     getCandleStickData()
     {
        let vm= this;
        axios.get('https://demo-live-data.highcharts.com/aapl-ohlcv.json')
            .then(response=>
            {
                var data=response.data;
                var dataLength = data.length
                var ohlc = []
                var volume = []             
                var i = 0
                for (i; i < dataLength; i += 1) {
                    if(i>=10)
                      break;
                    ohlc.push([
                        data[i][0], // the date
                        data[i][1], // open
                        data[i][2], // high
                        data[i][3], // low
                        data[i][4] // close
                    ]);
                    volume.push([
                        data[i][0], // the date
                        data[i][5] // the volume
                    ]);
                }
                vm.ChartOptions.series[0].data = ohlc;
                vm.ChartOptions.series[1].data = volume;
                Highcharts.setOptions({
                    stockTools: 
                    {
                        gui: 
                        {
                            buttons:['indicators',
                                        //'simpleShapes',
                                        'lines',
                                        'crookedLines',
                                        //'measure',
                                        'advanced',
                                        //'toggleAnnotations',
                                        //'verticalLabels',
                                        'flags',
                                        'zoomChange',
                                        'fullScreen',
                                        'typeChange',
                                        'currentPriceIndicator',
                                        'customZoom'],
                            definitions: 
                            {
                                customZoom: 
                                {
                                    className: 'highcharts-custom-zoom',
                                },
                            }                                    
                        },  
                    },
                    navigation: 
                    {
                        bindings: 
                        {
                            customZoom: 
                            {
                                className: 'highcharts-custom-zoom',
                                    init: function(e) 
                                    {
                                        vm.$parent.$parent.$parent.$parent.$parent.$parent.$parent.maxSize();
                                    }
                            }
                        }
                    },   
                });
                vm.chartR=Highcharts.stockChart('containerKR',vm.ChartOptions);
                var height = document.body.clientHeight*4/6-125;
                var width = document.body.clientWidth*0.8/2-15;
                vm.resizeRK(width,height);                
            })

     }, 
  },
  mounted () {
        let vm=this;
        vm.getCandleStickData();	   
  },
};
function addPopupEvents(chart) {
    // var closePopupButtons = document.getElementsByClassName('highcharts-close-popup');
    // // Close popup button:
    // Highcharts.addEvent(
    //     closePopupButtons[0],
    //     'click',
    //     function () {
    //         this.parentNode.style.display = 'none';
    //     }
    // );

    // Highcharts.addEvent(
    //     closePopupButtons[1],
    //     'click',
    //     function () {
    //         this.parentNode.style.display = 'none';
    //     }
    // );

    // Add an indicator from popup
    // Highcharts.addEvent(
    //     document.querySelectorAll('.highcharts-popup-indicators button')[0],
    //     'click',
    //     function () {
    //         var typeSelect = document.querySelectorAll(
    //                 '.highcharts-popup-indicators select'
    //             )[0],
    //             type = typeSelect.options[typeSelect.selectedIndex].value,
    //             period = document.querySelectorAll(
    //                 '.highcharts-popup-indicators input'
    //             )[0].value || 14;

    //         chart.addSeries({
    //             linkedTo: 'aapl-ohlc',
    //             type: type,
    //             params: {
    //                 period: parseInt(period, 10)
    //             }
    //         });

    //         chart.stockToolbar.indicatorsPopupContainer.style.display = 'none';
    //     }
    // );

    // // Update an annotaiton from popup
    // Highcharts.addEvent(
    //     document.querySelectorAll('.highcharts-popup-annotations button')[0],
    //     'click',
    //     function () {
    //         var strokeWidth = parseInt(
    //                 document.querySelectorAll(
    //                     '.highcharts-popup-annotations input[name="stroke-width"]'
    //                 )[0].value,
    //                 10
    //             ),
    //             strokeColor = document.querySelectorAll(
    //                 '.highcharts-popup-annotations input[name="stroke"]'
    //             )[0].value;

    //         // Stock/advanced annotations have common options under typeOptions
    //         if (chart.currentAnnotation.options.typeOptions) {
    //             chart.currentAnnotation.update({
    //                 typeOptions: {
    //                     lineColor: strokeColor,
    //                     lineWidth: strokeWidth,
    //                     line: {
    //                         strokeWidth: strokeWidth,
    //                         stroke: strokeColor
    //                     },
    //                     background: {
    //                         strokeWidth: strokeWidth,
    //                         stroke: strokeColor
    //                     },
    //                     innerBackground: {
    //                         strokeWidth: strokeWidth,
    //                         stroke: strokeColor
    //                     },
    //                     outerBackground: {
    //                         strokeWidth: strokeWidth,
    //                         stroke: strokeColor
    //                     },
    //                     connector: {
    //                         strokeWidth: strokeWidth,
    //                         stroke: strokeColor
    //                     }
    //                 }
    //             });
    //         } else {
    //             // Basic annotations:
    //             chart.currentAnnotation.update({
    //                 shapes: [{
    //                     'stroke-width': strokeWidth,
    //                     stroke: strokeColor
    //                 }],
    //                 labels: [{
    //                     borderWidth: strokeWidth,
    //                     borderColor: strokeColor
    //                 }]
    //             });
    //         }
    //         chart.stockToolbar.annotationsPopupContainer.style.display = 'none';
    //     }
    // );


}
</script>
<style>
.highcharts-scrollbar-arrow
{
     width:25px;
     height: 14px !important;
}
.highcharts-scrollbar-thumb
{
    height: 14px !important;
    display: block;
}
.highcharts-scrollbar
{
    height: 14px !important;
    display: block;    
}
.highcharts-background .highcharts-root .highcharts-container {
  max-height: 100%;
  max-width: 100%;
}
/* GENERAL */

/* .chart {
    float: left;
    position: relative;
} */
.container-fluid
{
    height: 100% !important;
    width: 100% !important;
    overflow: auto;
}
.highcharts-draw-mode {
    cursor: crosshair;
}
.highcharts-background, .highcharts-root, .highcharts-container {
  max-height: 100%;
  max-width: 100%;
}

.highcharts-stocktools-wrapper, .highcharts-bindings-wrapper, .highcharts-bindings-container
{
    height: 0px !important;
}

/* FULL SCREEN */
/* .chart:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.chart:-moz-full-screen {
    width: 100%;
    height: 100%;
}

.chart:-ms-fullscreen {
    width: 100%;
    height: 100%;
}

.chart:fullscreen {
    width: 100%;
    height: 100%;
}

/* GUI */

.highcharts-stocktools-wrapper {
    display: block;
}

.highcharts-stocktools-toolbar {
    margin: 0 0 0 10px;
    padding: 0;
    width: calc(100% - 63px);
}

.highcharts-stocktools-toolbar li {
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    cursor: pointer;
    float: left;
    height: 40px;
    list-style: none;
    margin-right: 2px;
    margin-bottom: 3px;
    padding: 0;
    position: relative;
    width: auto;
}

.highcharts-stocktools-toolbar li ul {
    display: none;
    left: 0;
    padding-left: 0;
    position: absolute;
    z-index: 125;
}

.highcharts-stocktools-toolbar li ul li {
    margin-bottom: 0;
    width: 160px;
}

.highcharts-stocktools-toolbar li:hover {
    background-color: #e6ebf5;
}

.highcharts-stocktools-toolbar li:hover ul {
    display: block;
}

.highcharts-stocktools-toolbar li > span.highcharts-menu-item-btn {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    float: left;
    height: 100%;
    width: 25px;
}

.highcharts-stocktools-toolbar li > .highcharts-menu-item-title {
    color: #666;
    line-height: 40px;
    font-size: 0.876em;
    padding: 0 10px 0 5px;
}

.highcharts-indicators > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/indicators.svg");
}

.highcharts-label-annotation > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/label.svg");
}

.highcharts-circle-annotation > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/circle.svg");
}

.highcharts-rectangle-annotation > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/rectangle.svg");
}

.highcharts-ellipse-annotation > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/ellipse.svg");
}

.highcharts-segment > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/segment.svg");
}

.highcharts-arrow-segment > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-segment.svg");
}

.highcharts-ray > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/ray.svg");
}

.highcharts-arrow-ray > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-ray.svg");
}

.highcharts-infinity-line > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/line.svg");
}

.highcharts-arrow-infinity-line > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-line.svg");
}

.highcharts-horizontal-line > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/horizontal-line.svg");
}

.highcharts-vertical-line > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-line.svg");
}

.highcharts-elliott3 > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/elliott-3.svg");
}

.highcharts-elliott5 > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/elliott-5.svg");
}

.highcharts-crooked3 > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/crooked-3.svg");
}

.highcharts-crooked5 > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/crooked-5.svg");
}

.highcharts-measure-xy > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-xy.svg");
}

.highcharts-measure-x > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-x.svg");
}

.highcharts-measure-y > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-y.svg");
}

.highcharts-fibonacci > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/fibonacci.svg");
}

.highcharts-pitchfork > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/pitchfork.svg");
}

.highcharts-parallel-channel > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/parallel-channel.svg");
}

.highcharts-toggle-annotations > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/annotations-visible.svg");
}

.highcharts-vertical-counter > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-counter.svg");
}

.highcharts-vertical-label > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-label.svg");
}

.highcharts-vertical-arrow > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-arrow.svg");
}

.highcharts-vertical-double-arrow > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-double-arrow.svg");
}

.highcharts-flag-circlepin > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-elipse.svg");
}

.highcharts-flag-diamondpin > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-diamond.svg");
}

.highcharts-flag-squarepin > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-trapeze.svg");
}

.highcharts-flag-simplepin > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-basic.svg");
}

.highcharts-zoom-xy > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-xy.svg");
}
.highcharts-custom-zoom{
    background-image: url("https://code.highcharts.com/gfx/stock-icons/fullscreen.svg");
}
.highcharts-zoom-x > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-x.svg");
}

.highcharts-zoom-y > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-y.svg");
}

.highcharts-full-screen > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/fullscreen.svg");
}

.highcharts-series-type-ohlc > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/series-ohlc.svg");
}

.highcharts-series-type-line > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/series-line.svg");
}

.highcharts-series-type-candlestick > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/series-candlestick.svg");
}

.highcharts-current-price-indicator > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/current-price-show.svg");
}

.highcharts-save-chart > .highcharts-menu-item-btn {
    background-image: url("https://code.highcharts.com/gfx/stock-icons/save-chart.svg");
}

li.highcharts-active {
    background-color: #e6ebf5;
}

/* Popup */


/* Responsive design */

@media screen and (max-width: 1024px) {
    .highcharts-stocktools-toolbar li > .highcharts-menu-item-title {
        display: none;
    }

    .highcharts-stocktools-toolbar li ul li {
        width: auto;
    }
}
@import '../assets/highcharts/code/css/stocktools/gui.css';
@import '../assets/highcharts/code/css/annotations/popup.css';
</style>
