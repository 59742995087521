import Vue from "vue";
import VueRouter from "vue-router";
import stock from "@/components/stock";
import signalR from "@/components/signalR";
import MySignalR from "@/components/MySignalR";
import test from "@/components/test";
import test2 from "@/components/test2";
import HighChartPopupL from "@/components/HighChartPopupL";
import HighChartPopupR from "@/components/HighChartPopupR";
import KLeft from "@/components/KLeft";
//import KLeft from "@/components/KLeft";
import spilter from "@/components/spilter";
import order from "@/components/order";
import TestMQ from "@/components/TestMQ";
import productGrid from "@/components/productGrid";
Vue.use(VueRouter);

export const routes = [   
  // {
  //   path: "/",
  //   name: "highChart",
  //   component: highChart,    
  // },  
   {
    path: "/",
    name: "stock",
    component: stock,    
    children: [
      {
        path: "/productGrid",
        name: "productGrid",
        component: productGrid,    
      },
    ]
  },

  //   {
  //   path: "/productGrid",
  //   name: "productGrid",
  //   component: productGrid,    
  // },
  //productGrid.vue
  // {
  //   path: "/123",
  //   name: "test",
  //   component: test,    
  // }, 
  // {
  //   path: "/",
  //   name: "mainPage",
  //   component: mainPage,    
  // },  
  // {
  //   path: "/",
  //   name: "toolTipEdit",
  //   component: toolTipEdit,    
  // },    
  // {
  //   path: "/",
  //   name: "VueAnnotationSort",
  //   component: VueAnnotationSort,    
  // },    
  // {
  //   path: "/",
  //   name: "ThreeDViewerLayout",
  //   component: ThreeDViewerLayout,    
  // },   
  // {
  //   path: "/",
  //   name: "toolTip",
  //   component: toolTip,    
  // },  
  // {
  //   path: "/",
  //   name: "BoxDatGui",
  //   component: BoxDatGui,    
  // },    
  // {
  //   path: "/",
  //   name: "LabelsOn3DObject",
  //   component: LabelsOn3DObject,    
  // },      
  // {
  //   path: "/",
  //   name: "EditModel",
  //   component: EditModel,    
  // },      
  // {
  //   path: "/",
  //   name: "ThreeDviewer",
  //   component: ThreeDViewer,    
  // },      
  // {
  //   path: "/",
  //   name: "Task",
  //   component: Task,    
  // },     
  // {
  //   path: "/",
  //   name: "bopomofo",
  //   component: bopomofo,    
  // },   
  // {
  //   path: "/",
  //   name: "mainPage",
  //   component: mainPage,
  //   children: [
  //     {
  //       path: '/',
  //       component: Right
  //     },
  //     {
  //       path: "/ClassOpenToRegisterList",
  //       name: "ClassOpenToRegisterList",
  //       component: ClassOpenToRegisterList,    
  //     },    
  //     {
  //       path: "/WebFAQ",
  //       name: "WebFAQ",
  //       component: WebFAQ,    
  //     },   
  //     {
  //       path: "/WebLink",
  //       name: "WebLink",
  //       component: WebLink,    
  //     },     
  //     {
  //       path: "/Download",
  //       name: "Download",
  //       component: Download,    
  //     },   
  //     {
  //       path: "/newsItem",
  //       name: "newsItem",
  //       component: newsItem,    
  //     },
  //     {
  //       path: "/Service",
  //       name: "Service",
  //       component: Service,    
  //     },    
  //     {
  //       path: "/About",
  //       name: "About",
  //       component: About,    
  //     },    
  //     {
  //       path: "/Login",
  //       name: "Login",
  //       component: Login,    
  //     },          
  //     {
  //       path: "/UnderConstruction",
  //       name: "UnderConstruction",
  //       component: UnderConstruction,    
  //     },                                 
  //   ]    
  // },        
  // {
  //   path: '/manager',
  //   name: "layout",
  //   component:layout,
  //   meta: { requiresAuth: true},
  //   children:[ 
  //     {
  //       path: "/UploadCatImg",
  //       name: "UploadCatImg",
  //       component: UploadCatImg,
  //     },       
  //     {
  //       path: "/QualityControlDocFileList",
  //       name: "QualityControlDocFileList",
  //       component: QualityControlDocFileList,
  //     },        
  //     {
  //       path: "/EmailNotification",
  //       name: "EmailNotification",
  //       component: EmailNotification,    
  //     },         
  //     { 
  //       path: '/QualifyCheck', 
  //       name: 'QualifyCheck', 
  //       component: QualifyCheck,
  //       meta: { requiresAuth: true},
  //      }, 
  //      {
  //       path: '/EditClassCat',
  //       name: "EditClassCat",
  //       component: EditClassCat,
  //       meta: { requiresAuth: true},
  //     },  
  //     {
  //       path: '/EditNews',
  //       name: "EditNews",
  //       component: EditNews,
  //       meta: { requiresAuth: true},
  //     },   
  //     {
  //       path: "/ClassLocation",
  //       name: "ClassLocation",
  //       component: ClassLocation,
  //       meta: { requiresAuth: true},   
  //     },  
  //     {
  //       path: "/ClassInfo",
  //       name: "ClassInfo",
  //       component: ClassInfo,
  //       meta: { requiresAuth: true},   
  //     },    
  //     {
  //       path: "/Teacher",
  //       name: "Teacher",
  //       component: Teacher,
  //       meta: { requiresAuth: true},   
  //     },  
  //     {
  //       path: "/ClassSession",
  //       name: "ClassSession",
  //       component: ClassSession,
  //       meta: { requiresAuth: true},    
  //     },   
  //     {
  //       path: "/Dormitory",
  //       name: "Dormitory",
  //       component: Dormitory,
  //       meta: { requiresAuth: true},    
  //     }, 
  //     {
  //       path: "/SignUp",
  //       name: "SignUp",
  //       component: SignUp,
  //       meta: { requiresAuth: true},    
  //     }, 
  //     {
  //       path: "/RoleAdd",
  //       name: "RoleAdd",
  //       component: RoleAdd,
  //       meta: { requiresAuth: true},    
  //     },    
  //     {
  //       path: "/Scheduler",
  //       name: "/Scheduler",
  //       component: Scheduler,
  //       meta: { requiresAuth: true},    
  //     }, 
  //     {
  //       path: "/QualityControlDocTemplate",
  //       name: "/QualityControlDocTemplate",
  //       component: QualityControlDocTemplate,
  //       meta: { requiresAuth: true},    
  //     },   
  //     {
  //       path: "/QualityControlDocUploadApproval",
  //       name: "/QualityControlDocUploadApproval",
  //       component: QualityControlDocUploadApproval,
  //       meta: { requiresAuth: true},    
  //     }, 
  //     {
  //       path: "/QualityControlDocApproval",
  //       name: "/QualityControlDocApproval",
  //       component: QualityControlDocApproval,
  //       meta: { requiresAuth: true},    
  //     },                                 
  //     ] 
  // },           
  // {
  //   path: "/Register",
  //   name: "Register",
  //   component: Register,
  //   children: [
  //     {
  //       path: "/",
  //       name: "ModalView",
  //       component: ModalView,  
  //     },
  //   ] 
  // },  
  // {
  //   path: "/PersonalInformation",
  //   name: "PersonalInformation",
  //   component: PersonalInformation   
  // },
  // {
  //   path: "/RegisterOk",
  //   name: "RegisterOk",
  //   component: RegisterOk   
  // },
];

const router = new VueRouter({
  model: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
