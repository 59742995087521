<template>

      <highcharts :constructorType="'stockChart'" :options="ChartOptions">
      </highcharts>    

</template>
<script>

import axios from "axios";
export default {
  name: "test",
data: function(){
  return{
    ChartOptions: {
        yAxis: [{
                    labels: {
                        align: 'left'
                    },
                    height: '80%',
                    resize: {
                        enabled: true
                    }
                }, {
                    labels: {
                        align: 'left'
                    },
                    top: '80%',
                    height: '20%',
                    offset: 0
                }],

        stockTools: {
          gui: {
            buttons:['indicators',
                      //'simpleShapes',
                      'lines',
                      'crookedLines',
                      //'measure',
                      'advanced',
                      //'toggleAnnotations',
                      //'verticalLabels',
                      'flags',
                      'zoomChange',
                      'fullScreen',
                      //'typeChange',
                      'currentPriceIndicator']
          }
        },
  exporting: {
    enabled: true,
    buttons: {
        contextButton: {
            height: 20,
            width: 20,
            symbolSize: 12,
            symbolX: 10,
            symbolY: 10,
            symbolStrokeWidth: 1
      }
    }
  },                
        series: [{
            type: 'candlestick',
            id: 'aapl-ohlc',
            name: 'AAPL Stock Price',
            data: [[1635379200000, 125.17, 126.31, 124.62, 125.84],
                    [1635465600000, 125.43, 126.32, 124.91, 125.1],
                    [1635724800000, 125.05, 126.31, 123.8375, 126.28],
                    [1635811200000, 126.3, 127.17, 124.91, 126.18],
                    [1635897600000, 126.23, 127.29, 125.68, 127.13],
                    [1635984000000, 123.05, 123.34, 119.9, 120.85],
                    [1636070400000, 121.43, 123.77, 121.43, 123.61],
                    [1636329600000, 123.985, 124.78, 123.53, 124.54],
                    [1636416000000, 122.56, 122.9, 120.26, 120.85],
                    [1636502400000, 121, 122.43, 119.932, 120.22],
                    [1636588800000, 120.9, 121.7894, 120.08, 120.27]]
        }, {
            type: 'column',
            id: 'aapl-volume',
            name: 'AAPL Volume',
            data: [[1635379200000, 1000],
                    [1635465600000, 1000],
                    [1635724800000, 1000],
                    [1635811200000, 1000],
                    [1635897600000, 1000],
                    [1635984000000, 1000],
                    [1636070400000, 1000],
                    [1636329600000, 1000],
                    [1636416000000, 1000],
                    [1636502400000, 1000],
                    [1636588800000, 1000]],
            yAxis: 1
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    rangeSelector: {
                        inputEnabled: false
                    }
                }
            }]
        }
    },
  }
  },
  
  components: {
    //pagination,
  },
  methods: {   
     getCandleStickData()
     {
        axios.get('https://demo-live-data.highcharts.com/aapl-ohlcv.json')
            .then(response=>
            {
                var data=response.data;
                var dataLength = data.length
                var ohlc = []
                var volume = []                
                var i = 0
                for (i; i < dataLength; i += 1) {
                    ohlc.push([
                        data[i][0], // the date
                        data[i][1], // open
                        data[i][2], // high
                        data[i][3], // low
                        data[i][4] // close
                    ]);
                    volume.push([
                        data[i][0], // the date
                        data[i][5] // the volume
                    ]);
                }
                this.ChartOptions.series[0].data = ohlc;
                this.ChartOptions.series[1].data = volume;
                // this.ohlc = ohlc;
                // this.volume = volume;
                // console.log(ohlc)
            })
     },       
  },
    mounted () {
        this.getCandleStickData();	
	},
};
</script>
<style>

.highcharts-bindings-wrapper li > span.highcharts-menu-item-btn {
    background-size: 18px 18px !important;
}
.highcharts-stocktools-toolbar li {
    height: 25px !important;
    width: 25px !important;
}

</style>