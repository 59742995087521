<template>
    <div class="modal-content boxshowdd">
                <div class="modal-body">
                    <div class="outerbox">
                        <div class="innerbox ft_table" style="width:100%;">
                            <div class=" ft_title" style="width:100%;">
                                <table class="infotablesym" style="width:100%;">
                                    <tbody><tr>
                                        <td class="markettitlemxwex" style="width:100%;height:27px;" data-aa="a108">名詞說明</td>
                                    </tr>
                                </tbody></table>
                            </div>
                            <div class=" ft_div" style="width:100%;height:450px;overflow-x: hidden ;overflow-y:auto; outline: none; margin-top: 27px;border-bottom:1px solid #dee2e6;">
                                <div class="ft_divfixed ruletable" id="nametable">
                                    <table>
                                        <tbody>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" style="width:97px;height:27px;text-align:left;padding-left:5px;" data-aa="n96">分類</td>
                                                <td class="myrule3" style="width:503px;height:27px;text-align:center;" data-aa="a108">說明</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b1">多單</td>
                                                <td class="myrule3">為買升(漲)以達到獲利目的所下的單</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b2">空單</td>
                                                <td class="myrule3">為買跌以達到獲利目的所下的單</td>
                                            </tr>
                                            <tr class="markettdinfo ">
                                                <td class="myrule1" data-aa="b5">餘額</td>
                                                <td class="myrule3" data-aa="b6">帳戶可以使用的額度</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b7">強平</td>
                                                <td class="myrule3 myspaceee" data-aa="b8">強制平倉(關艙)的簡稱，強平會發生於餘額不足強平額度時發生，以及當日行情漲跌幅到達系統設定強平點位時，所有在倉單之多單、空單，系統將所有在倉單強制以下一筆報價平倉</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b11">強平額度</td>
                                                <td class="myrule3" data-aa="b12">餘額不足強平額度時，系統執行強平的額度標準</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b13">留倉</td>
                                                <td class="myrule3" data-aa="b14">若您所下的單直到收盤都不平倉，要留至下一次開盤，即為留倉</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b15">權益數</td>
                                                <td class="myrule3" data-aa="b16">您帳戶的可用餘額以及留倉保證金的總合</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b17">口數</td>
                                                <td class="myrule3" data-aa="b18">即本系統所下單的單位數量</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b19">開盤風控點數</td>
                                                <td class="myrule3 myspaceee" data-aa="b20">因市場因素，開盤時的第一盤報價點數無法預測，本系統提供開盤風控點數供客戶設定，如此可將您的開盤虧損可能性控制在您的開盤風控點數不會有供客戶設定，如此可將您的開盤虧損可能性控制在您的開盤風控點數不會有超額虧損之情形，開盤風控點數也是您留倉保證金的計算依據，(留倉保證金=點數X商品每點價格/手)</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b25">留倉保證金</td>
                                                <td class="myrule3 myspaceee" data-aa="b26">留倉時所需預先從帳戶扣除的金額，也是您留倉單的最大虧損的極限，只要沒有出現超額的開盤虧損，留倉保證金將於商品下次開盤時，自動返還於帳戶餘額</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b29">手續費</td>
                                                <td class="myrule3" data-aa="b30">每次進行下單時所需付出的下單費用</td>
                                            </tr>

                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b31">每點價格</td>
                                                <td class="myrule3" data-aa="b32">商品每跳動一整點的價值</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b33">最小跳動點</td>
                                                <td class="myrule3" data-aa="b34">商品最小跳動點數</td>
                                            </tr>

                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b35">最小損益點</td>
                                                <td class="myrule3" data-aa="b36">商品持有平(關)艙所需的與你下單價時價位點數差額限制</td>
                                            </tr>

                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b37">口數上限</td>
                                                <td class="myrule3" data-aa="b38">本帳戶最多可持有的口(手)數上限</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b39">開盤時間</td>
                                                <td class="myrule3" data-aa="b40">商品於市場開始交易的時間</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b41">收盤時間</td>
                                                <td class="myrule3" data-aa="b42">商品於市場結束交易的時間</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b43">停損點</td>
                                                <td class="myrule3" data-aa="b44">預設實現虧損的價位</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b45">停利點</td>
                                                <td class="myrule3" data-aa="b46">預設實現盈利的價位</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b47">市價單</td>
                                                <td class="myrule3" data-aa="b48">不計價格以當時價格立刻要成交</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b49">限價單</td>
                                                <td class="myrule3" data-aa="b50">不計時間以預設價格等待成交</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b51">未成交</td>
                                                <td class="myrule3" data-aa="b52">訂單送出尚未成交</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b53">換倉日</td>
                                                <td class="myrule3 myspaceee" data-aa="b54">交易商品是有其限的，換倉日就是下期的首交易日，換倉日的前一日就是當期的商品最後交易日</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b56">漲跌幅</td>
                                                <td class="myrule3 myspaceee" data-aa="b57">今日報價漲跌除以昨日收市價就可計算出當日漲跌的百分比就是漲跌幅</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b58">轉新單</td>
                                                <td class="myrule3 myspaceee" data-aa="b59">系統會在每日的固定時間結算作為今日與明日的損益計算，當您手中的單跨越過這個計算時間時系統將會根據當時報價執行轉新單(不收手續費)將損益區分為昨日與今日</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b62">移動查價</td>
                                                <td class="myrule3" data-aa="b63">圖表功能:移動十字線同時顯示價格資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b64">顯示格線</td>
                                                <td class="myrule3" data-aa="b65">圖表功能:底圖格線顯示</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b66">操盤線</td>
                                                <td class="myrule3" data-aa="b67">圖表功能:顯示您手中持倉的價格線，以及未成交單的價格線</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b68">資料視窗</td>
                                                <td class="myrule3" data-aa="b69">圖表功能:主圖表左上方指標數值</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b70">成交提示</td>
                                                <td class="myrule3" data-aa="b71">當有單成交時跳出提示</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b72">損益設定</td>
                                                <td class="myrule3" data-aa="b73">未平(關)倉的單可以設定停損點或停利點的設定</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b74">點數輸入</td>
                                                <td class="myrule3" data-aa="b75">輸入價格的模式</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b76">行情輸入</td>
                                                <td class="myrule3" data-aa="b77">輸入跳動點單位元模式</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b78">餘額顯示</td>
                                                <td class="myrule3" data-aa="b79">餘額視窗顯示方式</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">已選商品</td>
                                                <td class="myrule3" data-aa="b81">選取顯示於市場總覽畫面的商品</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">可選商品</td>
                                                <td class="myrule3" data-aa="b81">隱藏的商品</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">倉位多</td>
                                                <td class="myrule3" data-aa="b81">顯示當前持倉</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">倉位空</td>
                                                <td class="myrule3" data-aa="b81">顯示當前持倉</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">買進價</td>
                                                <td class="myrule3" data-aa="b81">市場詢價資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">賣出價</td>
                                                <td class="myrule3" data-aa="b81">市場詢價資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">成交價</td>
                                                <td class="myrule3" data-aa="b81">市場成交資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">漲跌</td>
                                                <td class="myrule3" data-aa="b81">當前價格較前日收市價格的差額點數</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">單量</td>
                                                <td class="myrule3" data-aa="b81">市場成交數量資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">總量</td>
                                                <td class="myrule3" data-aa="b81">市場成交總數量資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">昨收價</td>
                                                <td class="myrule3" data-aa="b81">昨日市場收市價</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">開盤價</td>
                                                <td class="myrule3" data-aa="b81">當日開市第一筆價</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">最高價</td>
                                                <td class="myrule3" data-aa="b81">當日出現最高價</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">最低價</td>
                                                <td class="myrule3" data-aa="b81">當日出現最低價</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">時間</td>
                                                <td class="myrule3" data-aa="b81">最新成交出現時間</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">交易類型</td>
                                                <td class="myrule3" data-aa="b81">系統交易狀態</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b80">禁止新單</td>
                                                <td class="myrule3 myspaceee" data-aa="b81">禁止下新單，但可下平(關)倉單，系統會於市場低量時間設定此狀態請參閱交易時間表</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">最後成交價</td>
                                                <td class="myrule3" data-aa="b81">留倉單計算損益的依據價位</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">最後交易日</td>
                                                <td class="myrule3" data-aa="b81">當期交易商品的最後持有日，收盤到期將強平</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">委買</td>
                                                <td class="myrule3" data-aa="b81">市場詢價資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">委賣</td>
                                                <td class="myrule3" data-aa="b81">市場詢價資訊</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">全部單</td>
                                                <td class="myrule3" data-aa="b81">當日所有掛單</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">序號</td>
                                                <td class="myrule3" data-aa="b81">系統交易單編號</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">商品</td>
                                                <td class="myrule3" data-aa="b81">交易商品</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">委託價</td>
                                                <td class="myrule3" data-aa="b81">您限定交易之價格</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">口數</td>
                                                <td class="myrule3" data-aa="b81">(同手數)交易數量</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">成交價</td>
                                                <td class="myrule3" data-aa="b81">成交的價格</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">下單時間</td>
                                                <td class="myrule3" data-aa="b81">送交訂單時間</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">完成時間</td>
                                                <td class="myrule3" data-aa="b81">訂單成交時間</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">型別</td>
                                                <td class="myrule3" data-aa="b81">成交的型別網路下單或是系統強制</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">損失點</td>
                                                <td class="myrule3" data-aa="b81">您所設定停損點</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">獲利點</td>
                                                <td class="myrule3" data-aa="b81">您所設定停利點</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">狀態</td>
                                                <td class="myrule3" data-aa="b81">訂單狀態</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">未平倉</td>
                                                <td class="myrule3" data-aa="b81">手中持有之成交訂單損益隨市場跳動</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">不留倉</td>
                                                <td class="myrule3" data-aa="b81">此勾選後將於市場收市後直接於最後成交價平(關)艙</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">市價平倉</td>
                                                <td class="myrule3" data-aa="b81">市價平(關)艙</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">報價</td>
                                                <td class="myrule3" data-aa="b81">當時價格</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">未平損益</td>
                                                <td class="myrule3" data-aa="b81">手中持有之成交訂單損益隨市場跳動</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">天數</td>
                                                <td class="myrule3" data-aa="b81">持有該筆單的天數</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">昨日損益</td>
                                                <td class="myrule3" data-aa="b81">該筆單昨日的損益</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">多空並存</td>
                                                <td class="myrule3" data-aa="b81">用戶權限:可同時持有同一商品多單與空單進行鎖單的</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">口數上限</td>
                                                <td class="myrule3" data-aa="b81">最高可持有的上限</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">所需保證金</td>
                                                <td class="myrule3" data-aa="b81">每下一口(手)單所需要的餘額</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">昨日權益數</td>
                                                <td class="myrule3" data-aa="b81">昨日的帳戶總額</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">今日損益</td>
                                                <td class="myrule3" data-aa="b81">今日的損益</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">餘額</td>
                                                <td class="myrule3" data-aa="b81">帳戶可用餘額</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">強平額度</td>
                                                <td class="myrule3" data-aa="b81">持倉強制平倉的餘額標準</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">留倉保證金</td>
                                                <td class="myrule3" data-aa="b81">留倉單所預扣的保證金</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">總權益數</td>
                                                <td class="myrule3" data-aa="b81">帳戶總值</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">現價</td>
                                                <td class="myrule3" data-aa="b81">當前商品價格</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">買升</td>
                                                <td class="myrule3" data-aa="b81">買進後若價格高於買進價即獲利</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">買跌</td>
                                                <td class="myrule3" data-aa="b81">買進後若價格低於買進價即獲利</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b80">報價明細</td>
                                                <td class="myrule3" data-aa="b81">市場每筆報價的明細</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </template>
  
  <script>
  export default {};
  </script>