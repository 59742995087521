<template>
    <div>
        <b-tabs class="dv-b" style="background-color:#181818;font-size:0.9rem">
            <b-tab :title="renderTabTitle('全部')" active>
                <productGrid id="productAllGrid" ref="productAllGrid" v-on:productsResponse="getProductsResponse"></productGrid>
                <!-- <test id="productGrid" ref="productGrid"></test> -->
            </b-tab>
            <!-- <b-tab title="指數">
            <productGrid ref="productGrid"
            v-on:productsResponse="getProductsResponse"
            ></productGrid>
            <div style="color:white">指數</div>
        </b-tab> -->
            <b-tab :title="renderTabTitle('TWEMERGING')">
                <productGrid_TWEMERGING id="productTWEMERGINGGrid" ref="productTWEMERGINGGrid" v-on:productsResponse="getProductsResponse">
                </productGrid_TWEMERGING>
                <!-- <div style="color:white">指數期貨</div> -->
            </b-tab>
            <b-tab :title="renderTabTitle('TAIFEX')">
                <productGrid_TAIFEX id="productTAIFEXGrid" ref="productTAIFEXGrid" v-on:productsResponse="getProductsResponse"></productGrid_TAIFEX>
                <!-- <div style="color:white">商品期貨</div> -->
            </b-tab>
            <b-tab :title="renderTabTitle('TWSE')">
                <productGrid_TWSE id="productTWSEGrid" ref="productTWSEGrid" v-on:productsResponse="getProductsResponse"></productGrid_TWSE>
                <!-- <div style="color:white">加密貨幣</div> -->
            </b-tab>
        </b-tabs>
    </div>
</template>
<script src="../assets/js/base64.js"></script>
<script src="../assets/js/basic.js"></script>
<script src="../assets/js/bootstrap.bundle.min.js"></script>
<script src="../assets/js/bootstrap-datetimepicker.min.js"></script>
<script src="../assets/js/color-picker.min.js"></script>
<script src="../assets/js/gunzip.min.js"></script>
<script src="../assets/js/jQuery.fastClick.js"></script>
<script src="../assets/js/jquery.nicescroll.js"></script>
<script src="../assets/js/jquery.translate.js"></script>
<script src="../assets/js/jquery-3.0.0.min.js"></script>
<script src="../assets/js/jsbn.js"></script>
<script src="../assets/js/kChart.js"></script>
<script src="../assets/js/kkk.js"></script>
<script src="../assets/js/loadBar.js"></script>
<script src="../assets/js/main.js"></script>
<script src="../assets/js/modernizr-custom.js"></script>
<script src="../assets/js/mTW.js"></script>
<script src="../assets/js/prng4.js"></script>
<script src="../assets/js/rng.js"></script>
<script src="../assets/js/rsa.js"></script>
<script src="../assets/js/splitter.js"></script>
<script src="../assets/js/tinyscrollbar.js"></script>
<script src="../assets/js/top.js"></script>
<script src="../assets/js/tread.js"></script>
<script src="../assets/js/value.js"></script>
<style src="../assets/css/bootstrap.min.css"></style>
<style src="../assets/css/bootstrap-datetimepicker.min.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<style src="../assets/css/style.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<script>
    import productGrid from '@/components/productGrid';
    import productGrid_TWEMERGING from '@/components/productGrid_TWEMERGING';
    import productGrid_TAIFEX from '@/components/productGrid_TAIFEX';
    import productGrid_TWSE from '@/components/productGrid_TWSE';
    import test from '@/components/test';
    export default {

        name: 'mainTop',
        data: function () {
            return {

            }
        },
        components: {
            productGrid,
            productGrid_TWEMERGING,
            productGrid_TAIFEX,
            productGrid_TWSE
            //test
        },
        methods: {
            //|20231216|讓tab大小相同|RICKY|
            renderTabTitle(tabName) {
                return (
                    <div style="width: 120px; height: 90%; background-color: black; color: #0d6efd; text-align: center;">
                        {tabName}
                    </div>
                );
            },
            getProductsResponse(ProductsResponse) {
                console.log("getProductsResponse給阿公");
                this.$emit("productsResponse", ProductsResponse);
            },
            resizeLT: function (width, height) {
                document.getElementById('productAllGrid').style.width = width - 10 + 'px';
                document.getElementById('productAllGrid').style.height = height - 53 + 'px';
                document.getElementById('productTWEMERGINGGrid').style.width = width - 10 + 'px';
                document.getElementById('productTWEMERGINGGrid').style.height = height - 53 + 'px';
                document.getElementById('productTAIFEXGrid').style.width = width - 10 + 'px';
                document.getElementById('productTAIFEXGrid').style.height = height - 53 + 'px';
                document.getElementById('productTWSEGrid').style.width = width - 10 + 'px';
                document.getElementById('productTWSEGrid').style.height = height - 53 + 'px';
            },
        }
    };
</script>
<style scoped>
    body {
        margin: 0;
        font-family: var(--bs-font-sans-serif);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .pdnav5 {
        padding: 0 11px;
        font-size: 16px;
        color: #828282;
        border-radius: 0 !important;
    }

    .dv-b {
        width: 100%;
        height: 100%;
        background-color: yellow;
    }
</style>