<template>
  <highcharts id="container" class="chart">
</highcharts>
</template>
<script src="../assets/highcharts/code/highstock.js"></script>
<script src="../assets/highcharts/code/modules/data.js"></script>

<script src="../assets/highcharts/code/indicators/indicators-all.js"></script>
<script src="../assets/highcharts/code/modules/drag-panes.js"></script>

<script src="../assets/highcharts/code/modules/annotations-advanced.src.js"></script>
<script src="../assets/highcharts/code/modules/price-indicator.js"></script>
<script src="../assets/highcharts/code/modules/full-screen.js"></script>

<script src="../assets/highcharts/code/modules/stock-tools.js"></script>

<script src="../assets/highcharts/code/modules/heikinashi.js"></script>
<script src="../assets/highcharts/code/modules/hollowcandlestick.js"></script>

<script>
import axios from "axios";
var Highcharts = require('highcharts/highstock');
export default {
  name: "test2",
  data: function () {
    return {
        ohlc:[],
        volume:[]
    }
  },
  components: {
    //pagination,
  },
  methods: {
     getCandleStickData()
     {
        axios.get('https://demo-live-data.highcharts.com/aapl-ohlcv.json')
            .then(response=>
            {
                data=response.data;
                var ohlc = []
                var volume = []
                var dataLength = data.length
                console.log(dataLength);
                var i = 0
                for (i; i < dataLength; i += 1) {
                    ohlc.push([
                        data[i][0], // the date
                        data[i][1], // open
                        data[i][2], // high
                        data[i][3], // low
                        data[i][4] // close
                    ]);
                    volume.push([
                        data[i][0], // the date
                        data[i][5] // the volume
                    ]);
                }
                this.ohlc=ohlc;
                this.volume=volume;

                                    Highcharts.stockChart('container', {
                                        yAxis: [{
                                            labels: {
                                                align: 'left'
                                            },
                                            height: '80%',
                                            resize: {
                                                enabled: true
                                            }
                                        }, {
                                            labels: {
                                                align: 'left'
                                            },
                                            top: '80%',
                                            height: '20%',
                                            offset: 0
                                        }],
                                        tooltip: {
                                            shape: 'square',
                                            headerShape: 'callout',
                                            borderWidth: 0,
                                            shadow: false,
                                            positioner: function (width, height, point) {
                                                var chart = this.chart,
                                                    position;

                                                if (point.isHeader) {
                                                    position = {
                                                        x: Math.max(
                                                            // Left side limit
                                                            chart.plotLeft,
                                                            Math.min(
                                                                point.plotX + chart.plotLeft - width / 2,
                                                                // Right side limit
                                                                chart.chartWidth - width - chart.marginRight
                                                            )
                                                        ),
                                                        y: point.plotY
                                                    };
                                                } else {
                                                    position = {
                                                        x: point.series.chart.plotLeft,
                                                        y: point.series.yAxis.top - chart.plotTop
                                                    };
                                                }

                                                return position;
                                            }
                                        },
                                        series: [{
                                            type: 'ohlc',
                                            id: 'aapl-ohlc',
                                            name: 'AAPL Stock Price',
                                            data: this.ohlc
                                        }, {
                                            type: 'column',
                                            id: 'aapl-volume',
                                            name: 'AAPL Volume',
                                            data: this.volume,
                                            yAxis: 1
                                        }],
                                        responsive: {
                                            rules: [{
                                                condition: {
                                                    maxWidth: 800
                                                },
                                                chartOptions: {
                                                    rangeSelector: {
                                                        inputEnabled: false
                                                    }
                                                }
                                            }]
                                        }
                                    });

            })
     },
     draw()
     {
    }, 
  },      
    mounted () {
    this.getCandleStickData();
	},
};
</script>









