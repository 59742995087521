<template>
    <div class="modal-content boxshowdd">
                <div class="modal-body">
                    <div class="outerbox">
                        <div class="innerbox ft_table" style="width:100%;">
                            <div class=" ft_title" style="width:100%;">
                                <table class="infotablesym" style="width:100%;">
                                    <tbody>
                                        <tr>
                                            <td class="markettitlemxwex" style="width:100%;height:27px;" data-aa="a108">時間以 <span style="width: 40px; height: 30px; font-weight: 700px; background-color: red; color: white;">冬令</span> <span style="color: red;">(每年十一月第一個星期天開始)</span> 為主，歐美商品於夏令 <span style="color: red;">(每年三月第二個星期天開始)</span> 提前一小時開盤、收盤 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" ft_div" style="width:100%;height:450px;overflow-x: hidden ;overflow-y:auto; outline: none; margin-top: 27px;border-bottom:1px solid #dee2e6;">
                                <div class="ft_divfixed ruletable" id="nametable">
                                    <table>
                                        <tbody>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" style="width:130px;height:27px;text-align:left;padding-left:5px;" data-aa="n96">模擬商品</td>
                                                <td class="myrule3" style="width:90px;height:27px;text-align:center;" data-aa="a108">開盤時間</td>
                                                <td class="myrule3" style="width:90px;height:27px;text-align:center;" data-aa="a108">可交易時間</td>
                                                <td class="myrule3" style="width:90px;height:27px;text-align:center;" data-aa="a108">低量時段(禁新)</td>
                                                <td class="myrule3" style="width:90px;height:27px;text-align:center;" data-aa="a108">交易所</td>
                                            </tr>
                                            <!-- <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b1">元大台灣50</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr> -->
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b2">台指近</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <tr class="markettdinfo ">
                                                <td class="myrule1" data-aa="b5">電指PM近</td>
                                                <td class="myrule3" data-aa="b6">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1 myspaceee" data-aa="b7">那斯達克期PM近</td>
                                                <td class="myrule3 myspaceee" data-aa="b8">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b11">那斯達克期近</td>
                                                <td class="myrule3" data-aa="b12">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b13">小指近</td>
                                                <td class="myrule3" data-aa="b14">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b15">SP期近</td>
                                                <td class="myrule3" data-aa="b16">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b17">金萬林</td>
                                                <td class="myrule3" data-aa="b18">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr>
                                            <!-- <tr class="markettdinfo">
                                                <td class="myrule1" data-aa="b17">星宇航空</td>
                                                <td class="myrule3" data-aa="b18">08:45~13:45</td>
                                                <td class="myrule3">08:45~13:45</td>
                                                <td class="myrule3"></td>
                                                <td class="myrule3">台灣期交所(Taifex)</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </template>
  
  <script>
  export default {};
  </script>