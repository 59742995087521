<template>
    <div class="singlebox " id="mysinglebox" style="height: 100%; overflow: scroll;">
        <table class="talbetaeb singletable" style="height: 300px;">
            <tbody>
                <tr>
                    <td colspan="2" style="padding-left: 10px;">
                        <div>
                            <div class="form-check fleft ">
                                <input type="checkbox" class="form-check-input" id="lockcheck" v-model="orderLockCheck">
                                <label class="form-check-label blackWord" for="lockcheck" data-aa="n78"
                                    style="font-size:14px;">鎖定商品</label>
                            </div>
                            <div class="form-check fleft m10" id="fastcheckBox">
                                <input type="checkbox" class="form-check-input " id="fastcheck" v-model="orderCheck">
                                <label class="form-check-label blackWord" for="fastcheck" data-aa="n72"
                                    style="font-size:14px;">下單不確認</label>
                            </div>
                        </div>
                        <div class="fright">

                            <button type="button" id="singleboxa" class="button white small btnsmsm"
                                style="display: none;">A</button>
                            <button type="button" id="singleboxb" class="button white small btnsmsm" style="">B</button>
                            <button type="button" id="singleboxc" class="button white small btnsmsm" style="">C</button>
                        </div>
                        <!-- 20231105 | HENRY | 這邊新增下單不確認Dialog -->
                        <!-- 20231109 | HENRY | 這邊將Dialog關閉按鈕移除 -->
                        <k-dialog v-if="showDialog" :title="'提示訊息'" :closeIcon="false">
                            <p :style="{ margin: '25px', textAlign: 'center', fontSize: '18px' }">
                                勾選下單不確認，下單、平倉、刪單將不再跳出確認畫面，誤擊或重複點擊所成交的單均需由客戶自行承擔盈虧，不得異議!!</p>
                            <dialog-actions-bar>
                                <kbutton @click='closeDialog'>確定</kbutton>
                            </dialog-actions-bar>
                        </k-dialog>
                        <!-- 20231105 | HENRY | 這邊新增下單確認Dialog -->
                        <k-dialog v-if="showOrderConfirmDialog" :title="'下單確認'" @close="closeOrderConfirmDialog">
                            <p :style="{ margin: '25px', textAlign: 'center', fontSize: '18px' }">您確定要下單嗎？</p>
                            <dialog-actions-bar>
                                <kbutton @click='confirmOrder'>確定</kbutton>
                                <kbutton @click='closeOrderConfirmDialog'>取消</kbutton>
                            </dialog-actions-bar>
                        </k-dialog>
                    </td>
                </tr>

                <!--tr>
            <td>
                <button class="btn btn-lg btn-warring ml-2" @click="Change_showModal()">開啟</button>
                <modal-window
                    :visible="showModal"
                    :close-on-escape="false"
                    :close-on-outside-click="true"
                    :show-x-mark="true"
                    @close = "showModal=false">
                    <h2>用戶登入</h2>
                    <p>輸入</p>
                </modal-window>
            </td>
        </tr-->

                <tr>
                    <td class="text-secondary">
                        <div data-aa="a57" style="text-overflow: ellipsis; width: 38px ;">商品</div>
                    </td>
                    <td>
                        <select v-model="nowSelectProductName_read" id="single_symbol"
                            class="form-control form-control-sm selectpicker w90p"
                            @change="productsName_onChange($event)">
                            <optgroup label="請選擇">
                                <option disabled value="" selected>請選擇產品</option>
                                <Option v-for="(item,index) in product_List" :value="item" :key="index">{{ item }}
                                </Option>
                            </optgroup>
                        </select>
                    </td>
                </tr>
                <tr id="boxa1">
                    <td class="text-secondary" data-aa="n261">買賣</td>
                    <td style="padding-left: 10px;">
                        <div class="form-check fleft ">
                            <input v-model="dealData.buySell" type="radio" name="boxa1type" class="form-check-input"
                                id="single_type1" value="long" checked="checked">
                            <label class="form-check-label" for="single_type1" data-aa="a62" id="single_typew1"
                                style="color:#dc3545">多單</label>
                        </div>
                        <div class="form-check fleft ml15">
                            <input v-model="dealData.buySell" type="radio" name="boxa1type" class="form-check-input"
                                id="single_type2" value="short">
                            <label class="form-check-label" for="single_type2" data-aa="a63" id="single_typew2"
                                style="color:#28a745">空單</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="ml30">
                            <div class="form-check fleft ">
                                <input v-model="marketPrice" type="checkbox" class="form-check-input single_nowPrice"
                                    id="single_nowPrice">
                                <label class="form-check-label blackWord" for="single_nowPrice" data-aa="a58">市價</label>
                                <input style="margin-left: 30px;" v-model="marginPrice" type="checkbox" 
                                    id="single_nowPrice">
                                <label style="margin-left: 10px;" data-aa="a58">原始保證金</label>
                            </div>
                            <!--div class="form-check fleft ml15">
                        <input type="checkbox" class="form-check-input single_closePrice" id="single_closePrice">
                        <label class="form-check-label blackWord" for="single_closePrice" data-aa="a59">收盤價</label>
                    </div-->

                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="text-secondary" data-aa="a60">限價</td>
                    <td>

                        <div v-if="marketPrice" id="SingleBestBidBox"
                            class="input-group input-group-sm fleft ui-disabled" style="width: 155px;">
                            <button type="button" id="singlePriceAdd1" class="button white small">
                                <img src="../assets/images/edd.png">
                            </button>

                            <input type="number" min="0" step="0.1" name="single_price" id="single_price"
                                class="form-control singleinput" value="0">

                            <button type="button" id="singlePriceAdd2" class="button white small">
                                <img src="../assets/images/add.png">
                            </button>

                        </div>
                        <div v-else id="SingleBestBidBox" class="input-group input-group-sm fleft"
                            style="width: 155px;">
                            <button type="button" id="singlePriceAdd1" class="button white small"
                                @click="addEddPrice('E');">
                                <img src="../assets/images/edd.png">
                            </button>

                            <input v-model="dealData.price" type="number" min="0" name="single_price" id="single_price"
                                class="form-control singleinput" value="0">

                            <button type="button" id="singlePriceAdd2" class="button white small"
                                @click="addEddPrice('A');">
                                <img src="../assets/images/add.png">
                            </button>

                        </div>

                        <button type="button" id="single_nowpricebtn"
                            class="button orange small shlie fleft ml-2 single_nowpricebtn" data-aa="n67"
                            @click="getNowPrice();">現價</button>

                    </td>
                </tr>
                <tr id="boxb1" style="display: none;">
                    <td colspan="2">
                        <div class="ml30">
                            <button type="button" class="button white small btnsmsm singleset_put"
                                id="single_put1">1</button>
                            <button type="button" class="button white small btnsmsm singleset_put"
                                id="single_put2">2</button>
                            <button type="button" class="button white small btnsmsm singleset_put"
                                id="single_put3">3</button>
                            <button type="button" class="button white small btnsmsm singleset_put"
                                id="single_put4">4</button>
                            <button type="button" class="button white small btnsmsm singleset_put"
                                id="single_put5">5</button>
                            <button type="button" id="single_putset"
                                class="button white small ml-2 btnsmsm single_putset" data-aa="a18">設</button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary" data-aa="a61">口數</td>
                    <td>
                        <div class="input-group input-group-sm fleft" id="SingleLotBox" style="width: 155px;">

                            <button type="button" id="singlePutAdd1" class="button white small"
                                @click="addEddAmount('E');">
                                <img src="../assets/images/edd.png">
                            </button>

                            <input v-model="dealData.amount" type="number" min="0" name="single_put" id="single_put"
                                class="form-control singleinput" value="1">

                            <button type="button" id="singlePutAdd2" class="button white small"
                                @click="addEddAmount('A');">
                                <img src="../assets/images/add.png">
                            </button>

                        </div>

                        <button type="button" id="single_allping lockbtn"
                            class="button orange small shlie fleft ml-2 single_allping ui-disabled"
                            data-aa="n71">全平</button>
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary" data-aa="a60">保證金</td>
                    <td>

                        <div v-if="marginPrice" id="SingleBestBidBox"
                            class="input-group input-group-sm fleft ui-disabled" style="width: 155px;">
                            <button type="button" id="marginPutAdd1" class="button white small">
                                <img src="../assets/images/edd.png">
                            </button>

                            <input v-model.number="dealData.margin" type="number" min="0" name="margin_put" id="margin_put"
                                class="form-control margininput" value="1">

                            <button type="button" id="marginPutAdd2" class="button white small">
                                <img src="../assets/images/add.png">
                            </button>
                        </div>
                        <div v-else id="SingleBestBidBox" class="input-group input-group-sm fleft"
                            style="width: 155px;">
                            <button type="button" id="marginPutAdd1" class="button white small"
                                @click="addEddMargin('E');">
                                <img src="../assets/images/edd.png">
                            </button>

                            <input v-model.number="dealData.margin" type="number" min="0" name="margin_put" id="margin_put"
                                class="form-control margininput" value="1">

                            <button type="button" id="marginPutAdd2" class="button white small"
                                @click="addEddMargin('A');">
                                <img src="../assets/images/add.png">
                            </button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <button v-if="dealData.buySell== 'long'" type="button" class="button red lockbtn"
                            style="width: 100%;" id="single_btn_buy" @click="placeOrder('L');" data-aa="a62">多單</button>

                        <button v-if="dealData.buySell== 'short'" type="button" class="button green lockbtn"
                            style="width: 100%;" id="single_btn_sell" data-aa="a63"
                            @click="placeOrder('S');">空單</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    // import ModalWindow from "@vuesence/modal-window"
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'; // 20231105 | HENRY | 這邊新增Kendo確認視窗
    import { Button } from '@progress/kendo-vue-buttons';                   // 20231105 | HENRY | 這邊新增Kendo按鈕
    import { mapState } from "vuex";
    import { Encrypt, Decrypt } from '../utils/aes.js'//Ricky: AES
    import axios from 'axios';
    import Store from '../store/index.js'
    export default {
        name: "order",
        components: {
            'k-dialog': Dialog,
            'dialog-actions-bar': DialogActionsBar,
            'kbutton': Button
        },
        data: function () {
            return {
                nowSelectProductName_read: '',
                showModal: false,
                devicePixelRatio: window.devicePixelRatio,
                dealData: {
                    buySell: 'long',
                    name: '',
                    price: '',
                    amount: 0,
                    productId: '',
                    margin: 0,
                },
                longData: {
                    bstockId: '',
                    buserid: '',
                    bntw: '',
                    bentrust: '',
                    bdeal: ''
                },
                shortData: {
                    sstockId: '',
                    suserid: '',
                    sntw: '',
                    sentrust: '',
                    sdeal: ''
                },
                dealMix: {
                    productGuid: '', // 這邊放產品代號，由後端帶入guid
                    price: '', // 下單的價格，如果是市價，就是市價的價格，但不表示成交金額，需要看後台搓合的結果
                    dealAmount: '', // 交易量
                    dealType: '', // 交易方式 0市價 1限價
                    dealDict: '', // 交易方向 0多單 1空單
                    orderTime: '', // 交易時間，前端的時間給後端段，如果差太多就不給交易 UTC時間
                    stopLoss: '', // 停損點
                    takeProfit: '', // 停利點
                    margin: '', // 保證金
                },
                marketPrice: true,
                marginPrice: true,
                product_List: [],
                postit: 'coustom',
                orderCheck: false,                   // 20231105 | HENRY | 這邊新增bool變數綁定下單不確認
                showDialog: false,                   // 20231105 | HENRY | 這邊新增bool變數綁定下單不確認
                showOrderConfirmDialog: false,      // 20231105 | HENRY | 這邊控制下單確認對話框的顯示
                orderLockCheck: false,              // 20231105 | HENRY | 這邊控制鎖定商品的狀態
            }
        },
        watch: {
            // 20231105 | HENRY | 只有當 orderLockCheck 為 FALSE 時，才更新 nowSelectProductName_read
            nowSelectProductName(newVal) {
                if (!this.orderLockCheck) {
                    this.nowSelectProductName_read = newVal;
                }
            },
            allProducts() {
                this.product_List = this.allProductsName()
            },
            nowSelectProduct(value) {
                // console.log(value)
                this.selectedID = Store.state.nowSelectProduct.productCode;
            },
            // 20231105 | HENRY | 當 orderCheck 從 false 變為 true 時，顯示提示訊息
            orderCheck(newValue, oldValue) {
                if (newValue === true && oldValue === false) {
                    this.showDialog = true;
                }
            },
            nowSelectProductdetail(){
                console.log(this.nowSelectProductdetail)
                if(this.nowSelectProductdetail != null && this.marginPrice){
                    this.dealData.margin = this.nowSelectProductdetail.initialMargin;
                }
            },
            marginPrice(){
                if(this.marginPrice){
                    this.dealData.margin = this.nowSelectProductdetail.initialMargin;
                }
            }
        },
        methods: {
            Change_showModal() {
                console.log(1)
                this.showModal = !this.showModal;
            },
            loadNowSelectProduct(data) {
                //console.log(data)
                return Store.dispatch("LoadNowSelectProduct", data);
            },
            allProductsName() {
                var nameBuffer = [];
                // console.log("==>", Store.state.real_time_product)
                Store.state.real_time_product.forEach(function (e) {
                    nameBuffer.push(e.productName);
                });
                return nameBuffer;
            },
            productsName_onChange(event) {
                var vm = this;
                // console.log(event.target.value);
                Store.state.real_time_product.forEach(function (e) {
                    if (e.productName == event.target.value) {
                        // console.log(e);
                        vm.loadNowSelectProduct(e);
                        // console.log(vm.nowSelectProductName);
                    }
                });
            },
            resize(width, height) {
                let vm = this;
                // document.getElementById('mysinglebox').style.width =  width+ 'px';
                // document.getElementById('mysinglebox').style.height = height + 'px';
            },
            addEddPrice(e) {
                if (e == "A") {
                    this.dealData.price++;
                }
                if (e == "E") {
                    if (this.dealData.price <= 0) {
                        this.dealData.price = 0;
                    }
                    else {
                        this.dealData.price--;
                    }
                }
            },
            addEddAmount(e) {
                if (e == "A") {
                    this.dealData.amount++;
                }
                if (e == "E") {
                    if (this.dealData.amount <= 0) {
                        this.dealData.amount = 0;
                    }
                    else {
                        this.dealData.amount--;
                    }
                }
            },
            addEddMargin(e) {
                if (e == "A") {
                    this.dealData.margin+=100;
                }
                if (e == "E") {
                    if (this.dealData.margin <= 0) {
                        this.dealData.margin = 0;
                    }
                    else {
                        this.dealData.margin-=100;
                    }
                }
            },
            getNowPrice() {
                this.dealData.price = this.nowSelectProduct.nAsk;
            },
            sendOrder(e) {
                this.dealMix.productGuid = Encrypt(this.nowSelectProduct.productCode); // 加密 // 這邊放產品代號，由後端帶入guid
                this.dealMix.price = Encrypt(this.dealData.price.toString()); // 加密 // 下單的價格，如果是市價，就是市價的價格，但不表示成交金額，需要看後台搓合的結果
                this.dealMix.dealAmount = Encrypt(this.dealData.amount.toString()); // 加密 // 交易量
                this.dealMix.dealType = this.marketPrice ? Encrypt((0).toString()) : Encrypt((1).toString()); // 交易方式 0市價 1限價
                this.dealMix.dealDict = e == 'L' ? Encrypt((0).toString()) : Encrypt((1).toString()); // 交易方向 0多單 1空單
                const date = new Date();
                date.setHours(date.getHours() + 8);
                const utcString = date.toISOString();
                this.dealMix.orderTime = utcString; // 交易時間，前端的時間給後端段，如果差太多就不給交易 UTC時間
                this.dealMix.stopLoss = Encrypt((0).toString()); // 停損點
                this.dealMix.takeProfit = Encrypt((0).toString()); // 停利點
                this.dealMix.margin = Encrypt((this.dealData.margin).toString()); // 停利點
                console.log(this.nowSelectProduct.nAsk);
                if (this.marketPrice) {
                    this.dealMix.price = Encrypt(this.nowSelectProduct.intDealPrice.toString()); // 下單的價格，如果是市價，就是市價的價格，但不表示成交金額，需要看後台搓合的結果
                }



                console.log(e + '交易情況');

                if (this.dealData.amount == '') {
                    alert("口數不能為空")
                }
                else {
                    axios.post(this.$httpPath + '/Trade/dealmix', this.dealMix)
                        .then((response) => {
                            if (response.status === 200) {
                                console.log(response.data);
                                alert(response.data)
                            }
                        })
                }
                // else if(e=='L'){
                //     this.longData.bstockId = Encrypt(this.nowSelectProduct.productCode);// 加密
                //     this.longData.buserid = Encrypt('Pudding');// 加密 //此處無效 由後台加入
                //     this.longData.bntw = Encrypt(this.dealData.price.toString());// 加密
                //     this.longData.bentrust = Encrypt(this.dealData.amount.toString());// 加密
                //     this.longData.bdeal = Encrypt('0');// 加密
                //     axios.post(this.$httpPath+'/Trade/dealOrderBuy',this.longData)
                //     .then((response)=>{
                //         if(response.status===200)
                //         {
                //             console.log('委託完成');
                //             alert("委託完成")
                //             this.longData.bstockId = '';// 加密
                //             this.longData.buserid = '';// 加密
                //             this.longData.bntw = '';// 加密
                //             this.longData.bentrust = '';// 加密
                //             this.longData.bdeal = '';// 加密
                //         }
                //     })
                // }
                // else if(e=='S'){
                //     this.shortData.sstockId = Encrypt(this.dealData.productId);// 加密
                //     this.shortData.suserid = Encrypt('Pudding');// 加密
                //     this.shortData.sntw = Encrypt(this.dealData.price.toString());// 加密
                //     this.shortData.sentrust = Encrypt(this.dealData.amount.toString());// 加密
                //     this.shortData.sdeal = Encrypt('0');// 加密
                //     axios.post(this.$httpPath+'/Trade/dealOrderSell',this.shortData)
                //     .then((response)=>{
                //         if(response.status===200)
                //         {
                //             console.log('委託完成');
                //             alert("委託完成")
                //             this.shortData.sstockId = '';// 加密
                //             this.shortData.suserid = '';// 加密
                //             this.shortData.sntw = '';// 加密
                //             this.shortData.sentrust = '';// 加密
                //             this.shortData.sdeal = '';// 加密
                //         }
                //     })
                // }
            },
            // 20231105 | HENRY | 這邊新增綁定下單不確認Dialog
            toggleDialog() {
                this.orderCheck = true;
            },
            // 20231105 | HENRY | 這邊新增綁定下單不確認Dialog
            closeDialog() {
                this.showDialog = false;
            },
            // 20231105 | HENRY | 這邊新增綁定下單不確認Dialog
            closeOrderConfirmDialog() {
                // 關閉下單確認對話框
                this.showOrderConfirmDialog = false;
            },
            // 20231105 | HENRY | 這邊新增綁定下單不確認Dialog
            confirmOrder() {
                // 用戶點擊確定後，進行下單操作
                this.sendOrder(this.pendingOrderType);
                //console.log(this.pendingOrderType);

                // 關閉對話框
                this.showOrderConfirmDialog = false;
            },
            // 20231105 | HENRY | 這邊新增綁定下單不確認Dialog
            placeOrder(buyOrSell) {
                // 如果下單不確認沒有被勾選，顯示下單確認對話框
                if (!this.orderCheck) {
                    this.showOrderConfirmDialog = true;
                    // 暫存下單類型，用於後續確定下單
                    this.pendingOrderType = buyOrSell;
                } else {
                    // 如果已經勾選，直接下單
                    this.sendOrder(buyOrSell);
                    //console.log(buyOrSell);
                }
            },
        },
        computed: {
            // ModalWindow,
            // 注意: 這邊的ifLoading跟store的state的isLoading名字不同，是可以自定義的喔
            allProducts() {
                return Store.state.real_time_product;
            },
            nowSelectProductName() {
                return Store.state.nowSelectProduct.productName;
            },
            nowSelectProduct() {
                return Store.state.nowSelectProduct;
            },
            nowSelectProductdetail() {
                return Store.state.nowSelectProductdetail;
            },
        },
        mounted() {

        },
    };
</script>
<style>
    @import '../assets/css/order/style.css';
    @import '../assets/css/order/style_w.css';
</style>