import Stomp from 'stompjs'
import axios from 'axios';
import store from './index.js';//store import //coconut補充
import Vue from 'vue';
import Vuex from 'vuex';

// import '../assets/css/telerik/default-main-dark.css'

// var monitorin_client = null;
var user_client = null;
var OnClient = null;
var realtime_subscribe = null
var klineday_subscribe = null
var klinemin_subscribe = null
var tick_subscribe = null
var subscribe_productname = null
var monitorin_headers = null
var user_headers = null
//對外產品 訂閱顯示 //cocount補充
export function Monitorin_MQ_Initialization_Connect() {
    // monitorin_client = Stomp.client(Vue.prototype.$httpPath_ws);
    // monitorin_client.debug = null;
    // monitorin_headers = {
    //     login:"test",
    //     passcode:"000000",
    // };
    // console.log("訂閱連線初始化");
    // monitorin_client.connect(monitorin_headers, OnConnected, OnFailed);
};
export function User_MQ_Initialization_Connect(User_Name, User_Password) {
    // user_client = Stomp.client(Vue.prototype.$httpPath_ws);
    // user_client.debug = null;
    // user_headers = {
    //     login:User_Name,
    //     passcode:User_Password,
    // };
    // console.log("使用者連線初始化");
    // user_client.connect(user_headers, OnConnected, OnFailed);
    //client.connect(headers, OnConnected, OnFailed);
};
//對外連線訂閱 //coconut補充
export function Monitorin_MQ_Subscribe(productname) {
    var topic = "";
    if(subscribe_productname != productname){
        //console.log(productname);
        subscribe_productname = productname
        if(klineday_subscribe != null) {
            klineday_subscribe.unsubscribe();
            klineday_subscribe = null
        }
        
        if(klinemin_subscribe != null) {
            klinemin_subscribe.unsubscribe();
            klinemin_subscribe = null
        }
        
        if(tick_subscribe != null) {
            tick_subscribe.unsubscribe();
            tick_subscribe = null
        }
        //console.log(subscribe_productname);
        topic = "/exchange/KLineDay_Exchange/KLineDay." + subscribe_productname;
        klineday_subscribe = user_client.subscribe(topic, ResponseCallback, { id: 'klineday' },OnFailed)
        //console.log(klineday_subscribe)
        topic = "/exchange/KLineMin_Exchange/KLineMin." + subscribe_productname;
        klinemin_subscribe = user_client.subscribe(topic, ResponseCallback, { id: 'klinemin' },OnFailed)
        //console.log(klinemin_subscribe)
        topic = "/exchange/Tick_Exchange/Tick." + subscribe_productname;
        tick_subscribe = user_client.subscribe(topic, ResponseCallback, { id: 'tick' },OnFailed)
    }
};
//內部連線訂閱 //coconut補充
function OnConnected(frame) {
    console.log(123135)
    var topic = ""
    topic = "/exchange/AllStockPrice_Exchange/AllStockPrice.realtime";
    realtime_subscribe = user_client.subscribe(topic, ResponseCallback, { id: 'realtime' }, OnFailed)
    //console.log(topic);
    Monitorin_MQ_Subscribe("TX00");
};
//錯誤回報 //coconut補充
function OnFailed(frame) {
    console.log("Failed: " + frame);
};
//接收資料 //cocount補充
function ResponseCallback(frame) {
    //console.log(frame)
    var products = JSON.parse(frame.body);
    //console.log("得到的消息 msg=>" + frame.body);
    //console.log(products);

    if (frame.headers.destination.indexOf('AllStockPrice.') >= 0) {
        store.dispatch("ReloadProductsData", products);
        //User_Send();
        //console.log(products)
    }
    if (frame.headers.destination.indexOf('KLineDay.') >= 0) {
        //console.log(frame.headers.destination)
        store.dispatch("ReloadKLineDayData", products);
    }
    if (frame.headers.destination.indexOf('KLineMin.') >= 0) {
        //console.log(frame.headers.destination)
        store.dispatch("ReloadKLineMinData", products);
    }
    if (frame.headers.destination.indexOf('Tick.') >= 0) {
        //console.log(frame.headers.destination)
        store.dispatch("ReloadTickData", products);
    }
    //reloadProductsData(products);
    //console.log("products=>" + products);
};
function User_Send(){
    var example_data=[{
        "uUserName":"範例",
        "uAgent":"範例",
        "uCreditsAmount":"範例",
        "uLastBalance":"範例",
        "uStatus":"範例",
        "uCategory":"範例",
    }];
    user_client.send("/exchange/AllStockPrice_Exchange/AllStockPrice.realtime", {priority: 9}/*如果你想发送一个有消息体（body）的信息，也必须传递headers参数。如果没有headers需要传递，可以用{}来表示*/, JSON.parse(example_data) );
    console.log("我在下單測試");
}

//對外關閉網頁中連線
export function MQ_DisConnect(){			//關閉連線
    // if(monitorin_client && monitorin_client.connected) {
    //     monitorin_client.disconnect()
    // }
};
