<template>
<div>
    <button @click="sendMsg"></button>
</div>

</template>
<script>
import * as signalR from '@aspnet/signalr'
export default {
    data()
    {
        return{
            iuputMsg:'123',
            connection:''
        }
    },
    methods: {
        sendMsg:function(){
            this.connection.send("Broadcast","1111");
        },           
        connecHub:function(){
            let thisVue = this;
            this.connection = new signalR.HubConnectionBuilder()
            .withUrl("https://localhost:44344/Hubs/ChatHub", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Debug)
            .build();     
            this.connection.start().then(()=>{
                this.listenHub();
            }).catch((error)=>{
                console.log("error");
            });
        },           
        listenHub:function(){
            this.connection.on("SendMsgToClient",(result)=>{
                console.log(result);
            });
        },        
    },
    created: function() {
        this.connecHub();
    },
    beforeDestroy() {
        this.connection.stop();
    },
};
</script>