<template>
  <div>
  <div><H4 style="color:purple;">美國 FDA / Recalls, Market Withdrawals, & Safety Alerts</H4></div>
  {{nowData}}
  </div>
</template>

<script>
import Stomp from 'stompjs'
import Vue from 'vue';

export default {
  name: "TestMQ",
  data() {
      return {
        // client:Stomp.client(Vue.prototype.$httpPath_ws),
        nowData:'',
      }
    },
  created() {   
        // this.connect();           
    },
  destroyed(){
        // this.disconnect()
    },
  methods: {
    //webSocket连接成功后回调函数
    onConnected(frame) {
      console.log("準備連線: " + frame);
      const topic = "/exchange/helloExchange/hello.SayHello";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    onFailed(frame) {
      console.log("Failed: " + frame);
    },
    responseCallback(frame) {
      this.nowData = frame.body;
      console.log("得到的消息 msg=>" + frame.body);
    },
    connect() {
      const headers = {
          login:"test",
          passcode:"000000"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      console.log("連線");
    },
    disconnect(){			//關閉連線
      if(this.client && this.client.connected) {
          this.client.disconnect( () => {
              this.subscribes = {};
          })
      }
    }
  }
}
</script>