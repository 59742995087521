<template>
<td>       
    <button
        class="k-button k-grid-edit-command"
        @click="editHandler"
    >
      平倉
    </button>
    <button
        class="k-button k-grid-change-command"
        @click="changeHandler"
    >
      換倉
    </button>
 
</td>
</template>
<script>

export default {
   props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    methods: {
        editHandler: function() {
            this.$emit('closeDeal', this.dataItem);
        },
        changeHandler: function() {
            this.$emit('changeDeal', this.dataItem);
        }
    }
}
</script>
<style scoped>
.k-grid-edit-command
{
    color: white;
    background-color: #1D6A96;
}
.k-grid-change-command
{
    color: white;
    background-color: #1d9096;
}
</style>