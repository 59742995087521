<template>
    <!-- 新增最佳五檔視窗 | HENRY | 20240130 -->
    <div class="containerTest">
        <div id="containerKL"></div>
        <div class="spacer" id="containerbest5">
            <div style="width: 100%; height: 48%; margin-top: 1px">
                <table style="width: 100%;  border: 1px solid red;">
                    <tr>
                        <td style="width: 85%;">
                            <span style="color: white; margin-left: 5px; margin-right: 5px;">委買量</span>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white; margin-left: 5px; margin-right: 5px;">買價</span>
                        </td>
                    </tr>
                </table>
                <!-- 多量多價數據行 -->
                <table style="width: 100%; margin-top: 10px; margin-left: 15px;">
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noOneBuyNum" :max="bestFive.BuyMaxVolume" show-value
                                variant="danger"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noOneBuyPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noTwoBuyNum" :max="bestFive.BuyMaxVolume" show-value
                                variant="danger"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noTwoBuyPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noThreeBuyNum" :max="bestFive.BuyMaxVolume" show-value
                                variant="danger"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noThreeBuyPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noFourBuyNum" :max="bestFive.BuyMaxVolume" show-value
                                variant="danger"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noFourBuyPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noFiveBuyNum" :max="bestFive.BuyMaxVolume" show-value
                                variant="danger"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noFiveBuyPrice}}</span>
                        </td>
                    </tr>
                </table>
                <!-- 可以繼續添加更多多量多價數據行 -->
            </div>

            <!-- 空量空價區塊 -->
            <div style="width: 100%; height: 48%; margin-top: 10px;">
                <table style="width: 100%; border: 1px solid green;">
                    <tr>
                        <td style="width: 85%;">
                            <span style="color: white; margin-left: 5px; margin-right: 5px;">委賣量</span>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white; margin-left: 5px; margin-right: 5px;">賣價</span>
                        </td>
                    </tr>
                </table>
                <!-- 空量空價數據行 -->
                <table style="width: 100%; margin-top: 10px; margin-left: 15px;">
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noOneSellNum" :max="bestFive.SellMaxVolume" show-value
                                variant="success"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noOneSellPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noTwoSellNum" :max="bestFive.SellMaxVolume" show-value
                                variant="success"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noTwoSellPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noThreeSellNum" :max="bestFive.SellMaxVolume" show-value
                                variant="success"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noThreeSellPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noFourSellNum" :max="bestFive.SellMaxVolume" show-value
                                variant="success"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noFourSellPrice}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 85%;">
                            <b-progress :value="bestFive.noFiveSellNum" :max="bestFive.SellMaxVolume" show-value
                                variant="success"></b-progress>
                        </td>
                        <td style="width: 15%;">
                            <span style="color: white;">{{bestFive.noFiveSellPrice}}</span>
                        </td>
                    </tr>
                </table>
                <!-- 可以繼續添加更多空量空價數據行 -->
            </div>
        </div>
    </div>
</template>
<script src="https://code.highcharts.com/stock/highstock.js"></script>
<script src="https://code.highcharts.com/stock/modules/data.js"></script>

<script src="https://code.highcharts.com/stock/modules/drag-panes.js"></script>

<script src="https://code.highcharts.com/stock/indicators/indicators.js"></script>
<script src="https://code.highcharts.com/stock/indicators/bollinger-bands.js"></script>
<script src="https://code.highcharts.com/stock/indicators/ema.js"></script>



<script src="../assets/highcharts/code/es-modules/extensions/fullscreen.js"></script>
<script src="https://code.highcharts.com/stock/modules/price-indicator.js"></script>
<script src="https://code.highcharts.com/stock/modules/stock-tools.js"></script>
<script>

    import Vue from "vue";
    import Stomp from 'stompjs'
    import $ from 'jquery'
    import axios from "axios";
    import Highcharts from 'highcharts';
    import VueToast from 'vue-toast-notification';
    import Store from '../store/index.js'

    Vue.use(VueToast);
    export default {
        name: "HighChartPopupL",
        data: function () {
            return {
                bestFive: {
                    noOneBuyPrice: 0,
                    noTwoBuyPrice: 0,
                    noThreeBuyPrice: 0,
                    noFourBuyPrice: 0,
                    noFiveBuyPrice: 0,
                    noOneSellPrice: 0,
                    noTwoSellPrice: 0,
                    noThreeSellPrice: 0,
                    noFourSellPrice: 0,
                    noFiveSellPrice: 0,
                    noOneBuyNum: 0,
                    noTwoBuyNum: 0,
                    noThreeBuyNum: 0,
                    noFourBuyNum: 0,
                    noFiveBuyNum: 0,
                    noOneSellNum: 0,
                    noTwoSellNum: 0,
                    noThreeSellNum: 0,
                    noFourSellNum: 0,
                    noFiveSellNum: 0,
                    BuyMaxVolume: 200,
                    SellMaxVolume: 200,
                },
                //webSocketConnect
                // client:Stomp.client(Vue.prototype.$httpPath_ws),
                // nowConnectionWs:null,
                //webSocketConnect
                //klineBuffer
                allKLineMinStoreBuffer: [],
                //klineBuffer
                lastTimeStamp: 0,
                selectedField: 'selected',
                updatedData: [],
                editID: null,
                selectedID: null,
                group: [{ field: 'UnitsInStock' }],
                expandedItems: [],
                chartL: null,
                gridData: [],
                kLine_status: 0,
                startMorningSlot: new Date().setHours(8, 39, 0, 0),
                endMorningSlot: new Date().setHours(8, 44, 59, 999),
                ChartOptions:
                {
                    chart: {
                        // events: {
                        //     load: function () {

                        //         // set up the updating of the chart each second
                        //         const series = this.series[0];
                        //         setInterval(function () {
                        //             const x = (new Date()).getTime(), // current time
                        //                 y = Math.round(Math.random() * 100);
                        //             series.addPoint([x, y], true, true);
                        //         }, 1000);
                        //     }
                        // }
                    },

                    accessibility: {
                        enabled: false
                    },

                    time: {
                        useUTC: false
                    },

                    rangeSelector: {
                        buttons: [{
                            count: 5,
                            type: 'minute',
                            text: '5M'
                        }, {
                            type: 'all',
                            text: 'All'
                        }],
                        inputEnabled: false,
                        selected: 1
                    },

                    title: {
                        text: '分時線圖'
                    },

                    exporting: {
                        enabled: false
                    },

                    series: [{
                        name: '',
                    }],
                    
                    stockTools:
                    {
                        gui:
                        {
                            enabled: false
                        },
                    },
                },
            }
        },
        //webSocketConnect
        created() {
            //this.refreshAll();            
        },
        components: {
        },
        computed: {
            nowSelectProduct() {
                return Store.state.nowSelectProduct;
            },
            TimeLineOnly() {
                return Store.state.TimeLineOnly;
            },
            allBestFiveTick() {
                return Store.state.allBestFiveTick;
            },
        },
        watch: {
            TimeLineOnly() {
                let vm = this;
                const series = this.chartL.series[0];
                const title = this.chartL.title;
                if (this.kLine_status != 0) {
                    // if (this.isSameMinute(vm.lastTimeStamp, vm.TimeLineOnly['timeStamp'])) {
                    //     let lastData = series.update([vm.TimeLineOnly['timeStamp'], vm.TimeLineOnly['argIntDeal']]);
                    // }
                    // else {
                    //     series.addPoint([vm.TimeLineOnly['timeStamp'], vm.TimeLineOnly['argIntDeal']], true, true);
                    // }
                    if (!this.isSameMinute(vm.lastTimeStamp, vm.TimeLineOnly['timeStamp'])) {
                        series.addPoint([vm.TimeLineOnly['timeStamp'], vm.TimeLineOnly['argIntDeal']], true, true);
                    }
                    else{
                        var lastPointIndex = series.data.length - 1;
                        // 更新最後一個點的值
                        if(series.data.length != 0){
                            series.data[lastPointIndex].update([vm.TimeLineOnly['timeStamp'], vm.TimeLineOnly['argIntDeal']]);
                        }
                    }
                    this.chartL.setTitle({
                        text: vm.TimeLineOnly['argIntDeal']
                    })
                    
                    vm.lastTimeStamp = vm.TimeLineOnly['timeStamp'];
                }
            },
            nowSelectProduct() {
                let vm = this;
                console.log("nowSelectProduct__________", this.nowSelectProduct);
                const series = this.chartL.series[0];
                series.setData([]);
                series.name = vm.nowSelectProduct.productName;
                vm.lastTimeStamp = 0;
                vm.kLine_status = 0;
                vm.getSelectTimeLine();
                
                console.log("nowSelectProduct__________", this.kLine_status);
            },
            allBestFiveTick() {
                let vm = this;
                vm.bestFive.noOneBuyNum = Store.state.allBestFiveTick.noOneBuyNum;
                vm.bestFive.noTwoBuyNum = Store.state.allBestFiveTick.noTwoBuyNum;
                vm.bestFive.noThreeBuyNum = Store.state.allBestFiveTick.noThreeBuyNum;
                vm.bestFive.noFourBuyNum = Store.state.allBestFiveTick.noFourBuyNum;
                vm.bestFive.noFiveBuyNum = Store.state.allBestFiveTick.noFiveBuyNum;
                vm.bestFive.noOneSellNum = Store.state.allBestFiveTick.noOneSellNum;
                vm.bestFive.noTwoSellNum = Store.state.allBestFiveTick.noTwoSellNum;
                vm.bestFive.noThreeSellNum = Store.state.allBestFiveTick.noThreeSellNum;
                vm.bestFive.noFourSellNum = Store.state.allBestFiveTick.noFourSellNum;
                vm.bestFive.noFiveSellNum = Store.state.allBestFiveTick.noFiveSellNum;
                vm.bestFive.noOneBuyPrice = Store.state.allBestFiveTick.noOneBuyPrice;
                vm.bestFive.noTwoBuyPrice = Store.state.allBestFiveTick.noTwoBuyPrice;
                vm.bestFive.noThreeBuyPrice = Store.state.allBestFiveTick.noThreeBuyPrice;
                vm.bestFive.noFourBuyPrice = Store.state.allBestFiveTick.noFourBuyPrice;
                vm.bestFive.noFiveBuyPrice = Store.state.allBestFiveTick.noFiveBuyPrice;
                vm.bestFive.noOneSellPrice = Store.state.allBestFiveTick.noOneSellPrice;
                vm.bestFive.noTwoSellPrice = Store.state.allBestFiveTick.noTwoSellPrice;
                vm.bestFive.noThreeSellPrice = Store.state.allBestFiveTick.noThreeSellPrice;
                vm.bestFive.noFourSellPrice = Store.state.allBestFiveTick.noFourSellPrice;
                vm.bestFive.noFiveSellPrice = Store.state.allBestFiveTick.noFiveSellPrice;
            },
        },
        methods: {
            sysStart() {
                let vm = this;
                vm.getSelectTimeLine();
                const series = this.chartL.series[0];
                series.name = vm.nowSelectProduct.productName;
            },
            isSameMinute(timestamp1, timestamp2) {
                // 将时间戳转换为分钟，并取整数部分
                const minute1 = Math.floor(timestamp1 / 60000);
                const minute2 = Math.floor(timestamp2 / 60000);

                // 比较转换后的分钟数是否相等
                return minute1 === minute2;
            },
            getSelectTimeLine() {
                this.getProductInfo();
                axios.get(this.$httpPath + '/Line/getTimeLineHistoricalToday?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("Response OK:", response.data);
                            this.dealTimeLineHistorical(response.data);
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            dealTimeLineHistorical(fromApi_timeLineHistory) {
                let vm = this;
                const now = new Date();
                const series = this.chartL.series[0];

                if (vm.kLine_status < 1) {
                    console.log('pushData');
                    vm.chartL.series[0].removePoint(0);
                    let data = fromApi_timeLineHistory.map(function (item) {
                        return [item['timeStamp'], item['argIntDeal']];
                    });
                    series.setData(data);
                    vm.lastTimeStamp = fromApi_timeLineHistory[0]['timeStamp'];
                    this.kLine_status++;
                    data = null;
                }

                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    // console.log("!!");
                    vm.chartL.series[0].setData([]);
                }
            },
            TickData_Add_Min(frame, i) {
                console.log("======================Data_Add_Min=================")
                // console.log(frame, i)
                let vm = this;
                vm.chartL.series[0].addPoint([parseFloat(frame.timeStamp), parseFloat(frame.argIntDeal)], true, false);
            },
            resizeLK(width, height) {
                let vm = this;
                document.getElementById('containerKL').style.width = width / 3 * 2 - 10 + 'px';
                document.getElementById('containerKL').style.height = height - 40 + 'px';
                document.getElementById('containerbest5').style.width = width / 3 - 10 + 'px';
                document.getElementById('containerbest5').style.height = height - 60 + 'px';
                if (vm.chartL != null)
                    vm.chartL.reflow();
            },
            maxSize() {
                this.chartL.$parent.$parent.$parent.$parent.$parent.$parent.$parent.maxSize();
            },
            getCandleStickData() {
                // console.log("getCandleStickData()getCandleStickData()");
                let vm = this;
                vm.chartL = Highcharts.stockChart('containerKL', vm.ChartOptions);
                let height = document.body.clientHeight * 4 / 6 - 125;
                let width = document.body.clientWidth * 0.8 - 15;
                vm.resizeLK(width, height);
                height = null;
                width = null;
            },
            getProductInfo(){
                axios.get(this.$httpPath + '/Trade/getProductInfo/code/'+this.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data)
                            Store.dispatch("LoadNowSelectProductDetail", response.data);
                        }
                    })
                    .catch((error) => {
                        
                    })
            },
        },
        mounted() {
            let vm = this;
            vm.getCandleStickData();
            axios.get(this.$httpPath + '/AgentManager/checkVip')
                .then((response) => {
                    if (response.status === 200) {
                        console.log('登入確認');
                        vm.sysStart();
                    }
                }
                )
        },
    };
    function addPopupEvents(chart) {

    }
</script>
<style>
    /* 新增最佳五檔視窗 | HENRY | 20240130 */
    .containerTest {
        display: flex;
    }

    .spacer {
        width: 400px;
        background-color: #181818;
    }

    .highcharts-background,
    .highcharts-root,
    .highcharts-container {
        max-height: 100%;
        max-width: 100%;
        padding-left: 10px;
    }

    .highcharts-menu-wrapper {
        height: 100 !important;
        z-index: 0 !important;
    }

    .highcharts-scrollbar-arrow {
        width: 25px;
        height: 14px !important;
    }

    .highcharts-scrollbar-thumb {
        height: 14px !important;
        display: block;
    }

    .highcharts-scrollbar {
        height: 14px !important;
        display: block;
    }

    .highcharts-background .highcharts-root .highcharts-container {
        max-height: 100%;
        max-width: 100%;
    }

    /* GENERAL */
    .container-fluid {
        height: 100% !important;
        width: 100% !important;
        overflow: auto;
    }

    .highcharts-draw-mode {
        cursor: crosshair;
    }

    .highcharts-background,
    .highcharts-root,
    .highcharts-container {
        max-height: 100%;
        max-width: 100%;
    }

    .highcharts-stocktools-wrapper,
    .highcharts-bindings-wrapper,
    .highcharts-bindings-container {
        height: 0px !important;
    }


    /* GUI */

    .highcharts-stocktools-wrapper {
        display: block;
    }

    .highcharts-stocktools-toolbar {
        margin: 0 0 0 10px;
        padding: 0;
        width: calc(100% - 63px);
    }

    .highcharts-stocktools-toolbar li {
        background-color: #f7f7f7;
        background-repeat: no-repeat;
        cursor: pointer;
        float: left;
        height: 40px;
        list-style: none;
        margin-right: 2px;
        margin-bottom: 3px;
        padding: 0;
        position: relative;
        width: auto;
    }

    .highcharts-stocktools-toolbar li ul {
        display: none;
        left: 0;
        padding-left: 0;
        position: absolute;
        z-index: 125;
    }

    .highcharts-stocktools-toolbar li ul li {
        margin-bottom: 0;
        width: 160px;
    }

    .highcharts-stocktools-toolbar li:hover {
        background-color: #e6ebf5;
    }

    .highcharts-stocktools-toolbar li:hover ul {
        display: block;
    }

    .highcharts-stocktools-toolbar li>span.highcharts-menu-item-btn {
        background-repeat: no-repeat;
        background-position: 50% 50%;
        display: block;
        float: left;
        height: 100%;
        width: 25px;
    }

    .highcharts-stocktools-toolbar li>.highcharts-menu-item-title {
        color: #666;
        line-height: 40px;
        font-size: 0.876em;
        padding: 0 10px 0 5px;
    }

    .highcharts-indicators>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/indicators.svg");
    }

    .highcharts-label-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/label.svg");
    }

    .highcharts-circle-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/circle.svg");
    }

    .highcharts-rectangle-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/rectangle.svg");
    }

    .highcharts-ellipse-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/ellipse.svg");
    }

    .highcharts-segment>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/segment.svg");
    }

    .highcharts-arrow-segment>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-segment.svg");
    }

    .highcharts-ray>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/ray.svg");
    }

    .highcharts-arrow-ray>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-ray.svg");
    }

    .highcharts-infinity-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/line.svg");
    }

    .highcharts-arrow-infinity-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-line.svg");
    }

    .highcharts-horizontal-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/horizontal-line.svg");
    }

    .highcharts-vertical-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-line.svg");
    }

    .highcharts-elliott3>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/elliott-3.svg");
    }

    .highcharts-elliott5>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/elliott-5.svg");
    }

    .highcharts-crooked3>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/crooked-3.svg");
    }

    .highcharts-crooked5>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/crooked-5.svg");
    }

    .highcharts-measure-xy>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-xy.svg");
    }

    .highcharts-measure-x>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-x.svg");
    }

    .highcharts-measure-y>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-y.svg");
    }

    .highcharts-fibonacci>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/fibonacci.svg");
    }

    .highcharts-pitchfork>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/pitchfork.svg");
    }

    .highcharts-parallel-channel>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/parallel-channel.svg");
    }

    .highcharts-toggle-annotations>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/annotations-visible.svg");
    }

    .highcharts-vertical-counter>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-counter.svg");
    }

    .highcharts-vertical-label>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-label.svg");
    }

    .highcharts-vertical-arrow>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-arrow.svg");
    }

    .highcharts-vertical-double-arrow>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-double-arrow.svg");
    }

    .highcharts-flag-circlepin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-elipse.svg");
    }

    .highcharts-flag-diamondpin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-diamond.svg");
    }

    .highcharts-flag-squarepin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-trapeze.svg");
    }

    .highcharts-flag-simplepin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-basic.svg");
    }

    .highcharts-zoom-xy>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-xy.svg");
    }

    .highcharts-custom-zoom {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/fullscreen.svg");
    }

    .highcharts-zoom-x>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-x.svg");
    }

    .highcharts-zoom-y>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-y.svg");
    }

    .highcharts-full-screen>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/fullscreen.svg");
    }

    .highcharts-series-type-ohlc>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/series-ohlc.svg");
    }

    .highcharts-series-type-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/series-line.svg");
    }

    .highcharts-series-type-candlestick>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/series-candlestick.svg");
    }

    .highcharts-current-price-indicator>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/current-price-show.svg");
    }

    .highcharts-save-chart>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/save-chart.svg");
    }

    li.highcharts-active {
        background-color: #e6ebf5;
    }

    /* Popup */


    /* Responsive design */

    @media screen and (max-width: 1024px) {
        .highcharts-stocktools-toolbar li>.highcharts-menu-item-title {
            display: none;
        }

        .highcharts-stocktools-toolbar li ul li {
            width: auto;
        }
    }

    @import '../assets/highcharts/code/css/stocktools/gui.css';
    @import '../assets/highcharts/code/css/annotations/popup.css';
</style>