import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '@/router'
import {Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, Monitorin_MQ_Subscribe, MQ_DisConnect} from '@/store/RabbitMQ.js';
import {Start_SignalR_Connect} from '@/store/SignalRHub.js'

Vue.use(Vuex);

export default new Vuex.Store({
  //data
  state: { 
    userBalanceInfos:"",
    UpdateByApi: "",
    client_hub_register_info:{
      signalr_connection_id: "",
    },
    is_lost_connection: false,
    refresh_token: "",
    access_token: "",
    loggedInUser: {},
    isAuthenticated: false,
    selectedCatImg:"",
    My3DModelGuid:"111",
    My3DToolTipDatas:[],
    My3DModelData:{},
    allProducts: ["NoData"],
    allKLineDay: ["NoData"],
    allKLineMin: ["NoData"],
    historyTick: '',
    allTick: '',
    TimeLineOnly: '',
    timeLineHistory:'',
    TimeLineBuffer:'',
    kLineMinOnly: '',
    kLineMinHistory:'',
    kLineFiveMinOnly: '',
    kLineFiveMinHistory:'',
    kLineFifteenMinOnly: '',
    kLineThirtyMinOnly: '',
    kLineOneDayOnly: '',
    kLineOneWeekOnly: '',
    kLineOneMonthOnly: '',
    allBestFiveTick:[],
    nowSelectProductdetail:{},
    nowSelectProduct:{
        productCode:'TXF1',
        productName:'台指近',
        bstrStock:'',
        nBid:'',
        nAsk:'',
        nClose:'',
        nTQty:'',
        nYQty:'',
        nRef:'',
        nOpen:'',
        nHigh:'',
        nLow:'',
        sDecimal:'',
        nDown:'',
        nUp:'',
        nFutureOI:'',
        nTAc:'',
        nTBc:'',
        nAc:'',
        nBc:'',
        nTradingDay:'',
        Amplitude:'',
        nAmplitude:'',
        nTickQty:'',
        bstrMarketNo:'',
        sTypeNo:'',
    },
    real_time_product:[],
    isConnect:0,
  },
  //改變資料內容的方法
  //改變state
  mutations: { 
    reloadProductsData(state, payload) {
      state.allProducts = payload;
      //console.log(state.allProducts)
    },
    reloadKLineDayData(state, payload) {
      state.allKLineDay = payload;
      //console.log(state.allKLineDay)
    },
    reloadKLineMinData(state, payload) {
      state.allKLineMin = payload;
      //console.log(state.allKLineMin)
    },
    reloadonTimeLineData(state, payload) {
      state.onTimeLine = payload;
      //console.log(state.onTimeLine)
    },
    reloadTickData(state, payload) {
      state.allTick = payload;
      //console.log(state.allTick)
    },
    loadNowSelectProduct(state, payload) {
      state.nowSelectProduct = payload;
    },
    LoadNowSelectProductDetail(state, payload) {
      state.nowSelectProductdetail = payload;
    },
    setRefreshToken: function(state, refreshToken) {
      state.refresh_token = refreshToken;
    },
    setAccessToken: function(state, accessToken) {
      state.access_token = accessToken;
      localStorage.setItem("isAuthenticated",true);
    },
    // sets state with user information and toggles 
    // isAuthenticated from false to true
    setLoggedInUser: function(state, user) {
      state.loggedInUser = user;
      state.isAuthenticated = true;
    },
    filter_real_time_product: function(state, product) {
      state.real_time_product = product;
    },

    // delete all auth and user information from the state
    clearUserData: function(state) {
      state.refresh_token = "";
      state.access_token = "";
      state.loggedInUser = {};
      state.isAuthenticated = false;
      localStorage.setItem("access_token","");
    },  
    setCT3DModel: function(state,modelData) {
      state.My3DModelData = modelData;
    },    
    setCT3DModelToopTip: function(state,modelToolTip) {
      state.My3DToolTipDatas = modelToolTip;
    },
    addCT3DModelToopTip: function(state,modelToolTip) {
      state.My3DToolTipDatas.push(modelToolTip);
    },      
    setCT3DModelGuid: function(state,modelGuid) {
      state.My3DModelGuid = modelGuid;
    },   
    updateTooltip: function(state,tooltipdata) {
      state.My3DToolTipDatas.forEach(function(ToolTipData){
        if(ToolTipData.guid==tooltipdata.guid)
        {
          ToolTipData.title = tooltipdata.title;
          ToolTipData.description = tooltipdata.description;
          return;
        }
      });
    },        
  },
  //methods
  //用非同步取得遠端資料
  //用dispatch呼叫actions
  //action用commit呼叫mutations
  actions: {   
    ReloadProductsData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadProductsData', status)
    },
    ReloadKLineDayData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadKLineDayData', status)
    },
    ReloadKLineMinData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadKLineMinData', status)
    },
    ReloadonTimeLineData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadonTimeLineData', status)
    },
    ReloadTickData: async ({ commit }, status)=>{
      //console.log(status)
      commit('reloadTickData', status)
    },
    LoadNowSelectProduct: async ({ commit }, status)=>{
      //console.log(status)
      commit('loadNowSelectProduct', status)
    },
    LoadNowSelectProductDetail: async ({ commit }, status)=>{
      //console.log(status)
      commit('LoadNowSelectProductDetail', status)
    },
    SetCT3DModelGuid: async ({ commit }, modelGuid) => {
        commit("setCT3DModelGuid", modelGuid);
    },    
    UpdateTooltip: async ({ commit}, tooltipdata) => {
      commit("updateTooltip", tooltipdata);
    },     
    AddCT3DModelToopTip: async ({ commit }, myToolTipdata) => {
      let self = this
      const url = Vue.prototype.$httpPath+'/CT3DModelToolTip/AddCT3DModelToolTip';
      try {
        await axios.post(url,myToolTipdata).then(response => {
          if (response.status === 200) {
            //this.Get3DToolTipDatas();
            //commit("addCT3DModelToopTip", myToolTipdata);
          }
          else
          {
            console.log("response.status=",response.status);
          }
        });
      } catch (e) {
        console.log(e);
      }           
      
    },      
    Get3DToolTipDatas: async ({ commit,getters},) => {
      let self = this
      const modelUrl = Vue.prototype.$httpPath+'/CT3DModelToolTip/GetCT3DModelToolTip?guid='+getters.My3DModelGuid;
      try {
        await axios.get(modelUrl).then(response => {
          if (response.status === 200) {
            commit("setCT3DModelToopTip", response.data);
          }
          else
          {
            console.log("response.status=",response.status);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },    
    GetCT3DModel: async ({ commit,getters}, modelGuid) => {
      let self = this
      const modelUrl = Vue.prototype.$httpPath+'/CT3DModel/GetCT3DModel?guid='+getters.My3DModelGuid;
      try {
        await axios.get(modelUrl).then(response => {
          if (response.status === 200) {
            commit("setCT3DModel", response.data);
          }
          else
          {
            console.log("response.status=",response.status);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },   
    logIn: async ({ commit, dispatch }, {email,password}) => {
      let self = this
      console.log(email)
      console.log(password)
      const loginUrl = Vue.prototype.$httpPath+'/AuthManagement/Login';
      try {
        await axios.post(loginUrl, {email,password}).then(response => {
          if (response.status === 200) {
            localStorage.setItem("access_token",response.data.token);
            localStorage.setItem("refreshToken",response.data.refreshToken);
            router.go(0);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    UserClick_RefreshToken: async ({ state, commit }) => {
      const refreshUrl = Vue.prototype.$httpPath+'/AuthManagement/UserClick_RefreshToken';
      let refresh_token = localStorage.getItem("refreshToken");
      refresh_token = refresh_token == "null" ? null : refresh_token;
      let token = localStorage.getItem("access_token");
      token = token == "null" ? null : token;
      try {
        await axios
          .post(refreshUrl, { Token: token, RefreshToken: refresh_token })
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem("access_token",response.data.token);
              localStorage.setItem("refreshToken",response.data.refreshToken);
            }
            else{
              router.go(0);
            }
          });
      } catch (e) {
        console.log(e.response);
        router.go(0);
      }
    },
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = Vue.prototype.$httpPath+'/AuthManagement/RefreshToken';
      const refresh_token = localStorage.getItem("refreshToken");
      const token = localStorage.getItem("access_token");
      try {
        await axios
          .post(refreshUrl, { Token: token, RefreshToken: refresh_token })
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem("access_token",response.data.token);
              localStorage.setItem("refreshToken",response.data.refreshToken);
            }
          });
      } catch (e) {
        console.log(e.response);
      }
    },
    fetchUser: async ({ commit }) => {
      const currentUserUrl = "v1/auth/users/me/";
      try {
        await axios.get(currentUserUrl).then(response => {
          if (response.status === 200) {
            commit("setLoggedInUser", response.data);
            console.log(response.data)
            Start_SignalR_Connect();
          }
        });
      } catch (e) {
        console.log(e.response);
      }
    },   
    logout: async ({ commit, dispatch }) => {
      commit("clearUserData");
      router.push("/login");
    },    

    Filter_Real_Time_Product: async ({ commit}, product) => {
      //console.log(product)
      commit("filter_real_time_product", product);
    },     
  },
  getters: { //compute
    loggedInUser: state => state.loggedInUser,
    isAuthenticated: state => state.isAuthenticated,
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token, 
    My3DModelData: state => state.My3DModelData, 
    My3DToolTipDatas: state => state.My3DToolTipDatas,
    My3DModelGuid: state => state.My3DModelGuid,
    real_time_product: state => {
      return state.real_time_product;
    },
  }
});
