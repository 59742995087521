<template>
<div>
    <button @click="callByApi"></button>
</div>

</template>
<script>
import axios from "axios";
import * as signalR from '@aspnet/signalr'
import Vue from "vue";
export default {
    data()
    {
        return{
        }
    },
    methods: {
        callByApi:function()
        {
            const url = Vue.prototype.$httpPath+'/MySignalR/GetMessage?msg=11111111111111';
            axios.get(url)
        },   
        connecHub:function(){
            let thisVue = this;
            this.connection = new signalR.HubConnectionBuilder()
            .withUrl("https://localhost:44344/Hubs/ChatHub", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Debug)
            .build();     
            this.connection.start().then(()=>{
                this.listenHub();
            }).catch((error)=>{
                console.log(error);
            });
        },    
        listenHub:function(){
            this.connection.on("SendMsgToClient",(result)=>{
                console.log(result);
            });
        },                     
    },
    created: function() {
        this.connecHub();
    },
    beforeDestroy() {
        this.connection.stop();
    },
};
</script>