<template>
    <div id="bottombar" class="bottombar">
        <div id="barstatc" style="color: #ccc">正常</div>
        <div class="bline"></div>
        <div id="barnotic" style="max-width: 1200px;">
            <div id="BulletinID" class="slideText colbottomr"></div>
        </div>
        <div style="float:right">
            <div class="bline "></div>
            <div id="barsymbol" ref="barsymbol" style="color: #ccc">{{defaultproductName}}</div>
            <div class="bline "></div>
            <div><span data-aa="a56" style="color: #ccc">最後交易日</span>:<span id="barldt"
                    style="color: #ccc">{{endDate}}</span></div>
            <div class="bline "></div>
            <div><span data-aa="a54" style="color: #ccc">禁新</span>:<span id="barlimit"
                    style="color: #ccc">{{stopNew}}</span></div>
            <div class="bline "></div>
            <div><span data-aa="a55" style="color: #ccc">強平</span>:<span id="barping"
                    style="color: #ccc">==========</span></div>
            <div class="bline "></div>
            <div v-if="isConnected">
                <div id="myprogressbarword" style="margin-right:0px;color: #ccc" data-aa="rr2">連線</div>
                <div id="myprogressbar" class="botright progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"></div>
            </div>
            <div v-else>
                <div id="myprogressbarword" style="margin-right:0px;color: #ccc" data-aa="rr2">斷線</div>
                <div id="myprogressbar" class="botright progress-bar bg-danger" role="progressbar"></div>
            </div>
            <!-- progress-bar-animated-->
        </div>

    </div>
</template>
<script>

    import axios from 'axios';
    import Store from '../store/index.js'

    export default {
        name: "bottombar",
        data: function () {
            return {
                defaultproductName: Store.state.nowSelectProduct.productName,
                endDate:'',
                stopNew:'',
                isConnected: true,
            }
        },
        components: {

        },
        watch: {
            // 20231112 | HENRY | 新增下面的產品，也要跟著選擇到的產品做名稱更改
            nowSelectProductName(newVal) {
                this.defaultproductName = newVal;
            },
            nowSelectProductdetail(){
                console.log(this.nowSelectProductdetail)
                if(this.nowSelectProductdetail != null){
                    this.endDate = this.nowSelectProductdetail.endDate == null ? "==========" : this.nowSelectProductdetail.endDate.split('T')[0];
                    this.stopNew = this.nowSelectProductdetail.endDate == null ? "==========" : this.nowSelectProductdetail.endDate.split('T')[1].split('.')[0];
                }
            },
        },
        mounted() {
            // 每秒钟更改 isConnected 的值
            setInterval(() => {
                if(Store.state.isConnect < 5){
                    Store.state.isConnect++;
                    this.isConnected = true;
                    Store.state.is_lost_connection = false;
                }
                else{
                    this.isConnected = false;
                    Store.state.is_lost_connection = true;
                }
            }, 1000);
        },
        methods: {
        },
        computed: {
            nowSelectProductName() {
                return Store.state.nowSelectProduct.productName;
            },
            nowSelectProductdetail() {
                return Store.state.nowSelectProductdetail;
            },
        },
    };
</script>
<style>
    .bottombar {
        height: 25px;
        width: 100%;
        padding: 0 5px;
        line-height: 25px;
        background-color: #000;
        z-index: 999999 !important;
    }

    .bottombar .bline {
        margin-top: 3px;
        height: 19px;
        border-left: 1px solid #2a2a2a;
    }

    .bottombar .botright {
        width: 130px;
        height: 15px;
        margin-top: 5px;
        font-size: 12px;
    }
</style>