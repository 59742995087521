<template>
  <div ref="mydivref">
  <kendo-grid id="mygrid" ref="mygridref" :data-source="localDataSource">
        <kendo-grid-column :field="'prroductID'" :title="'prroductID'" :width="120" :hidden="true"></kendo-grid-column>
        <kendo-grid-column :field="'商品'" :title="'商品'" :width="120" :locked="true"></kendo-grid-column>
        <kendo-grid-column :field="'倉位多'" :title="'倉位多'" :width="120"></kendo-grid-column>
        <kendo-grid-column :field="'倉位空'" :title="'倉位空'" :width="120"></kendo-grid-column>
        <kendo-grid-column :field="'買進價'" :title="'買進價'"  :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'賣出價'" :title="'賣出價'" ></kendo-grid-column>
        <kendo-grid-column :field="'成交價'" :title="'成交價'"  :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'漲跌'" :title="'漲跌'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'漲幅百分比'" :title="'漲幅百分比'"  :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'單量'" :title="'單量'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'總量'" :title="'總量'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'昨收價'" :title="'昨收價'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'開盤價'" :title="'開盤價'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'最高價'" :title="'最高價'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'最低價'" :title="'最低價'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'時間'" :title="'時間'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'交易'" :title="'交易'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'最後成交價'" :title="'最後成交價'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'最後交易日'" :title="'最後交易日'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'說明'" :title="'說明'" :width="150"></kendo-grid-column>
        <kendo-grid-column :field="'商品類別'" :title="'商品類別'" :width="150"></kendo-grid-column>       
    </kendo-grid>
    </div>
</template>
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import $ from 'jquery'
export default {
    components: {
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn
    },
    data () {
            return {
                mygrid:null,
                localDataSource: {
                    data: [{
                "商品":"台指期1",
                "倉位多":"",
                "倉位空":"",
                "買進價":17623,
                "賣出價":17623,
                "成交價":17623,
                "漲跌":36.0000,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期2",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期3",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期4",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期5",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期6",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期7",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期8",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期9",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期10",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期11",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期12",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期13",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期14",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":15,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期15",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期16",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-36,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期17",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-15,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            },{
                "商品":"台指期18",
                "倉位多":"",
                "倉位空":"",
                "買進價":17622,
                "賣出價":17622,
                "成交價":17622,
                "漲跌":-10,
                "漲幅百分比":"0.21%",
                "單量":1,
                "總量":52371,
                "昨收價":17659,
                "開盤價":17604,
                "最高價":17698,
                "最低價":17593,
                "時間":"11:24:32",
                "交易":"正常",
                "最後成交價":17659,
                "最後交易日":12/14,
                "說明":"說明",
                "商品類別":"CFD",
            }]
            }
        }
    },
    methods:{
        resizeLT:function(width,height) {
        //if(this.mygrid!=null)
        {
             this.$refs.mydivref.style.width=1000+"px";
             this.$refs.mydivref.style.height=height-200+"px";

             this.$refs.mygridref.width=1000+"px";
             this.$refs.mygridref.height=height-100+"px";
             console.log("resizeLT");
            //$('#mygrid').style.width =  width+ 'px';
            //$('#mygrid').style.height =  height+ 'px';       
        }
     },        
    },
    mounted(){
    }
}
</script>