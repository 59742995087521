<template>
  <div>
    <div class="root">

      <Dialog v-if="is_lost_connection" style="z-index:99;" :width="'375px'" :height="'auto'" :closeIcon="false">
        <div style="display:flex; justify-content:center; margin:10px 0px; padding:5px 10px;">
          <span style="font-size:30px;">
            連線異常
          </span>
        </div>
        網路連線不穩定，正在連線重新連線
        <DialogActionsBar>
          <div style="display:flex; justify-content:center; margin-bottom:0px">
            <Button :fill-mode="'link'" style="color:#007bff" :rounded="'medium'" v-text="'軟體使用條款'"></Button>
          </div>
        </DialogActionsBar>
      </Dialog>

      <Dialog v-if="login_visibleDialog" style="z-index:100;" :width="'375px'" :height="'auto'" :closeIcon="false"
        @close="Login_ToggleDialog">
        <div style="display:flex; justify-content:center; margin:10px 0px; padding:5px 10px;">
          <span style="font-size:30px;">
            創兆
          </span>
        </div>
        <div style="display:flex; justify-content:center;margin:10px 20px;">
          <Input style="font-size:16px; border-radius:4px; border:1px solid #ced4da;" :icon-name="'k-icon k-i-user'"
            :style="{ width: '100%', height:'33px'}" :placeholder="'帳號'" v-model="logindata.email" />
        </div>
        <div style="display:flex; justify-content:center; margin:10px 20px;">
          <Input style="font-size:16px; border-radius:4px; border:1px solid #ced4da;"
            v-bind:type="showPassword ? 'text' : 'password'" :style="{ width: '100%', height:'33px'}"
            :placeholder="'密碼'" v-model="logindata.password" />
        </div>
        <div style="display:flex; justify-content:center; margin:10px 20px;">
          <Button :fill-mode="'link'" @click="Login_Click"
            style="background-color:#007bff; color:#FFFF;height:48px; width:100%;" :rounded="'medium'" v-text="'登入'"
            :disabled="isLoginButtonDisabled"></Button>
        </div>
        <input type="checkbox" v-model="showPassword" style="fontSize:20;  margin-left:20px;"> 顯示密碼 <br>
        <div style="text-align: center;"> <!-- Container for the content -->
          <div v-if="loginStatus == 5" id="loading">
            載入中，請稍候
          </div>
          <div v-if="loginStatus == 1" class="spinner-border text-secondary" role="status"
            style="display: block; margin: auto;">
            <span class="sr-only">Loading...</span>
          </div>
          <div v-if="loginStatus == 2" id="success">
            登入成功，請稍候
          </div>
          <div v-if="loginStatus == 3" id="fail">
            帳號或密碼錯誤 {{countFailTime}} 次
          </div>
        </div>
        <div style="display:flex; justify-content:flex-end; margin:10px 20px;">
          <div
            style="display:flex; background-color:#9fcdff; align-items:center; justify-content:center; font-weight:bold; font-size:13px; border-radius:4px; color:black; border:1px solid #ced4da;"
            :style="{ width: '62px', height:'20px'}" v-text="'版本:測試'" />
        </div>
        <DialogActionsBar>
          <div style="display:flex; justify-content:center; margin-bottom:0px">
            <Button :fill-mode="'link'" style="color:#007bff" :rounded="'medium'" v-text="'軟體使用條款'"></Button>
          </div>
        </DialogActionsBar>
      </Dialog>
      <div>
        <!-- 
  +---------------------------------------------------------------------------+-----------------------------------+
  |                  ^                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |       $("#LT_Pane")[0].style.height                                       |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |             dv-lt                                      |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  |                                                        |                                   |
  |                  V                                                        |                                   |
  +------------------------------------+ -------------------------------------+                                   |
  |                                    |                                      |                                   |
  |                                    |                                      |                                   |
  |--$("#LBL_Pane")[0].style.width --- | --$("#LBR_Pane")[0].style.width------|        dv-r                       |
  |                                    |                                      |                                   |
  |          dv-lbl                    |          dv-lbr                      | -- $("#R_Pane")[0].style.width ---|
  |                                    |                                      |                                   |
  |                                    |                                      |                                   |
  +------------------------------------+--------------------------------------+-----------------------------------+
      !-->
        <Splitpanes ref="mySplitpanes" @resize="resize('resizeW',$event)" vertical style="height:500px">
          <Pane id="L_Pane" :size="75" min-size="40">
            <Splitpanes @resize="resize('resizeH',$event)" class="default-theme" horizontal="horizontal">
              <Pane id="LT_Pane">
                <div style="height: 25px;">
                  <menueBox></menueBox>
                </div>
                <div style="height: calc(100% - 25px); overflow: hidden;">
                  <mainTop id="dv-lt" class="dv-t" ref="leftLT"></mainTop>
                </div>
              </Pane>
              <Pane id="LB_Pane">
                <Splitpanes vertical>
                  <Pane id="LBL_Pane" size="100" style="z-index:0 !important">
                    <mainDown id="dv-lta" class="dv-ta" ref="leftLD"></mainDown>
                  </Pane>
                  <!-- <Pane id="LBR_Pane" size="50" style="z-index:0 !important">
                        <div id="dv-lbr" class="dv-lbr">
                          <HighChartPopupR ref="leftRK"></HighChartPopupR>
                        </div>   
                    </Pane>                              -->
                </Splitpanes>
              </Pane>
            </Splitpanes>
          </Pane>
          <Pane id="R_Pane" :size="25" min-size="25">
            <Splitpanes @resize="resize('resizeRT',$event)" horizontal>
              <Pane id="RT_Pane" :size="32">
                <div id="dv-rt">
                  <realTimePrice ref="leftRT"></realTimePrice>
                </div>
              </Pane>
              <Pane id="RM_Pane" :size="28">
                <div id="myvalue" style="height: 98%; margin-top: 5px; position: relative; overflow: auto;">
                  <div id="myvalue1" style="display: none;">
                    <div class="fleft" style="padding-left:10px;">
                      <div class="myvaluename myid"><span>ta4</span> <span class="text-danger"
                          style="font-size:12px;font-weight:bold;">測試<span></span></span></div>
                      <div>
                        <span class="mytesbx" data-aa="a14">可用餘額</span>
                      </div>
                    </div>
                    <div class="fleft">
                      <span class="myvvale" id="myaCash0"
                        style="margin-left: 10px; color: rgb(220, 53, 69);">157,000</span>
                    </div>

                  </div>
                  <div id="myvalue2" style="height: 98%;">
                    <div style="padding-left:10px;">
                      <div class="myvaluename myid"><span id="myName">ta4</span> <span class="text-danger"
                          style="font-size:12px;font-weight:bold;">測試<span></span></span></div>
                    </div>
                    <div>
                      <ul class="myvaluecashPop">
                        <li>
                          <div class="myaaadlab" data-aa="a12">昨日權益數</div>
                          <div id="yCash" class="mycash " style="color: rgb(220, 53, 69);">連線中...</div>
                        </li>
                        <li>
                          <div class="myaaadlab" data-aa="a13">今日損益</div>
                          <div id="tCash" class="mycash " style="color: rgb(204, 204, 204);">連線中...</div>
                        </li>
                        <li>
                          <div class="myaaadlab" data-aa="a14">可用餘額</div>
                          <div id="aCash" class="mycash " style="color: rgb(220, 53, 69);">連線中...</div>
                        </li>
                        <li>
                          <div class="myaaadlab" data-aa="a15">強平額度</div>
                          <div id="pCash" class="mycash" style="color: rgb(40, 167, 69);">連線中...</div>
                        </li>
                        <li>
                          <div class="myaaadlab" data-aa="a16">留倉保證金</div>
                          <div id="eCash" class="mycash" style="color: rgb(204, 204, 204);">連線中...</div>
                        </li>
                        <li>
                          <div class="myaaadlab" data-aa="a17">總權益數</div>
                          <div id="aCash0" class="mycash" style="color: rgb(220, 53, 69);">連線中...</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Pane>
              <Pane id="P_rightB" padding-bottom=0 size="45">
                <div id="dv-rb" style="height: 100%;">
                  <order ref="rightB"></order>
                </div>
              </Pane>
            </Splitpanes>
          </Pane>
        </Splitpanes>
      </div>
    </div>
    <div class="footer">
      <bottombar style="z-index:2;"></bottombar>
    </div>
  </div>
</template>
<script scoped src="../assets/js/base64.js"></script>
<script scoped src="../assets/js/basic.js"></script>
<script scoped src="../assets/js/bootstrap.bundle.min.js"></script>
<script scoped src="../assets/js/bootstrap-datetimepicker.min.js"></script>
<script scoped src="../assets/js/color-picker.min.js"></script>
<script scoped src="../assets/js/gunzip.min.js"></script>
<script scoped src="../assets/js/jQuery.fastClick.js"></script>
<script scoped src="../assets/js/jquery.nicescroll.js"></script>
<script scoped src="../assets/js/jquery.translate.js"></script>
<script scoped src="../assets/js/jquery-3.0.0.min.js"></script>
<script scoped src="../assets/js/jsbn.js"></script>
<script scoped src="../assets/js/kChart.js"></script>
<script scoped src="../assets/js/kkk.js"></script>
<script scoped src="../assets/js/loadBar.js"></script>
<script scoped src="../assets/js/main.js"></script>
<script scoped src="../assets/js/modernizr-custom.js"></script>
<script scoped src="../assets/js/mTW.js"></script>
<script scoped src="../assets/js/prng4.js"></script>
<script scoped src="../assets/js/rng.js"></script>
<script scoped src="../assets/js/rsa.js"></script>
<script scoped src="../assets/js/splitter.js"></script>
<script scoped src="../assets/js/tinyscrollbar.js"></script>
<script scoped src="../assets/js/top.js"></script>
<script scoped src="../assets/js/tread.js"></script>
<script scoped src="../assets/js/value.js"></script>
<style scoped src="../assets/css/bootstrap.min.css"></style>
<style scoped src="../assets/css/bootstrap-datetimepicker.min.css"></style>
<style scoped src="../assets/css/color-picker.min.css"></style>
<style scoped src="../assets/css/style.css"></style>
<style scoped src="../assets/css/color-picker.min.css"></style>


<script>
  // import ModalWindow from "@vuesence/modal-window"
  import { Splitpanes, Pane } from '@/components/splitpanes/index';
  import menueBox from '@/components/menueBox';
  import mainTop from '@/components/mainTop';
  import mainDown from '@/components/mainDown';
  import mainBottom from '@/components/mainBottom';
  import mainRight from '@/components/mainRight';
  import k_menubox from '@/components/k_menubox';
  import k_newpricebox from '@/components/k_newpricebox';
  import bottombar from '@/components/bottombar';
  import KLeft from '@/components/KLeft';
  import HighChartPopupL from '@/components/HighChartPopupL';
  import HighChartPopupL_KLine from '@/components/HighChartPopupL_KLine';
  import order from '@/components/order';
  import realTimePrice from '@/components/realTimePrice';
  import $ from 'jquery'
  import Order from './order.vue';
  import Stomp from 'stompjs'
  import Store from '../store/index.js'
  import { Window } from '@progress/kendo-vue-dialogs';
  import { Button } from '@progress/kendo-vue-buttons';
  import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';

  import { Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, Monitorin_MQ_Subscribe, MQ_DisConnect } from '@/store/RabbitMQ.js';
  import { Start_SignalR_Connect } from '@/store/SignalRHub.js'


  import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
  import { Encrypt, Decrypt } from '@/utils/aes.js'//Ricky: AES
  import axios from 'axios';

  // import Top from '@/components/Top.vue'
  // import Left from '@/components/Left.vue'
  // import Footer from '@/components/Footer.vue'
  // import Right from '@/components/Right.vue'
  /* eslint-disable no-debugger, no-console */
  export default {
    components: {
      Dialog,
      DialogActionsBar,
      Input,
      Button,
      // ModalWindow,
      Splitpanes,
      Pane,
      menueBox,
      mainTop,
      mainDown,
      mainBottom,
      mainRight,
      k_menubox,
      k_newpricebox,
      bottombar,
      KLeft,
      HighChartPopupL,
      order,
      realTimePrice
    },
    /* 
    +---------------------------------------------------------------------------+-----------------------------------+
    |                  ^                                                        |          dv-rt                    |
    |                  |                                                        |                                   |
    |       $("#LT_Pane")[0].style.height                                       |                                   |
    |                  |                                                        +-----------------------------------+ 
    |                  |             dv-lt                                      |          dv-rm                    |
    |                  V                                                        |                                   |
    +------------------------------------+ -------------------------------------+                                   |
    |                                    |                                      +-----------------------------------+
    |--$("#LBL_Pane")[0].style.width --- | --$("#LBR_Pane")[0].style.width------|          dv-rb                    |
    |                                    |                                      |                                   |
    |          dv-lbl                    |          dv-lbr                      | -- $("#R_Pane")[0].style.width ---|
    |                                    |                                      |                                   |
    +------------------------------------+--------------------------------------+-----------------------------------+
        */
    data: function () {
      return {
        countFailTime: 0,
        isLoginButtonDisabled: true,
        loginStatus: 5,
        showPassword: false,
        showModal: false,
        visible: false,
        login_visibleDialog: true,
        login_account: "",
        login_password: "",
        L_Pane_Size: 60,
        R_Pane_Size: 40,
        LT_Pane_Size: 40,
        LB_Pane_Size: 60,
        products: [],
        postit: 'coustom',
        // use for login Ricky
        logindata: {
          email: '',
          password: '',
          model: '',
        },
        encrypt_logindata: {
          email: '',
          password: '',
          model: '',
        },
        // use for login Ricky
        userData: [],                // 用於存放使用者資料 | 20231030 | HENRY |
        nowMyWallet: "",             // 用於存放使用者總額度 | 20231030 | HENRY |
        nowMyLock: "",               // 用於存放使用者被鎖定額度 | 20231030 | HENRY |
      }
    },
    created() {
      //Monitorin_MQ_Initialization_Connect();
      //console.log("訂閱")
      //Monitorin_MQ_Subscribe("TX00");

      window.addEventListener("resize", this.windowResize);
    },
    destroyed() {
      window.removeEventListener("resize", this.windowResize);
      MQ_DisConnect();
    },
    computed: {
      is_lost_connection() {
        return Store.state.is_lost_connection;
      },
      signalr_connection_id() {
        return Store.state.client_hub_register_info.signalr_connection_id;
      },
      userBalanceInfos() {
        return Store.state.userBalanceInfos;
      },
    },
    watch: {
      signalr_connection_id() {
        this.registerSignalrConnId();
      },
      userBalanceInfos() {
        this.update_userBalanceInfos();
      },
    },
    mounted() {
      console.log("login")
      axios.get(this.$httpPath + '/AgentManager/checkVip')
        .then((response) => {
          if (response.status === 200) {
            console.log('登入正確');
            Start_SignalR_Connect();
            //User_MQ_Initialization_Connect("TC00001", "Abc@1234");/*api需補充帳密回傳*/
            //User_MQ_Initialization_Connect(this.logindata.email, this.logindata.password);
            this.Login_ToggleDialog();
            this.windowResize();
            this.loginStatus = 2;
          }
        })
        .catch((error) => {
          this.loginStatus = 0;
          this.isLoginButtonDisabled = false;
        })
      this.resize("mounted", null);
      this.$refs.leftRT.reslzeRT(0, 255);
      this.getUserData();
      this.getMyFutureWalletAmount();
      // this.getFutureLockAmount(); // 放入 getMyFutureWalletAmount() 內 PUDDING
    },
    methods: {
      update_userBalanceInfos() {
        const yCash = document.getElementById('yCash');
        const tCash = document.getElementById('tCash');
        const aCash = document.getElementById('aCash');
        const pCash = document.getElementById('pCash');
        const eCash = document.getElementById('eCash');
        const aCash0 = document.getElementById('aCash0');
        if (yCash) {
          yCash.textContent = this.userBalanceInfos.TotalOfEquityPrevious;
        }
        if (tCash) {
          tCash.textContent = this.userBalanceInfos.TotalOfEquityNow;
        }
        if (aCash) {
          aCash.textContent = this.userBalanceInfos.Balance;
        }
        if (pCash) {
          pCash.textContent = this.userBalanceInfos.ForcedQuota;
        }
        if (eCash) {
          eCash.textContent = this.userBalanceInfos.MaintenanceQuota;
        }
        if (aCash0) {
          aCash0.textContent = this.userBalanceInfos.TotalOfProfit;
        }
      },
      registerSignalrConnId() {
        axios.get(this.$httpPath + '/UserManager/registerSignalrConnectionId?connId=' + this.signalr_connection_id)
          .then((response) => {
            if (response.status === 200) {
              console.log("Response OK:", response.data);
            } else {
              console.error("Response ERR:", response.status);
            }
          })
          .catch((error) => {
            console.error("Request failed:", error);
          });
      },
      Login_Click() {
        this.isLoginButtonDisabled = true;
        this.encrypt_logindata.email = Encrypt(this.logindata.email)
        this.encrypt_logindata.password = Encrypt(this.logindata.password)
        this.encrypt_logindata.model = Encrypt(this.logindata.model)
        //this.$store.dispatch('logIn', this.encrypt_logindata);
        Store.dispatch('logIn', this.encrypt_logindata);
      },
      Login_ToggleDialog() {
        /*login_account  帳號 */
        /*login_password 密碼*/

        this.login_visibleDialog = false;
        console.log(this.login_visibleDialog)
      },
      Change_showModal() {
        console.log(1)
        this.showModal = !this.showModal;
      },
      getProductsResponse(ProductsResponse) {
        console.log("getProductsResponse給order");
        console.log(typeof (ProductsResponse));
        this.products = ProductsResponse;
      },
      maxSize() {
        this.L_Pane_Size = 100;
        this.R_Pane_Size = 0;
        this.LT_Pane_Size = 0;
        this.LB_Pane_Size = 100;
        this.maxResize();
      },

      maxResize() {
        this.$refs.leftLK.resizeLK(document.body.clientWidth, document.body.clientHeight);
        this.$refs.leftRK.resizeRK(document.body.clientWidth, document.body.clientHeight);
      },
      windowResize(e) {
        var dv_lt_h = document.body.clientHeight * parseFloat($("#LT_Pane")[0].style.height.replace("%", "")) / 100;
        var dv_lt_w = document.body.clientWidth - document.body.clientWidth * parseFloat($("#R_Pane")[0].style.width.replace("%", "")) / 100;
        this.$refs.leftLT.resizeLT(dv_lt_w, dv_lt_h);
        this.$refs.leftLD.$refs.leftLK.resizeLK(dv_lt_w, document.body.clientHeight - dv_lt_h);
        this.$refs.leftLD.$refs.leftLK_KL.resizeLK(dv_lt_w, document.body.clientHeight - dv_lt_h);
        this.$refs.leftLD.resizeGrid(dv_lt_w, document.body.clientHeight - dv_lt_h);
        //this.$refs.leftLK.resizeLK(dv_lt_w,document.body.clientHeight-dv_lt_h);2023/01/09 cooc註解
      },
      resize(what, event) {
        if (what == "resizeW") {
          var dv_lt_h = document.body.clientHeight * parseFloat($("#LT_Pane")[0].style.height.replace("%", "")) / 100;
          var dv_lt_w = document.body.clientWidth * event[0].size / 100;
          this.$refs.leftLT.resizeLT(dv_lt_w, dv_lt_h);
          this.$refs.leftLD.$refs.leftLK.resizeLK(dv_lt_w, document.body.clientHeight - dv_lt_h);
          this.$refs.leftLD.$refs.leftLK_KL.resizeLK(dv_lt_w, document.body.clientHeight - dv_lt_h);
          this.$refs.leftLD.resizeGrid(dv_lt_w, document.body.clientHeight - dv_lt_h);
        }
        else {
          var dv_lt_h = document.body.clientHeight * parseFloat($("#LT_Pane")[0].style.height.replace("%", "")) / 100;
          var dv_lt_w = document.body.clientWidth - document.body.clientWidth * parseFloat($("#R_Pane")[0].style.width.replace("%", "")) / 100;
          this.$refs.leftLT.resizeLT(dv_lt_w, dv_lt_h);
          this.$refs.leftLD.$refs.leftLK.resizeLK(dv_lt_w, document.body.clientHeight - dv_lt_h);
          this.$refs.leftLD.$refs.leftLK_KL.resizeLK(dv_lt_w, document.body.clientHeight - dv_lt_h);
          this.$refs.leftLD.resizeGrid(dv_lt_w, document.body.clientHeight - dv_lt_h);
        }

        if (what == "resizeRT") {
          var dv_rt_h = document.body.clientHeight * parseFloat($("#RT_Pane")[0].style.height.replace("%", "")) / 100;
          var dv_rt_w = document.body.clientHeight * parseFloat($("#R_Pane")[0].style.width.replace("%", "")) / 100;
          this.$refs.leftRT.reslzeRT(dv_rt_w, dv_rt_h - 10);
        }
      },

      //|20231030|取得會員基本資料|HENRY|
      async getUserData() {
        axios.get(this.$httpPath + '/UserManager/myAccountInfo')
          .then((response) => {
            if (response.status === 200) {
              this.userData = response.data;
              const userName = document.getElementById('myName');
              if (userName) {
                userName.textContent = this.userData.userName;
              }
            } else {
              console.error("getUserData(), Response not 200:", response.status);
            }
          }
          )
      },

      //|20231030|會員能查詢目前帳戶餘額|HENRY|
      //|20231116|加入非同步執行|PUDDING|
      getMyFutureWalletAmount() {
        axios.get(this.$httpPath + '/UserManager/myFutureWalletAmount')
          .then((response) => {
            if (response.status === 200) {
              this.nowMyWallet = response.data;
              const myCash = document.getElementById('aCash');
              if (myCash) {
                myCash.textContent = this.nowMyWallet;
              }
              this.getFutureLockAmount();
            } else {
              console.error("getMyFutureWalletAmount(), Response not 200:", response.status);
            }
          }
          )
      },

      //|20231030|會員能查詢目前帳戶鎖定餘額  |HENRY|
      //|20231108|目前帳戶鎖定餘額NaN問題修正 |HENRY|
      getFutureLockAmount() {
        axios.get(this.$httpPath + '/UserManager/myFutureLockAmount')
          .then((response) => {
            if (response.status === 200) {
              this.nowMyLock = response.data;

              // 確保從後端獲取的數據是有效的數字字符串
              // 如果不是，設定一個預設值，比如 0
              const lockAmount = parseFloat(this.nowMyLock || '0', 10);
              const totalAmount = parseFloat(this.nowMyWallet || '0', 10);

              // 檢查轉換後的數字是否為 NaN
              if (isNaN(lockAmount) || isNaN(totalAmount)) {
                console.error('Invalid number received from server', this.nowMyLock, this.nowMyWallet);
                return; // 提前返回，不執行下面的代碼
              }

              // 計算 myQuota
              const myQuota = totalAmount + lockAmount;

              // 更新 UI
              const myCashLock = document.getElementById('eCash');
              const myCashElement = document.getElementById('aCash0');
              if (myCashElement) {
                myCashElement.textContent = myQuota.toString();
              }
              if (myCashLock) {
                myCashLock.textContent = lockAmount.toString();
              }
            } else {
              console.error("getFutureLockAmount(), Response not 200:", response.status);
            }
          })
          .catch((error) => {
            console.error("getFutureLockAmount(), Request failed:", error);
          });
      },
    },
  };
</script>
<style scoped>
  .modal-mask {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }

  .modal-container {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
  }

  .modal-body {
    cursor: auto;
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
  }

  .dv-lbl {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }

  .dv-lbr {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #42b983;
    color: #fff;
    z-index: 2;
  }

  body {
    color: #ccc !important;
  }

  .default-theme.splitpanes .splitpanes .splitpanes__splitter {
    z-index: 0 !important;
  }

  .splitpanes__pane {
    box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
  }

  .splitpanes__pane>span {
    font-family: Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 5em;
    opacity: 0.7;
  }

  .vue-modal-resizer {
    display: block;
    overflow: hidden;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: transparent;
    cursor: se-resize;
  }

  .vue-modal-resizer::after {
    display: block;
    position: absolute;
    content: '';
    background: transparent;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid #ddd;
    border-left: 10px solid transparent;
  }

  .vue-modal-resizer.clicked::after {
    border-bottom: 10px solid #369be9;
  }

  .v--modal-block-scroll {
    overflow: hidden;
    width: 100vw;
  }

  .v--modal-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    opacity: 1;
  }

  .v--modal-overlay.scrollable {
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .v--modal-overlay .v--modal-background-click {
    width: 100%;
    min-height: 100%;
    height: auto;
  }

  .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  .v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 2px;
  }

  .v--modal {
    background-color: white;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    padding: 0;
  }

  .v--modal.v--modal-fullscreen {
    width: 100vw;
    height: 100vh;
    margin: 0;
    left: 0;
    top: 0;
  }

  .v--modal-top-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  .overlay-fade-enter-active,
  .overlay-fade-leave-active {
    transition: all 0.2s;
  }

  .overlay-fade-enter,
  .overlay-fade-leave-active {
    opacity: 0;
  }

  .nice-modal-fade-enter-active,
  .nice-modal-fade-leave-active {
    transition: all 0.4s;
  }

  .nice-modal-fade-enter,
  .nice-modal-fade-leave-active {
    opacity: 0;
    transform: translateY(-20px);
  }

  .vue-dialog div {
    box-sizing: border-box;
  }

  .vue-dialog .dialog-flex {
    width: 100%;
    height: 100%;
  }

  .vue-dialog .dialog-content {
    flex: 1 0 auto;
    width: 100%;
    padding: 15px;
    font-size: 14px;
  }

  .vue-dialog .dialog-c-title {
    font-weight: 600;
    padding-bottom: 15px;
  }

  .vue-dialog .vue-dialog-buttons {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    border-top: 1px solid #eee;
  }

  .vue-dialog .vue-dialog-buttons-none {
    width: 100%;
    padding-bottom: 15px;
  }

  .vue-dialog-button {
    font-size: 12px !important;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 40px;
    height: 40px;
    color: inherit;
    font: inherit;
    outline: none;
  }

  .vue-dialog-button:hover {
    background: rgba(0, 0, 0, 0.01);
  }

  .vue-dialog-button:active {
    background: rgba(0, 0, 0, 0.025);
  }

  .vue-dialog-button:not(:first-of-type) {
    border-left: 1px solid #eee;
  }
</style>